import React from "react";
import "./PhoneInput.css";

// Phone Input
const PhoneInput = ({
  name,
  id,
  type,
  className,
  placeholder,
  value,
  onChange,
  required,
  maxLength,
  label,
  subLabel,
  onFocus,
  disabled,
}) => {
  return (
    <>
      <label htmlFor={id}>
        {label}
        <span>{subLabel}</span>
      </label>
      {type === "tel" && <div className="country-code-label">+234</div>}
      <input
        type={"tel"}
        id={id}
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        maxLength={maxLength}
        onFocus={onFocus}
        disabled={disabled}
      />
    </>
  );
};

export default PhoneInput;
