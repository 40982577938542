import React from "react";
import "./TextInput.css";

// Can be Text, Password, Email, Number or Url Input
const TextInput = ({
  name,
  id,
  type,
  className,
  placeholder,
  value,
  onChange,
  required,
  min,
  max,
  maxLength,
  label,
  subLabel,
  autoComplete,
}) => {
  return (
    <>
      <label htmlFor={id}>
        {label}
        <span>{subLabel}</span>
      </label>
      <input
        type={type}
        id={id}
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        min={min}
        max={max}
        maxLength={maxLength}
      />
    </>
  );
};

export default TextInput;
