/* eslint-disable import/no-anonymous-default-export */
import { GET_ALL_BRANDS, } from "../constants";

const initialState = {
    brands: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_BRANDS:
            return {
                ...state,
                brands: action.payload
            };
        default:
            return {
                ...state
            };
    }
}