import React, { createContext, useState } from "react";

export const SearchContext = createContext();
function SearchContextProvider({ children }) {
  //   const navigate = useNavigate();
  const [searchedValue, setSearchedValue] = useState("");

  return (
    <SearchContext.Provider value={{ searchedValue, setSearchedValue }}>
      {children}
    </SearchContext.Provider>
  );
}

export default SearchContextProvider;
