import React from "react";
import "./Modals.css";
import attentionIcon from "../../../image/attention-icon.svg";
import { numberWithCommas } from "../../../utils/numberWithCommas";
import { useDispatch } from "react-redux";
import { createAWithdrawalForMerchant } from "../../../redux/actions/withdrawalActions";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";

function ConfirmWithdrawalModal({
  isConfirmWithdrawalModalOpened,
  setIsConfirmWithdrawalModalOpened,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  setIsLoading,
  isLoading,
  amount,
  setAmount,
  merchantWallet,
  setIsWithdrawalSuccessful,
}) {
  const dispatch = useDispatch();
  //   handle Confirm Withdrawal
  const handleConfirmWithdrawal = function () {
    const data = { amount };
    if (amount <= merchantWallet.balance) {
      // Call create withdrawal API
      dispatch(
        createAWithdrawalForMerchant(
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsWithdrawalSuccessful
        )
      );
      setIsConfirmWithdrawalModalOpened(false);
    } else {
      setIsConfirmWithdrawalModalOpened(false);
      setErrorHandlerObj({ hasError: true, message: "Amount above balance!" });
    }
  };
  return (
    <div
      className={`confirm-withdrawal-modal-container ${
        isConfirmWithdrawalModalOpened ? "" : "none"
      }`}
    >
      <div className="confirm-withdrawal-modal-container--inner">
        <div className="attention-img-wrapper">
          <img src={attentionIcon} alt="" />
        </div>

        <div className="confirm-withdrawal-header-text">
          Kindly confirm this action
        </div>
        <div className="confirm-withdrawal-sub-text">
          Would you like to withdraw the sum of{" "}
          <span>{numberWithCommas(amount)}</span> from your merchant wallet
        </div>

        <div className="modal-action-button-wrapper">
          {/* Cancel Modal Button */}
          <div className="cancel-modal-button-wrapper">
            <button onClick={() => setIsConfirmWithdrawalModalOpened(false)}>
              Cancel
            </button>
          </div>
          {/* Conffirm Action Button */}
          <div className="confirm-modal-action-button">
            <button onClick={() => handleConfirmWithdrawal()}>Confirm</button>
            {isLoading && <LoadingSpinner />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmWithdrawalModal;
