import axios from "axios";
import { GET_ALL_ORDERS, GET_AN_ORDER, GET_ERRORS } from "../constants";
import isEmpty from "../../validation/isEmpty";
import { api } from "../../api/config";


const url = `${api}/api/v1/orders`

const token = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;
// Get all orders per merchant
export const getAllOrdersForMerchant = (setIsLoading, setErrorHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.get(`${url}/merchant`)
        .then((res) => {
            // // console.log(res.data)
            dispatch({
                type: GET_ALL_ORDERS,
                payload: res.data,
            });
            setIsLoading(false)
        })
        .catch((err) => {
            if (!isEmpty(err) && !isEmpty((err.response))) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                })
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: "Something Went Wrong. Please Check your Connection and try again"
                })
            }

            setIsLoading(false)
            // Set the error handler state
            setErrorHandlerObj({
                hasError: true,
                message:
                    err?.response?.data ||
                    "Something Went Wrong. Please Check your Connection and try again",
            });

        })
}

// Get an order
export const getSingleOrder = (id, setIsLoading, setErrorHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.get(`${url}/${id}`)
        .then((res) => {
            dispatch({
                type: GET_AN_ORDER,
                payload: res.data,
            });
            // console.log(res.data)
            setIsLoading(false)
        })
        .catch((err) => {
            if (!isEmpty(err) && !isEmpty((err.response))) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                })
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: "Something Went Wrong. Please Check your Connection and try again"
                })
            }

            setIsLoading(false)
            // Set the error handler state
            setErrorHandlerObj({
                hasError: true,
                message:
                    err?.response?.data ||
                    "Something Went Wrong. Please Check your Connection and try again",
            });

        })
}


// Confirm Product Delivery For an Order on the Merchant's side
export const confirmProductDeliveryForOrderByMerchant = (orderId, data, setIsLoading, setErrorHandlerObj, setSuccessHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.patch(`${url}/confirm-delivery/${orderId}`, data)
        .then((res) => {
            // console.log(res.data)
            setIsLoading(false)

            setSuccessHandlerObj({ isSuccess: true, message: "Order completed successfully!" })
        })
        .catch((err) => {
            if (!isEmpty(err) && !isEmpty((err.response))) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                })
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: "Something Went Wrong. Please Check your Connection and try again"
                })
            }

            setIsLoading(false)
            // Set the error handler state
            setErrorHandlerObj({
                hasError: true,
                message:
                    err?.response?.data ||
                    "Something Went Wrong. Please Check your Connection and try again",
            });

        })
}
