import React, { PureComponent, useLayoutEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const DataFormater = (number) => {
    if (number > 1000000000) {
        return (number / 1000000000).toFixed(1).toString() + 'B';
    } else if (number > 1000000) {
        return (number / 1000000).toFixed(1).toString() + 'M';
    } else if (number > 1000) {
        return (number / 1000).toFixed(1).toString() + 'K';
    } else {
        return number.toString();
    }
}

// export default class Chart extends PureComponent {
const Chart = ({ data, revenueChartWidth }) => {
    // Functions, States and Variables
    return (
        <ResponsiveContainer width={revenueChartWidth}>
            <AreaChart
                data={data}
                margin={{
                    top: 15,
                    right: 0,
                    left: -11,
                    bottom: 0,
                }}
                padding={{
                    right: 20
                }}
            >
                <defs>
                    {/* linear-gradient(89.98deg, rgba(209, 148, 57, 0.04) 2.33%, rgba(146, 118, 40, 0.05) 99.97%); */}
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="54.88%" stopColor={"rgba(58, 12, 163, 1)"} stopOpacity={1} />
                        <stop offset="112.95%" stopColor={"rgba(218, 215, 254, 0.5)"} stopOpacity={1} />
                    </linearGradient>

                </defs>
                Loloo
                <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
                <XAxis dataKey="name" scale="point" />
                <YAxis tickFormatter={DataFormater} />
                {/* <Tooltip /> */}
                <Area type="monotone" dataKey="amount" stroke={"#3A0CA3"} fill="url(#colorUv)" fillOpacity={0.3} />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default Chart;

