import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useDisplayMessage from "../../../utils/useDisplayMessage";
import VoucherSetup from "../voucher-setup/VoucherSetup";

function VoucherEdit() {
  // Funtion, State and Variables
  const location = useLocation();
  const navigate = useNavigate();
  // States
  const [currentVoucherObj, setCurrentVoucherObj] = useState([]);
  const [pageContent, setPageContent] = useState("voucher-info");
  const [isLoading, setIsLoading] = useState(false);

  // Hooks
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    if (location.state) {
      const { voucher } = location.state;
      console.log(voucher, "ditt");

      setCurrentVoucherObj((prevCurrentVoucher) => {
        let newVoucherObj = voucher;
        return newVoucherObj;
      });
    } else {
      navigate(-1);
    }
  }, []);

  return (
    <>
      {currentVoucherObj !== null && (
        <VoucherSetup
          pageType={"edit"}
          currentVoucherObj={currentVoucherObj}
          pageContent={pageContent}
          setPageContent={setPageContent}
          errorHandlerObj={errorHandlerObj}
          setErrorHandlerObj={setErrorHandlerObj}
          successHandlerObj={successHandlerObj}
          setSuccessHandlerObj={setSuccessHandlerObj}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
}

export default VoucherEdit;
