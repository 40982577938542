import axios from "axios";
import { GET_CURRENT_USER, GET_USER_VERIFICATION_STATUS, SET_CURRENT_USER } from "../constants";
import jwt_decode from "jwt-decode";
import isEmpty from "../../validation/isEmpty";
import { api } from "../../api/config";
import setAuthToken from "../../utils/setAuthToken";
import { handleAPIError } from "../../utils/handleAPIError";


const url = `${api}/api/v1/merchant`
export const loginUser = (userData, setIsLoading, setErrorHandlerObj, setSuccessHandlerObj, navigate) => (dispatch) => {
    setIsLoading(true)
    axios.post(`${url}/login`, userData)
        .then((res) => {
            const token = res.data;
            // Save token on local storage
            localStorage.setItem("jwtToken", token);
            // Set token to all Request Header
            // setAuthToken(token);
            // Decode token to get user data
            const decoded = jwt_decode(token);
            console.log(decoded)
            // Set current User to decoded token
            dispatch(setCurrentUser(decoded));

            axios.defaults.headers.common["authorization"] = token;
            // Check if user is verified and navigate user
            axios
                .get(`${url}/check-verification-status`)
                .then((res) => {
                    console.log(res, "verification res")
                    // If the user is verified
                    if (res.data.isVerified) {
                        // Get user details and navigat to app
                        axios.get(`${url}/one`)
                            .then((response) => {
                                setIsLoading(false)
                                const userInfo = response.data
                                console.log(response.data, "authh")
                                if (userInfo?.profilePicture && userInfo?.address && userInfo?.accountNumber && userInfo?.bvn && !isEmpty(userInfo?.deliverycost)) {

                                    // Navigate to dashboard
                                    navigate("/merchant/dashboard");
                                } else {
                                    // Navigate to dashboard
                                    navigate("/merchant/dashboard/setup");
                                }
                            })
                    } else {
                        // Go to verification info page
                        navigate("/merchant/verification")
                    }
                })

        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)

        })
}



// Set logged in user
export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};

const token = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;
// Get Current User
export const getCurrentUser = (setIsLoading, setErrorHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.get(`${url}/one`)
        .then((res) => {
            dispatch({
                type: GET_CURRENT_USER,
                payload: res.data,
            });
            setIsLoading(false)
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}

// Verify Current User 
export const verifyCurrentUser = (data, setIsLoading, setErrorHandlerObj, setSuccessHandlerObj, navigate) => (dispatch) => {
    setIsLoading(true)
    axios.post(`${url}/verify`, data)
        .then((res) => {
            setSuccessHandlerObj({ isSuccess: true, message: "Your account has been successfully verified!" });
            setTimeout(() => {
                navigate("/")
            }, 8000)
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
            setTimeout(() => {
                navigate("/merchant/verification")
            }, 8000)

        })
}

// Get Current User Verification Status
export const getUserVerificationStatus = (setIsLoading, setErrorHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.get(`${url}/check-verification-status`)
        .then((res) => {
            dispatch({
                type: GET_USER_VERIFICATION_STATUS,
                payload: res.data,
            });
            setIsLoading(false)
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)

        })
}


// Resend Verification link
export const resendVerificationLink =
    (
        setIsLoading,
        setErrorHandlerObj,
        setSuccessHandlerObj,

    ) =>
        (dispatch) => {
            setIsLoading(true);
            axios
                .post(`${url}/resend-verification-email`)
                .then((res) => {
                    setSuccessHandlerObj({
                        isSuccess: true,
                        message: `We’ve resent you a verification link. Kindly check your email!`,
                    });
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false)
                    // Set the error handler state
                    handleAPIError(err, dispatch, setErrorHandlerObj)
                });
        };

// Forgot Password
export const forgotPassword =
    (
        data,
        setErrorHandlerObj,
        setSuccessHandlerObj,
        setIsLoading,
        navigate
    ) =>
        (dispatch) => {
            setIsLoading(true);
            axios
                .post(`${url}/forgot-password`, data)
                .then((res) => {
                    setSuccessHandlerObj({
                        isSuccess: true,
                        message: "A reset token has been sent to your email!",
                    });
                    setIsLoading(false);
                    // Navigate to reset password after 8 seconds
                    setTimeout(() => {
                        navigate("/reset-password");
                    }, 8000);
                })
                .catch((err) => {
                    setIsLoading(false);
                    handleAPIError(err, dispatch, setErrorHandlerObj);
                });
        };

// Reset Password
export const resetPassword =
    (
        data,
        setErrorHandlerObj,
        setSuccessHandlerObj,
        setIsLoading,
        navigate
    ) =>
        (dispatch) => {
            setIsLoading(true);
            axios
                .post(`${url}/reset-password`, data)
                .then((res) => {
                    setSuccessHandlerObj({
                        isSuccess: true,
                        message: "Password updated successfully, Go ahead and Login!",
                    });

                    setIsLoading(false);
                    // Navigate to signin after 8 seconds
                    setTimeout(() => {
                        navigate("/");
                    }, 8000);
                })
                .catch((err) => {
                    setIsLoading(false);
                    handleAPIError(err, dispatch, setErrorHandlerObj);
                });
        };


export const logoutUser = (navigate) => (dispatch) => {
    // Remove token from localstorage
    localStorage.removeItem("jwtToken");
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to {} which will set is authenticated to false
    dispatch(setCurrentUser({}));
    navigate("/");
};
