import React, { useState, useEffect } from "react";
import "./Signin.css";
import { useNavigate } from "react-router-dom";
import AuthNavbar from "../../components/auth-navbar/AuthNavbar";
import authWaveImage from "../../image/signin-wave-image.svg";
import TextInput from "../../components/input-components/text-input/TextInput";
import { Link } from "react-router-dom";
import eyeOpenIcon from "../../image/eye-open-icon.svg";
import eyeCloseIcon from "../../image/eye-close-icon.svg";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/actions/authActions";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import ErrorHandler from "../../components/error/ErrorHandler";

function Signin() {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: true,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Errors
  // const error = useSelector((state) => state.errors);

  // Functions
  const handleSubmitLoginDetails = function (e) {
    // Set Error State to default false
    setErrorHandlerObj({ hasError: false, message: "" });
    if (email !== "" && password !== "") {
      e.preventDefault();

      const userData = {
        email,
        password,
      };

      dispatch(
        loginUser(
          userData,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          navigate
        )
      );
    }
  };

  // UseEffect
  useEffect(() => {
    // Handle Password type and icon toggle
    let passwordInputs = document.querySelectorAll(".password-input");
    //  Remove type any and get type for the input
    passwordInputs.forEach((input) => {
      // On click of the first child of the parent which is eye closed icon
      input.parentElement.firstElementChild.addEventListener("click", (e) => {
        e.stopPropagation();
        let elem = e.target;
        // Toggle the eye icon appropriately
        elem.nextElementSibling.classList.remove("hidden");
        elem.classList.add("hidden");
        // Change the type of the input between password and text appropriately

        input.setAttribute("type", "text");
      });

      // On click of the first child of the parent which is eye closed icon
      input.parentElement.firstElementChild.nextSibling.addEventListener(
        "click",
        (e) => {
          e.stopPropagation();
          // Toggle the eye icon appropriately
          e.target.previousElementSibling.classList.remove("hidden");
          e.target.classList.add("hidden");
          // Change the type of the input between password and text appropriately
          input.setAttribute("type", "password");
        }
      );
    });
  }, []);

  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);
  return (
    <div className="signin-container">
      <AuthNavbar />

      {/* Signin container--inner  */}
      <div className="signin-container--inner">
        {/* Signin Left section  / Auth Left Section*/}
        <section className="auth-left-section signin-left-section">
          {/* Signin left section text section */}
          <div className="signin-left-section-text-wrapper">
            Make sales and your money is ensured
          </div>

          {/* Auth Left Image Wrapper */}
          <div className="left-auth-section-image-wrapper">
            <img src={authWaveImage} alt="" />
          </div>
        </section>

        {/* Signin Right section  / Auth Right Section*/}
        <section className="auth-right-section signin-right-section">
          <div className="signin-right-section--inner">
            <div className="auth-message-wrapper">
              {/* Error handler component */}
              <ErrorHandler errorHandlerObj={errorHandlerObj} />
            </div>

            {/* Success handdler component  */}
            {/* <SuccessHandler successHandlerObj={successHandlerObj} /> */}
            {/* Signin right section header */}
            <div className="signin-right-section-header">
              {/* Signin right section header title */}
              <div className="signin-right-section-header-title">Log in</div>
            </div>

            {/* Signin main form section */}
            <div className="signin-main-form-section">
              <form>
                {/* Email form group */}
                <div className="signin-email-form-group form-group">
                  <TextInput
                    type={"email"}
                    id={"signin-email-text-input"}
                    className={"signin-email-input"}
                    placeholder={""}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                    label={"Email"}
                  />
                </div>

                {/* Password form group */}
                <div className="signin-password-form-group form-group">
                  <img
                    src={eyeCloseIcon}
                    alt=""
                    className="eye-close-icon password-icon-wrapper"
                  />

                  <img
                    src={eyeOpenIcon}
                    alt=""
                    className="hidden password-icon-wrapper eye-open-icon"
                  />

                  <label htmlFor={"signin-password-input"}>Password</label>
                  <input
                    type="password"
                    id={"signin-password-input"}
                    className={"signin-password-text-input password-input"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required={true}
                  />
                </div>

                <div className="forgot-password-link-wrapper">
                  <Link to={"/forgot-password"}>forgot password?</Link>
                </div>

                {/* Submit form button wrapper */}
                <div className="log-in-button-wrapper">
                  {isLoading && <LoadingSpinner />}
                  <button onClick={handleSubmitLoginDetails}>Log in</button>
                </div>

                {/* Don't have an account wrapper  */}
                <div className="dont-have-an-account-wrapper">
                  <div className="row-one">
                    Don't have an account, you can <span>Sign up</span> as
                  </div>
                  <div className="row-two">
                    <span>
                      <a href={`${process.env.REACT_APP_COMPANY_URL}`}>
                        Employer
                      </a>
                    </span>
                    or
                    <span>
                      <Link to="/merchant/signup">Merchant</Link>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Signin;
