import "../Pagination.css";
import arrowLeftIcon from "../../../image/arrow-left.svg";
import arrowRightIcon from "../../../image/arrow-right.svg";

function FEPagination({ rowsPerPage, totalPosts, paginate, currentPage }) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination-container">
      <nav>
        <div className="prev-button-wrapper">
          <button
            onClick={() => {
              if (currentPage > 1) {
                paginate(currentPage - 1);
              }
            }}
          >
            <img src={arrowLeftIcon} alt="" />
          </button>
        </div>
        <ul className="pagination__">
          {pageNumbers.map((number) => (
            <li
              className={`pagination--page-item ${
                number === currentPage
                  ? "active"
                  : number === currentPage - 1
                  ? "active-sibling"
                  : ""
              }`}
              key={number}
            >
              <button onClick={() => paginate(number)} className="page-link">
                {number}
              </button>
            </li>
          ))}
        </ul>
        <div className="next-button-wrapper">
          <button
            onClick={() => {
              if (currentPage * rowsPerPage < totalPosts) {
                paginate(currentPage + 1);
              }
            }}
          >
            <img src={arrowRightIcon} alt="" />
          </button>
        </div>
      </nav>
    </div>
  );
}

export default FEPagination;
