import React from "react";
import "./PrimaryButton.css";

function PrimaryButton({ className, placeholder, onClick, children }) {
  return (
    <div className={`primary-button-wrapper ${className}`}>
      <button onClick={onClick}>
        {placeholder}
        {children}
      </button>
    </div>
  );
}

export default PrimaryButton;
