import React, { useEffect, useRef, useState } from "react";
import "./BrandsPopup.css";
import searchIcon from "../../../../../../image/search-icon.svg";
import SubstituteLoadingSpinner from "../../../../../substitute-loading-spinner/SubstituteLoadingSpinner";

const BrandsPopup = function ({
  isBrandsPopupModalOpened,
  setIsBrandsPopupModalOpened,
  handleUpdateProductFormInputState,
  allBrands,
  isAllBrandsLoading,
}) {
  // Functions, States and Variables
  const [allAvailableBrands, setAllAvailableBrands] = useState(null);
  const [searchedValue, setSearchedValue] = useState("");
  const inputRef = useRef(null);

  //   Functions
  const handleSearchAndFilterAvailableCountries = function (e) {
    //
    setSearchedValue(e.target.value);
  };

  useEffect(() => {
    if (allBrands) {
      const filteredAvailableBrands = allBrands.filter((brand) =>
        brand.value.toLowerCase().includes(searchedValue.toLowerCase())
      );
      setAllAvailableBrands(filteredAvailableBrands);
    }
  }, [searchedValue, allBrands]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [isBrandsPopupModalOpened]);

  return (
    <div
      className={`brands-popup-container ${
        isBrandsPopupModalOpened ? "" : "none"
      }`}
    >
      <div className="brands-popup-search-wrapper">
        <div className="brands-popup-search-icon-wrapper">
          <img src={searchIcon} alt="" />
        </div>
        <input
          ref={inputRef}
          type="text"
          value={searchedValue}
          onChange={(e) => handleSearchAndFilterAvailableCountries(e)}
          onClick={() => {
            setIsBrandsPopupModalOpened(true);
          }}
        />
      </div>

      <div className="available-brands-display-wrapper">
        <div className="available-brands-wrapper-title">Select Brand</div>
        {allAvailableBrands?.map((brand, i) => (
          <div
            key={i + 1}
            className="brands-popup-wrapper"
            onClick={() => {
              // setProductBrand(brand.key);
              handleUpdateProductFormInputState("productBrand", brand.key);
              setIsBrandsPopupModalOpened(false);
              setSearchedValue("");
            }}
          >
            {brand.value}
          </div>
        ))}
        <div className="brands-loader-spinner-wrapper">
          {isAllBrandsLoading && <SubstituteLoadingSpinner />}
        </div>
      </div>
    </div>
  );
};

export default BrandsPopup;
