import React from "react";
import "./SubstituteLoadingSpinner.css";

function SubstituteLoadingSpinner() {
  return (
    <div className="substitute-loading-spinner-wrapper">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default SubstituteLoadingSpinner;
