import { GET_CURRENT_USER, GET_MERCHANT_WALLET_HISTORY, SET_CURRENT_USER } from "../constants";
import isEmpty from "../../validation/isEmpty";

const initialState = {
    isAuthenticated: false,
    user: {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case GET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case GET_MERCHANT_WALLET_HISTORY:
            return {
                ...state,
                walletHistory: action.payload
            };

        default:
            return {
                ...state
            };
    }
}