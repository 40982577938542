import moment from "moment";
import React from "react";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import isEmpty from "../../../../validation/isEmpty";
import FEPagination from "../../../../components/pagination/fe-pagination/FEPagination";

function VoucherOrdersTable({
  isLoading,
  allOrders,
  currentPosts,
  rowsPerPage,
  currentPage,
  setCurrentPage,
}) {
  // Functions and States

  // Functions
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {["USER", "ITEM", "AMOUNT", "DATE"].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentPosts?.map((purchase, i) => (
              <tr key={i + 1}>
                {/* User Name Column */}
                <td className="td-user-name">
                  <div className="td-user-name--wrapper">
                    {/* {purchase.userId?.firstName} {purchase.userId?.lastName} */}
                    Faruq Adeyeye
                  </div>
                </td>

                {/*  ITEMS Column */}
                <td className="td-items">
                  <div className="td-items--wrapper">
                    {/* {purchase.items.productId?.name} */}
                    Amazon voucher
                  </div>
                </td>

                {/*  Amount Column */}
                <td className="td-amount">
                  <div className="td-amount--wrapper">
                    {purchase.amountPaid.toLocaleString()}
                  </div>
                </td>

                {/*  Date Added Column */}
                <td className="td-date-added">
                  <div className="td-date-added--wrapper">
                    {moment(purchase.createdAt).format("Do MMM, YYYY")}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allOrders && !isEmpty(currentPosts) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allOrders.length}
          currentPage={currentPage}
        />
      )}
    </>
  );
}

export default VoucherOrdersTable;
