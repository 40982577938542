import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import "./MerchantDashboard.css";
import { useNavigate } from "react-router-dom";
import Top from "../../components/app-top/Top";
import dropdownIcon from "../../image/angle-down.svg";
import percentIncreaseIcon from "../../image/percent-increase-icon.svg";
import percentDecreaseIcon from "../../image/percent-decrease-icon.svg";
import RevenueAreaChart from "../../components/area-chart/RevenueAreaChart";
import OrderAreaChart from "../../components/area-chart/OrderAreaChart";
import { Table } from "react-bootstrap";
import setAuthToken from "../../utils/setAuthToken";
import { setCurrentUser } from "../../redux/actions/authActions";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { getMerchantWallet } from "../../redux/actions/walletActions";
import { allMonths } from "../../utils/allMonths";
import { getAllRevenuesForMerchant } from "../../redux/actions/revenueActions";
import { getAllOrdersForMerchant } from "../../redux/actions/orderActions";
import ErrorHandler from "../../components/error/ErrorHandler";
import Main from "../../components/main-container/Main";
import Sidebar from "../../components/app-sidebar/Sidebar";
import useDisplayMessage from "../../utils/useDisplayMessage";

// Variables
const dashboardData = {
  walletBalancePercentageChange: +0,
  salesLevelsPercentage: 0,
  salesLevelsPercentageChange: -0,
  stockLevelPercentage: 0,
  stockLevelPercentageChange: -0,
};

const orderChartData = [
  {
    name: "Week 1",
    amount: 4000,
  },
  {
    name: "Week 2",
    amount: 2500,
  },
  {
    name: "Week 3",
    amount: 5000,
  },
  {
    name: "Week 4",
    amount: 1089,
  },
];

const revenueChartData = [
  {
    name: "Week 1",
    amount: 2500000,
  },
  {
    name: "Week 2",
    amount: 1100000,
  },
  {
    name: "Week 3",
    amount: 3500000,
  },
  {
    name: "Week 4",
    amount: 1978989,
  },
];

function MerchantDashboard() {
  const wallet = useSelector((state) => state.wallet.wallet);
  const revenues = useSelector((state) => state.revenues.revenues);
  const orders = useSelector((state) => state.orders.orders);
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // States
  const [merchantDashboardData, setMerchantDashboardData] =
    useState(dashboardData);

  const [merchantWallet, setMerchantWallet] = useState(null);
  const [merchantRevenues, setMerchantRevenues] = useState(null);
  const [merchantOrders, setMerchantOrders] = useState(null);
  // Row One
  const [salesLevelMonthSort, setSalesLevelMonthSort] = useState("");
  const [stockLevelMonthSort, setStockLevelsMonthSort] = useState("");
  // Row Two
  const [merchantRevenueMonthSort, setMerchantRevenueMonthSort] = useState("");
  const [merchantOrderMonthSort, setMerchantOrderMonthSort] = useState("");
  // Row Three
  const [topSellersMonthSort, setTopSellersMonthSort] = useState("");
  const [outOfStockMonthSort, setOutOfStockMonthSort] = useState("");
  const [orderChartDataForPresentMonth, setOrderChartDataForPresentMonth] =
    useState([
      {
        name: "",
        amount: 0,
      },

      ...orderChartData,
    ]);
  const [revenueChartDataForPresentMonth, setRevenueChartDataForPresentMonth] =
    useState([
      {
        name: "",
        amount: 0,
      },
      ...revenueChartData,
    ]);
  //
  const [topSellerProductsData, setTopSellerProductsData] = useState(null);
  const [outOfStockProductsData, setOutOfStockProductsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  // Hooks
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    // console.log("ran");
    const token = localStorage.getItem("jwtToken");
    // console.log("tokn", token);
    if (token) {
      // Set token to Auth Header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current User
      dispatch(setCurrentUser(decoded));
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    dispatch(getMerchantWallet(setIsLoading, setErrorHandlerObj));
    dispatch(getAllRevenuesForMerchant(setIsLoading, setErrorHandlerObj));
    dispatch(getAllOrdersForMerchant(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    setMerchantWallet(wallet);
  }, [wallet]);
  useEffect(() => {
    setMerchantRevenues((prevRevenues) => {
      const newTotalRevenue = revenues?.reduce(
        (acc, revenuesObj) => acc + revenuesObj.revenue,
        0
      );
      return { total: newTotalRevenue };
    });
  }, [revenues]);

  useEffect(() => {
    setMerchantOrders(orders);
  }, [orders]);

  const [revenueChartWidth, setRevenueChartWidth] = useState(0);
  const [orderChartWidth, setOrderChartWidth] = useState(0);
  const revenueChartContainerRef = useRef(null);
  const orderChartContainerRef = useRef(null);
  const updateWindowSize = () => {
    setRevenueChartWidth(revenueChartContainerRef.current.clientWidth - 10);
    setOrderChartWidth(orderChartContainerRef.current.clientWidth - 10);
  };

  useLayoutEffect(() => {
    // Continually change the width of the chart to fit in it's container
    window.addEventListener("resize", updateWindowSize);
    updateWindowSize();
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Dashboard"} />

      <Main>
        <div className="app-page-container--inner">
          <div className="app--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
          </div>

          {/* app-container-main-section */}
          <section className="app-container-main-section">
            {/* merchant-dashboard-container inner */}
            <div className="merchant-dashboard-container-wrapper">
              {/* ROW ONE SECTION */}
              <section className="merchant-container-row--one merchant-dashboard-row-section">
                {/* Wallet Balance container / Row one Left */}
                <div className="row-one--left-container wallet-balance-container">
                  {/* Top Wrapper */}
                  <div className="row-one--left--top-wrapper">
                    <div className="row-one--box-title wallet-balance-box-title">
                      WALLET BALANCE
                    </div>

                    {/* <div className="make-withdrawal-btn-container">
                <button
                  onClick={() => navigate("/merchant/dashboard/withdraw")}
                >
                  Make withdrawal
                </button>
              </div> */}
                  </div>

                  {/* Bottom Wrapper */}
                  <div className="row-one--left--bottom-wrapper">
                    {/* Wallet balancee value wrapper */}
                    <div className="wallet-balance-value-wrapper">
                      &#8358;
                      {numberWithCommas(merchantWallet?.balance || 0)}
                    </div>

                    {/* wallet balance percent chang wrapper */}
                    <div
                      className={`row--one-percent-change-wrapper wallet-balance-percent-change-wrapper ${
                        merchantDashboardData.walletBalancePercentageChange < 0
                          ? "percent-decrease-wrapper"
                          : "percent-increase-wrapper"
                      }`}
                    >
                      0%{" "}
                      <img
                        src={
                          merchantDashboardData.walletBalancePercentageChange <
                          0
                            ? percentDecreaseIcon
                            : percentIncreaseIcon
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                {/* Sales level container / Row one Middle */}
                <div className="row-one--middle-container sales-level-container">
                  {/* Top Wrapper */}
                  <div className="row-one--middle--top-wrapper">
                    <div className="row-one--box-title sales-level-box-title">
                      SALES LEVEL
                    </div>

                    <div className="sales-level-sort-dropdown-wrapper">
                      <select
                        id={"sales-level-sort-dropdown"}
                        className={"sales-level-sort-dropdown-input"}
                        value={salesLevelMonthSort}
                        onChange={(e) => {
                          // Change later to stand-alone function when more info is gathered
                          setSalesLevelMonthSort(e.target.value);
                        }}
                      >
                        {allMonths?.map((optionValue, i) => (
                          <option
                            key={i + 1}
                            disabled={optionValue.default ? true : false}
                            value={optionValue.key}
                            hidden={optionValue.default ? true : false}
                            className=""
                          >
                            {optionValue.value}
                          </option>
                        ))}
                      </select>
                      <div className="dropdown-icon">
                        <img src={dropdownIcon} alt="" />
                      </div>
                    </div>
                  </div>

                  {/* Bottom Wrapper */}
                  <div className="row-one--middle--bottom-wrapper">
                    {/* Sales level percentage value wrapper */}
                    <div className="sales-level-percentage-wrapper">
                      {merchantDashboardData.salesLevelsPercentage}%
                    </div>

                    {/* wallet balance percent chang wrapper */}
                    <div
                      className={`row--one-percent-change-wrapper sales-level-percent-change-wrapper ${
                        merchantDashboardData.salesLevelsPercentageChange < 0
                          ? "percent-decrease-wrapper"
                          : "percent-increase-wrapper"
                      }`}
                    >
                      0%{" "}
                      <img
                        src={
                          merchantDashboardData.salesLevelsPercentageChange < 0
                            ? percentDecreaseIcon
                            : percentIncreaseIcon
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                {/* Stock level container / Row one Right */}
                <div className="row-one--right-container stock-level-container">
                  {/* Top Wrapper */}
                  <div className="row-one--right--top-wrapper">
                    <div className="row-one--box-title stock-level-box-title">
                      STOCK LEVEL
                    </div>

                    <div className="stock-level-sort-dropdown-wrapper">
                      <select
                        id={"stock-level-sort-dropdown"}
                        className={"stock-level-sort-dropdown-input"}
                        value={stockLevelMonthSort}
                        onChange={(e) => {
                          // Change later to stand-alone function when more info is gathered
                          setStockLevelsMonthSort(e.target.value);
                        }}
                      >
                        {allMonths.map((optionValue, i) => (
                          <option
                            key={i + 1}
                            disabled={optionValue.default ? true : false}
                            value={optionValue.key}
                            hidden={optionValue.default ? true : false}
                            className=""
                          >
                            {optionValue.value}
                          </option>
                        ))}
                      </select>
                      <div className="dropdown-icon">
                        <img src={dropdownIcon} alt="" />
                      </div>
                    </div>
                  </div>

                  {/* Bottom Wrapper */}
                  <div className="row-one--right--bottom-wrapper">
                    {/* Sales level percentage value wrapper */}
                    <div className="stock-level-percentage-wrapper">
                      {merchantDashboardData.salesLevelsPercentage}%
                    </div>

                    {/* wallet balance percent chang wrapper */}
                    <div
                      className={`row--one-percent-change-wrapper stock-level-percent-change-wrapper ${
                        merchantDashboardData.salesLevelsPercentageChange < 0
                          ? "percent-decrease-wrapper"
                          : "percent-increase-wrapper"
                      }`}
                    >
                      0%{" "}
                      <img
                        src={
                          merchantDashboardData.salesLevelsPercentageChange < 0
                            ? percentDecreaseIcon
                            : percentIncreaseIcon
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </section>

              {/* ROW TWO SECTION */}
              <section className="merchant-container-row--two merchant-dashboard-row-section">
                {/* Row Two Left Container */}
                <div className="row-two--left-container row-two-main-container">
                  {/* Top Wrapper */}
                  <div className="row-two--left--top-wrapper">
                    <div className="row-two--box-title merchant-revenue-level-box-title">
                      REVENUE
                    </div>

                    <div className="merchant-revenue-sort-dropdown-wrapper">
                      <div className="select-dropdown-wrapper">
                        <select
                          id={"merchant-revenue-sort-dropdown"}
                          className={"merchant-revenue-sort-dropdown-input"}
                          value={merchantRevenueMonthSort}
                          onChange={(e) => {
                            // Change later to stand-alone function when more info is gathered
                            setMerchantRevenueMonthSort(e.target.value);
                          }}
                        >
                          {allMonths.map((optionValue, i) => (
                            <option
                              key={i + 1}
                              disabled={optionValue.default ? true : false}
                              value={optionValue.key}
                              hidden={optionValue.default ? true : false}
                              className=""
                            >
                              {optionValue.value}
                            </option>
                          ))}
                        </select>
                        <div className="dropdown-icon">
                          <img src={dropdownIcon} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Bottom Wrapper */}
                  <div className="row-two--left--bottom-wrapper">
                    {/* Total Revenue Amount Wrapper */}
                    <div className="total-revenue-amount-wrapper">
                      <div className="total-revenue-amount-title">
                        Total Amount
                      </div>
                      <div className="total-revenue-amount-value">
                        &#8358;
                        {numberWithCommas(merchantRevenues?.total || 0)}
                      </div>
                    </div>

                    {/* Revenue Area Chart Container */}
                    <div
                      ref={revenueChartContainerRef}
                      className="revenue-area-chart-container"
                    >
                      <RevenueAreaChart
                        data={revenueChartDataForPresentMonth}
                        revenueChartWidth={revenueChartWidth}
                      />
                    </div>
                  </div>
                </div>

                {/* Row Two Right Container */}
                <div className="row-two--right-container row-two-main-container">
                  {/* Top Wrapper */}
                  <div className="row-two--right--top-wrapper">
                    <div className="row-two--box-title merchant-order-level-box-title">
                      ORDER
                    </div>

                    <div className="merchant-order-sort-dropdown-wrapper">
                      <div className="select-dropdown-wrapper">
                        <select
                          id={"merchant-order-sort-dropdown"}
                          className={"merchant-order-sort-dropdown-input"}
                          value={merchantOrderMonthSort}
                          onChange={(e) => {
                            // Change later to stand-alone function when more info is gathered
                            setMerchantOrderMonthSort(e.target.value);
                          }}
                        >
                          {allMonths.map((optionValue, i) => (
                            <option
                              key={i + 1}
                              disabled={optionValue.default ? true : false}
                              value={optionValue.key}
                              hidden={optionValue.default ? true : false}
                              className=""
                            >
                              {optionValue.value}
                            </option>
                          ))}
                        </select>
                        <div className="dropdown-icon">
                          <img src={dropdownIcon} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Bottom Wrapper */}
                  <div className="row-two--right--bottom-wrapper">
                    {/* Total Revenue Amount Wrapper */}
                    <div className="total-order-amount-wrapper">
                      <div className="total-order-amount-title">
                        Total Amount
                      </div>
                      <div className="total-order-amount-value">
                        {numberWithCommas(merchantOrders?.length || 0)}
                      </div>
                    </div>

                    {/* Order Area Chart Container */}
                    <div
                      ref={orderChartContainerRef}
                      className="order-area-chart-container"
                    >
                      <OrderAreaChart
                        data={orderChartDataForPresentMonth}
                        orderChartWidth={orderChartWidth}
                      />
                    </div>
                  </div>
                </div>
              </section>

              {/* ROW THREE SECTION */}
              <section className="merchant-container-row--three merchant-dashboard-row-section">
                {/* Row Two Left Container */}
                <div className="row-three--left-container row-three-main-container">
                  {/* Top Wrapper */}
                  <div className="row-three--left--top-wrapper">
                    <div className="row-three--box-title merchant-revenue-level-box-title">
                      TOP KSU
                    </div>

                    <div className="merchant-top-sellers-sort-dropdown-wrapper">
                      <div className="select-dropdown-wrapper">
                        <select
                          id={"merchant-top-sellers-sort-dropdown"}
                          className={"merchant-top-sellers-sort-dropdown-input"}
                          value={topSellersMonthSort}
                          onChange={(e) => {
                            // Change later to stand-alone function when more info is gathered
                            setTopSellersMonthSort(e.target.value);
                          }}
                        >
                          {allMonths.map((optionValue, i) => (
                            <option
                              key={i + 1}
                              disabled={optionValue.default ? true : false}
                              value={optionValue.key}
                              hidden={optionValue.default ? true : false}
                              className=""
                            >
                              {optionValue.value}
                            </option>
                          ))}
                        </select>
                        <div className="dropdown-icon">
                          <img src={dropdownIcon} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Bottom Wrapper */}
                  <div className="row-three--left--bottom-wrapper">
                    {/* Top selling products table header */}
                    <div className="top-selling-products-table-title">
                      Your top 3 selling products
                    </div>

                    {/* Top selling products table container */}
                    <div className="top-selling-products-table-container">
                      <Table
                        responsive="lg"
                        className="top-selling-products-table"
                      >
                        <thead
                          style={{
                            textAlign: "left",
                          }}
                        >
                          <tr>
                            {[
                              // "Date",
                              "PRODUCT NAME",
                              "QTY SOLD",
                              "PRICE",
                              "TOTAL AMOUNT",
                            ].map((COLUMN, index) => (
                              <th key={index + 1}>{COLUMN}</th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {topSellerProductsData?.map((product, i) => (
                            <tr key={i + 1}>
                              {/* Product Name Column */}
                              <td className="td-product-name">
                                <div className="td-product-name--wrapper">
                                  {/* profile image wrapper */}
                                  <div className="profile-image-wrapper">
                                    <img
                                      src={
                                        topSellerProductsData &&
                                        require("../../../src/image/" +
                                          product.productImg)
                                      }
                                      alt=""
                                    />
                                  </div>
                                  {product.productName}
                                </div>
                              </td>

                              {/*  Quantity Sold Column */}
                              <td className="td-qty-sold-type">
                                <div className="td-qty-sold--wrapper">
                                  {product.quantitySold}
                                </div>
                              </td>

                              {/* Product Price Column */}
                              <td className="td-product-price">
                                <div className="td-product-price--wrapper">
                                  {product.price}
                                </div>
                              </td>

                              {/*Total Amount Column*/}
                              <td className="td-total-amount">
                                <div className="td-total-amount--wrapper">
                                  {numberWithCommas(
                                    product?.totalAmount() || 0
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

                {/* Row Two Right Container */}
                <div className="row-three--right-container row-three-main-container">
                  {/* Top Wrapper */}
                  <div className="row-three--right--top-wrapper">
                    <div className="row-three--box-title merchant-revenue-level-box-title">
                      STOCK LEVELS
                    </div>

                    <div className="merchant-out-of-stock-sort-dropdown-wrapper">
                      <div className="select-dropdown-wrapper">
                        <select
                          id={"merchant-out-of-stock-sort-dropdown"}
                          className={
                            "merchant-out-of-stock-sort-dropdown-input"
                          }
                          value={outOfStockMonthSort}
                          onChange={(e) => {
                            // Change later to stand-alone function when more info is gathered
                            setOutOfStockMonthSort(e.target.value);
                          }}
                        >
                          {allMonths.map((optionValue, i) => (
                            <option
                              key={i + 1}
                              disabled={optionValue.default ? true : false}
                              value={optionValue.key}
                              hidden={optionValue.default ? true : false}
                              className=""
                            >
                              {optionValue.value}
                            </option>
                          ))}
                        </select>
                        <div className="dropdown-icon">
                          <img src={dropdownIcon} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Bottom Wrapper */}
                  <div className="row-three--left--bottom-wrapper">
                    {/* Out of stock products table header */}
                    <div className="out-of-stock-products-table-title">
                      You are running out of the following products
                    </div>

                    {/* Out of stock products table container */}
                    <div className="out-of-stock-products-table-container">
                      <Table
                        responsive="lg"
                        className="out-of-stock-products-table"
                      >
                        <thead
                          style={{
                            textAlign: "left",
                          }}
                        >
                          <tr>
                            {[
                              // "Date",
                              "PRODUCT NAME",
                              "QTY SOLD",
                              "PRICE",
                              "QTY LEFT",
                              "STATUS",
                            ].map((COLUMN, index) => (
                              <th key={index + 1}>{COLUMN}</th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {outOfStockProductsData?.map((product, i) => (
                            <tr key={i + 1}>
                              {/* Product Name Column */}
                              <td className="td-product-name">
                                <div className="td-product-name--wrapper">
                                  {/* profile image wrapper */}
                                  <div className="profile-image-wrapper">
                                    <img
                                      src={
                                        outOfStockProductsData &&
                                        require("../../../src/image/" +
                                          product.productImg)
                                      }
                                      alt=""
                                    />
                                  </div>
                                  {product.productName}
                                </div>
                              </td>

                              {/*  Quantity Sold Column */}
                              <td className="td-qty-sold-type">
                                <div className="td-qty-sold--wrapper">
                                  {product.quantitySold}
                                </div>
                              </td>

                              {/* Product Price Column */}
                              <td className="td-product-price">
                                <div className="td-product-price--wrapper">
                                  {product.price}
                                </div>
                              </td>

                              {/*  Quantity Left Column */}
                              <td className="td-qty-left-type">
                                <div className="td-qty-left--wrapper">
                                  {product.quantityLeft}
                                </div>
                              </td>

                              {/*Product status Column*/}
                              <td className="td-product-status">
                                <div className="td-product-status--wrapper">
                                  {product.status}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </Main>
    </div>
  );
}

export default MerchantDashboard;
