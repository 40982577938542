import React, { useContext, useEffect, useState } from "react";
import "./MerchantStore.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SearchContext } from "../../../components/contexts/SearchContext";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import PrimaryButton from "../../../components/buttons/primary-button/PrimaryButton";
import SubstituteLoadingSpinner from "../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import { getCurrentUser } from "../../../redux/actions/authActions";
import { getAllCategories } from "../../../redux/actions/categoryActions";
import { getAllMerchantStock } from "../../../redux/actions/stockActions";
import isEmpty from "../../../validation/isEmpty";
import StoreTable from "./store-table/StoreTable";

function MerchantStore({ setErrorHandlerObj, setSuccessHandlerObj }) {
  // Functions, States and Variables
  const { searchedValue } = useContext(SearchContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.users.user);
  const categories = useSelector((state) => state.categories.categories);
  const storeStock = useSelector((state) => state.stocks.stocks);

  // States
  const [allStoreStocks, setAllStoreStocks] = useState(null);
  const [stockStatusFilter, setStockStatusFilter] = useState("");
  const [stockDateFilter, setStockDateFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // is Add New Stock Stock button Clicked
  const [isAddNewStockButtonClicked, setIsAddNewStockButtonClicked] =
    useState(false);
  const [isUserInfoloading, setIsUserInfoLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPosts, setCurrentPosts] = useState(null);

  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  // handle Filter Merchant Stock
  const handleFilterMerchantStock = function (value) {
    if (value === "all") {
      setAllStoreStocks((prevStocks) => {
        let newStockArr = storeStock
          ?.filter((stock) => stock.isActive === true)
          ?.map((stock) => {
            return { ...stock, isVariantsOpened: false };
          })
          ?.filter((stock) =>
            stock.name.toLowerCase().includes(searchedValue.toLowerCase())
          )
          .reverse();

        return newStockArr;
      });

      // Update table state to all rows
      if (!allStoreStocks) return false;
      const currentRows = allStoreStocks?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentPosts(currentRows);
    } else if (value === "not-approved") {
      // Update the set stocks state to accomodate only not approvd stocks.
      setAllStoreStocks((prevStocks) => {
        if (!prevStocks) return prevStocks;

        let filteredStocks = storeStock
          ?.filter((stock) => stock.isActive === true)
          ?.filter((stock) => {
            return stock.isApproved === false;
          })
          ?.filter((stock) =>
            stock.name.toLowerCase().includes(searchedValue.toLowerCase())
          )
          .reverse();
        const currentRows = filteredStocks?.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setCurrentPosts(currentRows);
        return filteredStocks;
      });
    } else if (value === "out-of-stock") {
      // Update the set stocks state to accomodate only out of stock storeStock.
      setAllStoreStocks((prevStocks) => {
        if (!prevStocks) return prevStocks;

        let filteredStocks = storeStock
          ?.filter((stock) => stock.isActive === true)
          ?.filter((stock) => {
            return stock.quantity === 0;
          })
          ?.filter((stock) =>
            stock.name.toLowerCase().includes(searchedValue.toLowerCase())
          )
          .reverse();
        const currentRows = filteredStocks?.slice(
          indexOfFirstPost,
          indexOfLastPost
        );
        setCurrentPosts(currentRows);
        return filteredStocks;
      });
    } else {
      // Update the set stocks state to accomodate only new stock.
      setAllStoreStocks((prevStocks) => {
        if (!prevStocks) return prevStocks;

        let filteredStocks = storeStock
          ?.filter((stock) => stock.isActive === true)
          ?.filter((stock) => {
            return stock.isApproved && stock.quantity > 0;
          })
          ?.filter((stock) =>
            stock.name.toLowerCase().includes(searchedValue.toLowerCase())
          )
          .reverse();

        const currentRows = filteredStocks?.slice(
          indexOfFirstPost,
          indexOfLastPost
        );

        setCurrentPosts(currentRows);
        return filteredStocks;
      });
    }
  };

  // Handle Navigate to add new stock
  const handleNavigateToAddNewStock = function () {
    setIsAddNewStockButtonClicked(true);
    setErrorHandlerObj({ hasError: false, message: "" });

    // Check if userInfo is not empty(i.e is gotten), then check and navigate / throw error respectively
    if (!isEmpty(userInfo)) {
      if (
        userInfo?.address &&
        userInfo?.accountNumber &&
        userInfo?.bvn &&
        !isEmpty(userInfo?.deliverycost)
      ) {
        // If user is approved
        if (userInfo.isApproved) {
          navigate("/merchant/stock/create");
        } else {
          navigate("/merchant/awaiting-approval");
        }
      } else {
        setErrorHandlerObj({
          hasError: true,
          message: "Kindly complete account setup before you can proceed!",
        });
      }
    }
  };

  // handle Toggle Variants Display
  const handleToggleVariantsDisplay = function (stock, index) {
    console.log(index, stock);

    setAllStoreStocks((prevStocks) => {
      if (!prevStocks) return prevStocks;

      let allStocksCopy = prevStocks.map((currentStock) => {
        return { ...currentStock, isVariantsOpened: false };
      });
      let updatedStock = {
        ...stock,
        isVariantsOpened: !stock.isVariantsOpened,
      };

      allStocksCopy.splice(index, 1, updatedStock);
      return allStocksCopy;
    });
  };

  useEffect(() => {
    // If Button is clicked before userInfo is gotten, trigger call on change of user info
    if (isAddNewStockButtonClicked) {
      if (
        userInfo?.profilePicture &&
        userInfo?.address &&
        userInfo?.cacDocument &&
        userInfo?.accountNumber &&
        !isEmpty(userInfo?.deliverycost)
      ) {
        // If user is approved
        if (userInfo.isApproved) {
          navigate("/merchant/stock/create");
        } else {
          navigate("/merchant/awaiting-approval");
        }
      } else {
        setErrorHandlerObj({
          hasError: true,
          message: "Kindly complete account setup before you can proceed!",
        });
      }
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(getAllCategories(setIsLoading, setErrorHandlerObj));
    dispatch(getAllMerchantStock(setIsLoading, setErrorHandlerObj));
    dispatch(getCurrentUser(setIsUserInfoLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    setAllStoreStocks((prevStocks) => {
      let newStockArr = storeStock
        ?.filter((stock) => stock.isActive === true)
        ?.map((stock) => {
          return { ...stock, isVariantsOpened: false };
        })
        ?.filter((stock) =>
          stock.name.toLowerCase().includes(searchedValue.toLowerCase())
        )
        .reverse();

      return newStockArr;
    });
  }, [storeStock]);

  useEffect(() => {
    handleFilterMerchantStock(stockStatusFilter || "all");
  }, [searchedValue]);

  useEffect(() => {
    // console.log(allStoreStocks);
    const currentRows = allStoreStocks?.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    setCurrentPosts(currentRows);
  }, [allStoreStocks, currentPage]);

  return (
    <>
      <section className="primary-table-section merchant-store-section">
        {/* Table Top Wrapper */}
        <div className="primary-table--top-wrapper">
          {/*  Table Filter Wrapper */}
          <div className="primary-table-filter-option-wrapper">
            <div className="primary-table-filter-row-title">Filter:</div>
            {/* Filter by Status */}
            <div className="primary-table--date-filter-form-group form-group">
              <DropdownInput
                className={"order-status-filter-dropdown-input"}
                id={"order-status-filter-input"}
                label={""}
                required={true}
                optionArray={[
                  { key: "", value: "Filter", default: "default" },
                  { key: "all", value: "All" },
                  {
                    key: "not-approved",
                    value: "Not Approved",
                  },
                  { key: "out-of-stock", value: "Out Of Stock" },
                  { key: "new-stock", value: "New Stock" },
                ]}
                value={stockStatusFilter}
                onChange={(e) => {
                  setStockStatusFilter(e.target.value);
                  handleFilterMerchantStock(e.target.value);
                }}
              />
            </div>
            {/* Date */}
            <div className="primary-table--date-filter-form-group form-group">
              <DropdownInput
                className={"order-date-filter-dropdown-input"}
                id={"order-date-filter-input"}
                label={""}
                required={true}
                optionArray={[
                  { key: "", value: "Date", default: "Default" },
                  {
                    key: "yesterday",
                    value: "Yesterday",
                  },
                ]}
                value={stockDateFilter}
                onChange={(e) => setStockDateFilter(e.target.value)}
              />
            </div>
          </div>

          <div className="primary-btn-container">
            <PrimaryButton
              className="add-new-stock-button-wrapper"
              placeholder="Add New"
              onClick={() => handleNavigateToAddNewStock()}
            />
            {isAddNewStockButtonClicked && isUserInfoloading && (
              <SubstituteLoadingSpinner />
            )}
          </div>
        </div>

        {/* Store Table Container */}
        <StoreTable
          currentPosts={currentPosts}
          categories={categories}
          allStoreStocks={allStoreStocks}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={isLoading}
          rowsPerPage={rowsPerPage}
          handleToggleVariantsDisplay={handleToggleVariantsDisplay}
        />
      </section>
    </>
  );
}

export default MerchantStore;
