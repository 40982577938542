import axios from "axios";
import { api } from "../../api/config"
import { GET_MERCHANT_WALLET_HISTORY } from "../constants";
import { handleAPIError } from "../../utils/handleAPIError";

const url = `${api}/api/v1/merchant`
export const registerUser = (userData, setIsLoading,
    setErrorHandlerObj,
    setRegisterSuccessful) => (dispatch) => {
        setIsLoading(true)
        axios.post(url, userData)
            .then((res) => {

                //  Set the register success state
                setRegisterSuccessful(true)


                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
                // Set the error handler state
                handleAPIError(err, dispatch, setErrorHandlerObj)
            })
    }

// Get Merchant wallet history
export const getMerchantWalletHistory = (pageNumberIndex, setIsLoading, setErrorHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.get(`${url}/wallet-history?page=${pageNumberIndex}`)
        .then((res) => {
            dispatch({
                type: GET_MERCHANT_WALLET_HISTORY,
                payload: res.data,
            });
            setIsLoading(false)
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}