import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import isEmpty from "../../../../validation/isEmpty";
import pencilIcon from "../../../../image/pencil-icon.svg";
import moment from "moment";
import FEPagination from "../../../../components/pagination/fe-pagination/FEPagination";

function VouchersTable({
  categories,
  currentPosts,
  allVoucherStocks,
  currentPage,
  setCurrentPage,
  isLoading,
  rowsPerPage,
}) {
  // Functions, States and variables
  // Functions
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Get Category Name
  const getCategoryName = function (categoryId) {
    const currentCategoryArr = categories?.filter(
      (category) => category._id === categoryId
    );
    return currentCategoryArr[0]?.name;
  };

  return (
    <>
      {/* All Voucher Stock Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "VOUCHER DESCRIPTION",
                "CATEGORY",
                "MINIMUM AMOUNT",
                "MAXIMUM AMOUNT",
                "DISCOUNT",
                "EXPIRY DATE",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentPosts?.map((voucher, index) => (
              <tr key={index + 1}>
                {/* Product Name Column */}
                <td className="td-description">
                  <div className="td-description--wrapper">
                    {voucher.voucherDescription}
                  </div>
                </td>

                {/*  Category Column */}
                <td className="td-stock-category">
                  <div className="td-stock-category--wrapper">
                    {getCategoryName(voucher.categoryId)}
                  </div>
                </td>

                {/* Voucher Min Amount Column*/}
                <td className="td-min-amount">
                  <div className="td-min-amount--wrapper">
                    &#8358;{voucher.minAmount?.toLocaleString()}
                  </div>
                </td>

                {/* Voucher Max Amount Column*/}
                <td className="td-max-amount">
                  <div className="td-max-amount--wrapper">
                    &#8358;{voucher.maxAmount?.toLocaleString()}
                  </div>
                </td>

                {/* Voucher Discount Column*/}
                <td className="td-stock-discount">
                  <div className="td-stock-discount--wrapper">
                    {voucher.discount}%
                  </div>
                </td>

                {/* Voucher Discount Column*/}
                <td className="td-expiry-date">
                  <div className="td-expiry-date--wrapper">
                    {moment(voucher.expiryDate).format("Do MMM, YYYY")}
                  </div>
                </td>

                {/* Edit Stock Row Column */}
                <td className="td-edit-stock-row">
                  <div className="td-edit-stock-row--wrapper">
                    <Link to={"/merchant/voucher/edit"} state={{ voucher }}>
                      <img src={pencilIcon} alt="" />
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="stock-loader-wrapper">
          {/* Loading Spinner Wrapper */}
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allVoucherStocks && !isEmpty(allVoucherStocks) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allVoucherStocks.length}
          currentPage={currentPage}
        />
      )}
    </>
  );
}

export default VouchersTable;
