import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../../../../../redux/actions/categoryActions";
import DropdownInput from "../../../../../input-components/dropdown-input/DropdownInput";
import TextInput from "../../../../../input-components/text-input/TextInput";
import LoadingSpinner from "../../../../../loading-spinner/LoadingSpinner";
import isEmpty from "../../../../../../validation/isEmpty";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  createMerchantVoucher,
  updateMerchantVoucher,
} from "../../../../../../redux/actions/vouchersActions";

function VoucherInfoForm({
  pageContent,
  pageType,
  currentVoucherObj,
  productFormInputState,
  setProductFormInputState,
  isLoading,
  setIsLoading,
  setErrorHandlerObj,
}) {
  // Functions, States and Variables
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categories = useSelector((state) => state.categories.categories);

  // States
  // Product Info form states
  const [allCategories, setAllCategories] = useState([]);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);

  //   Functions
  // Handle Submit Product Info form
  const handleSubmitProductInfoForm = function (e) {
    if (
      productFormInputState.voucherDescription !== "" &&
      productFormInputState.voucherDiscount !== "" &&
      productFormInputState.minAmount !== "" &&
      productFormInputState.maxAmount !== "" &&
      productFormInputState.expiryDate !== "" &&
      productFormInputState.categoryId !== ""
    ) {
      e.preventDefault();
      // Clear Error
      setErrorHandlerObj({ hasError: false, message: "" });

      if (pageType === "create") {
        const data = {
          voucherDescription: productFormInputState.voucherDescription,
          discount: parseInt(productFormInputState.voucherDiscount),
          minAmount: parseInt(productFormInputState.minAmount),
          maxAmount: parseInt(productFormInputState.maxAmount),
          expiryDate: productFormInputState.expiryDate,
          categoryId: productFormInputState.categoryId,
        };

        // Call the create Voucher API
        dispatch(
          createMerchantVoucher(
            data,
            setIsLoading,
            setErrorHandlerObj,
            navigate
          )
        );
      } else {
        const data = {
          discount: parseInt(productFormInputState.voucherDiscount),
          minAmount: parseInt(productFormInputState.minAmount),
          maxAmount: parseInt(productFormInputState.maxAmount),
          expiryDate: productFormInputState.expiryDate,
          categoryId: productFormInputState.categoryId,
        };
        const voucherId = currentVoucherObj._id;

        // Call the edit Voucher API
        dispatch(
          updateMerchantVoucher(
            data,
            voucherId,
            setIsLoading,
            setErrorHandlerObj,
            navigate
          )
        );
      }
    }
  };

  //   Handle Update product form input state
  const handleUpdateProductFormInputState = function (fieldName, fieldValue) {
    setProductFormInputState((prevFormInputState) => {
      const updatedFormInputStates = {
        ...prevFormInputState,
        [fieldName]: fieldValue,
      };
      return updatedFormInputStates;
    });
  };

  //   UseEffects
  useEffect(() => {
    dispatch(getAllCategories(setIsCategoriesLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    setAllCategories(() => {
      let newCategoriesArr = categories?.map((category) => {
        return { key: category._id, value: category.name };
      });
      return newCategoriesArr;
    });
  }, [categories]);

  useEffect(() => {
    if (!isEmpty(currentVoucherObj)) {
      setProductFormInputState((prevFormInputState) => {
        const updatedFormInputStates = {
          ...prevFormInputState,
          voucherDescription: currentVoucherObj.voucherDescription,
          voucherDiscount: currentVoucherObj.discount,
          minAmount: currentVoucherObj.minAmount,
          maxAmount: currentVoucherObj.maxAmount,
          expiryDate: moment(currentVoucherObj.expiryDate).format("YYYY-MM-DD"),
          categoryId: currentVoucherObj.categoryId,
        };
        return updatedFormInputStates;
      });
    }
  }, [currentVoucherObj]);

  return (
    <div
      className={`product-info-setup--inner ${
        pageContent !== "voucher-info" ? "none" : ""
      }`}
    >
      <form>
        {/* Category Form group */}
        <div className="form-group">
          <DropdownInput
            className={"product-category-dropdown-input"}
            id={"product-category-name-input"}
            label={"Category"}
            required={true}
            optionArray={[
              {
                key: "",
                value: "",
                default: "default",
              },
              ...allCategories,
            ]}
            value={productFormInputState.categoryId}
            onChange={(e) =>
              handleUpdateProductFormInputState("categoryId", e.target.value)
            }
          />
        </div>

        {/* Min Amount form group */}
        <div className="product-info-setup-price-form-group form-group">
          <TextInput
            type={"number"}
            className={"product-price-input"}
            placeholder={""}
            value={productFormInputState.minAmount}
            onChange={(e) => {
              handleUpdateProductFormInputState("minAmount", e.target.value);
            }}
            subLabel={"(in naira)"}
            label={"Mininum Amount"}
            required={true}
          />
        </div>

        {/* Max Amount form group */}
        <div className="product-info-setup-price-form-group form-group">
          <TextInput
            type={"number"}
            className={"product-price-input"}
            placeholder={""}
            value={productFormInputState.maxAmount}
            onChange={(e) => {
              handleUpdateProductFormInputState("maxAmount", e.target.value);
            }}
            subLabel={"(in naira)"}
            label={"Maximum Amount"}
            required={true}
          />
        </div>

        {/* Voucher Discount form group */}
        <div className="form-group">
          <TextInput
            type={"number"}
            className={"product-discount-input"}
            placeholder={""}
            value={productFormInputState.voucherDiscount}
            onChange={(e) => {
              handleUpdateProductFormInputState(
                "voucherDiscount",
                e.target.value
              );
            }}
            min={"0"}
            label={"Discount(%)"}
            required={true}
          />
        </div>

        {/* Voucher Expiry Date form group */}
        <div className="form-group">
          <TextInput
            type={"date"}
            className={"product-expiry-date-input"}
            placeholder={""}
            value={productFormInputState.expiryDate}
            onChange={(e) => {
              handleUpdateProductFormInputState("expiryDate", e.target.value);
            }}
            label={"Expiry Date"}
            required={true}
          />
        </div>

        {/* Product Description form group */}
        <div className="product-info-setup-product-descrp-form-group shop-details-setup-shop-address-form-group form-group">
          <label htmlFor="product-descrp-textarea-input">
            Voucher Description
            {pageType === "edit" && <span>(not edittable)</span>}
          </label>
          <textarea
            id={"product-descrp-textarea-input"}
            className={"product-descrp-input"}
            placeholder={""}
            value={productFormInputState.voucherDescription}
            onChange={(e) =>
              handleUpdateProductFormInputState(
                "voucherDescription",
                e.target.value
              )
            }
            required={true}
            readOnly={pageType === "edit"}
          />
        </div>

        {/* Submit form button */}
        <div className="submit-setup-form-button-wrapper">
          {isLoading && <LoadingSpinner />}
          <button onClick={handleSubmitProductInfoForm}>Submit</button>
        </div>
      </form>
    </div>
  );
}

export default VoucherInfoForm;
