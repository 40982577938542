import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { api } from "../../../../../../api/config";
import {
  createMerchantStock,
  updateMerchantStock,
} from "../../../../../../redux/actions/stockActions";
import isEmpty from "../../../../../../validation/isEmpty";
import plusIcon from "../../../../../../image/plus-icon.svg";
import TextInput from "../../../../../input-components/text-input/TextInput";
import LoadingSpinner from "../../../../../loading-spinner/LoadingSpinner";

function ProductVariantsForm({
  pageContent,
  pageType,
  currentStockObj,
  productImgArr,
  productFormInputState,
  currentUploadId,
  isLoading,
  isFileUploadLoading,
  setIsBrandsPopupModalOpened,
  setCurrentUploadId,
  setPageContent,
  setIsFileUploadLoading,
  setVariantsExists,
  setIsLoading,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}) {
  // Functions, States and Variables
  const dispatch = useDispatch();
  // States
  // Variants State
  const [variantImg, setVariantImg] = useState("");
  const [variantQuantity, setVariantQuantity] = useState("");
  const [variantPrice, setVariantPrice] = useState("");
  const [variantSpecifications, setVariantSpecifications] = useState("");
  const [productVariants, setProductVariants] = useState([]);
  //   Functions
  // Handle Submit / Update Shop details form
  const handleSubmitStockSetupForm = function (e) {
    e.preventDefault();
    const allVariantsArr = productVariants
      ?.filter(
        (variant) =>
          variant.quantity !== "" &&
          variant.specifications !== "" &&
          variant.price !== "" &&
          variant.imgUrl !== ""
      )
      .map((variant) => {
        return {
          image: variant.imgUrl,
          specification: variant.specifications,
          quantity: variant.quantity,
          price: variant.price,
        };
      });
    console.log(allVariantsArr, "all");

    const pictures = productImgArr.map((imgObj) => {
      return imgObj.imgUrl;
    });
    const data = {
      image: pictures[0],
      pictures,
      name: productFormInputState.productName,
      category: productFormInputState.category,
      quantity: productFormInputState.productQuantity,
      description: productFormInputState.productDescription,
      price: productFormInputState.price,
      brandId: productFormInputState.productBrand,
      size: productFormInputState.productSizeCategory,
      variants: allVariantsArr,
    };
    console.log("result", data);
    if (pageType === "create") {
      // Call the create product API
      dispatch(
        createMerchantStock(
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setPageContent
        )
      );
    } else {
      // TODO Call the edit product API
      dispatch(
        updateMerchantStock(
          currentStockObj._id,
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setPageContent
        )
      );

      console.log("edit data", data);
    }
  };

  // handle Edit Current Variant
  const handleEditCurrentVariant = function (e, id, variant) {
    // Update All Instruments State
    // Filter out any instrument obj whose form state is empty, then set current obj to preview based on index gotten from Id
    e.preventDefault();

    let filteredVariantsArr = productVariants?.filter(
      (singleVariant) =>
        singleVariant.imgUrl !== "" &&
        singleVariant.quantity !== "" &&
        singleVariant.price !== "" &&
        singleVariant.specifications !== ""
    );

    console.log("is not empty", filteredVariantsArr);
    setProductVariants((prevVariants) => {
      if (!prevVariants || !filteredVariantsArr) return prevVariants;

      // Get the variant index
      const index = filteredVariantsArr.findIndex(
        (singleVariant) => singleVariant._id === id
      );

      // Set every variant obj to preview view
      let newPrevVariants = filteredVariantsArr.map((currentVariant) => {
        return {
          ...currentVariant,
          variantView: "preview-view",
        };
      });

      let updatedVariant = {
        ...variant,
        variantView: "form-view",
      };

      setVariantImg(variant.imgUrl);
      setVariantQuantity(variant.quantity);
      setVariantPrice(variant.price);
      setVariantSpecifications(variant.specifications);
      newPrevVariants.splice(index, 1, updatedVariant);

      console.log("first", newPrevVariants);
      return newPrevVariants;
    });
  };

  // handle Add New Variant
  const handleAddNewVariant = function (e) {
    console.log(productVariants, variantImg);
    if (
      variantImg !== "" &&
      variantQuantity !== "" &&
      variantPrice !== "" &&
      variantSpecifications !== ""
    ) {
      e.preventDefault();
      console.log("got hrrrr");

      setVariantImg("");
      setVariantQuantity("");
      setVariantPrice("");
      setVariantSpecifications("");

      // Update and create a new variant form
      setProductVariants((prevVariants) => {
        if (!prevVariants) return prevVariants;

        let newVariant = {
          _id: Math.floor(Math.random() * 1000).toString(),
          imgUrl: "",
          specifications: "",
          quantity: "",
          price: "",
          variantView: "form-view",
        };

        let newPrevVariants = prevVariants
          .map((variant) => {
            return {
              ...variant,
              variantView: "preview-view",
            };
          })
          .concat(newVariant);
        console.log(newPrevVariants, "weppp");
        return newPrevVariants;
      });
    }
  };

  // handle Upload Variant Img
  const handleUploadVariantImage = async function (e, id) {
    setErrorHandlerObj({ hasError: false, message: "" });
    setCurrentUploadId(id);
    if (e.target.files[0].size <= 512000) {
      // handle Upload Variant Img
      const token = localStorage.getItem("jwtToken");
      axios.defaults.headers.common["authorization"] = token;

      try {
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append("file", selectedFile);

        setIsFileUploadLoading(true);
        const { data } = await axios.post(`${api}/api/v1/upload`, formData);

        if (data) {
          setVariantImg(e.target.value);
        }

        // Update the variants arr
        setProductVariants((prevVariants) => {
          if (!prevVariants) return prevVariants;

          let index = prevVariants.findIndex(
            (currentVariant) => currentVariant._id === id
          );

          let updatedVariant = {
            ...prevVariants[index],
            imgUrl: data,
          };
          prevVariants.splice(index, 1, updatedVariant);

          return prevVariants;
        });
        setIsFileUploadLoading(false);
      } catch (ex) {
        setIsFileUploadLoading(false);

        setErrorHandlerObj({
          hasError: true,
          message: "Upload failed!",
        });
      }

      // setProductImg("");
    } else {
      setErrorHandlerObj({
        hasError: true,
        message: "File size exceeds 500kb!",
      });
    }
  };

  // USEEFFECT
  useEffect(() => {
    // Set the products variants array
    setProductVariants([
      {
        _id: Math.floor(Math.random() * 10000).toString(),
        imgUrl: "",
        specifications: "",
        quantity: "",
        price: "",
        variantView: "form-view",
      },
    ]);
  }, []);

  useEffect(() => {
    if (!isEmpty(currentStockObj)) {
      // Variants States
      setVariantsExists(isEmpty(currentStockObj.variants) ? false : true);
      setProductVariants((prevVariants) => {
        if (isEmpty(currentStockObj.variants))
          return [
            {
              _id: Math.floor(Math.random() * 10000).toString(),
              imgUrl: "",
              specifications: "",
              quantity: "",
              price: "",
              variantView: "form-view",
            },
          ];

        let newVariantsArr = currentStockObj.variants.map((variant) => {
          return {
            _id: variant._id,
            imgUrl: variant.image,
            specifications: variant.specification,
            quantity: variant.quantity,
            price: variant.price,
            variantView: "preview-view",
          };
        });

        setVariantImg(newVariantsArr[0].imgUrl);
        setVariantQuantity(newVariantsArr[0].quantity);
        setVariantPrice(newVariantsArr[0].price);
        setVariantSpecifications(newVariantsArr[0].specification);
        return newVariantsArr;
      });
    }
  }, [currentStockObj]);

  return (
    <div
      className={`product-variants-setup--inner ${
        pageContent !== "variants" ? "none" : ""
      }`}
      onClick={() => setIsBrandsPopupModalOpened(false)}
    >
      <form>
        {productVariants?.map((variant, index) => (
          <div key={index + 1} className="product-variant-container">
            <div className="form-header-wrapper">Variant {index + 1}</div>
            {/* Variant Form View Wrapper  */}
            <div
              className={`variant-form-view-wrapper ${
                variant.variantView === "form-view" ? "" : "none"
              }`}
            >
              {/* Variants Image form group */}
              <div className="product-info-setup-product-image-form-group file-form-group">
                <div className="file-form-group--inner">
                  <div className="file-input-wrapper">
                    <div className="file-input-title">
                      {!variant.imgUrl ? "Upload image" : ""}
                    </div>
                    {currentUploadId === variant._id && isFileUploadLoading && (
                      <div className="upload-loading-spinner-wrapper"></div>
                    )}
                    {!variantImg && (
                      <input
                        type="file"
                        id={"variant-img-file-input"}
                        className={"variant-img-input"}
                        placeholder={""}
                        // value={variantImg}
                        onChange={(e) => {
                          handleUploadVariantImage(e, variant._id);
                        }}
                        required
                      />
                    )}
                  </div>

                  {variantImg && (
                    <>
                      <img
                        className="product-img-preview"
                        src={variant.imgUrl}
                        alt=""
                      />
                    </>
                  )}
                </div>

                {variantImg && (
                  <div className="edit-variant-img-input">
                    <span>Edit image</span>
                    <input
                      type="file"
                      id={"variant-img-file-2-input"}
                      className={"variant-img-input"}
                      placeholder={""}
                      onChange={(e) => {
                        handleUploadVariantImage(e, variant._id);
                      }}
                    />
                  </div>
                )}
              </div>

              {/* Product Quantity */}

              {/* Flex form wrapper */}
              <div className="form-body-wrapper variant-quantity-and-price-form-group-wrapper">
                <div className="form-group variants-quatity-form-group">
                  <TextInput
                    type={"text"}
                    id={"variant-quantity-text-input"}
                    className={"variant-quantity-input"}
                    placeholder={""}
                    value={variantQuantity}
                    onChange={(e) => {
                      setVariantQuantity(e.target.value);

                      // Update the variants arr
                      setProductVariants((prevVariants) => {
                        if (!prevVariants) return prevVariants;

                        let updatedVariant = {
                          ...variant,
                          quantity: e.target.value,
                        };
                        prevVariants.splice(index, 1, updatedVariant);

                        return prevVariants;
                      });
                      // }
                    }}
                    required={true}
                    label={"Quantity"}
                  />
                </div>

                {/* Variant Price form group */}
                <div className="variant-price-form-group form-group">
                  <TextInput
                    type={"text"}
                    id={"variant-price-text-input"}
                    className={"variant-price-input"}
                    placeholder={""}
                    value={variantPrice}
                    onChange={(e) => {
                      setVariantPrice(e.target.value);

                      // Update the variants arr
                      setProductVariants((prevVariants) => {
                        if (!prevVariants) return prevVariants;

                        let updatedVariant = {
                          ...variant,
                          price: e.target.value,
                        };
                        prevVariants.splice(index, 1, updatedVariant);

                        return prevVariants;
                      });
                      // }
                    }}
                    label={"Price"}
                    required={true}
                  />
                </div>
              </div>

              {/* Product Specs form group */}
              <div className="product-info-setup-product-name-form-group form-group">
                <TextInput
                  type={"text"}
                  id={"product-specs-text-input"}
                  className={"product-specs-input"}
                  placeholder={"e.g  Medium, Red, etc"}
                  value={variantSpecifications}
                  onChange={(e) => {
                    setVariantSpecifications(e.target.value);

                    // Update the variants arr
                    setProductVariants((prevVariants) => {
                      if (!prevVariants) return prevVariants;

                      let updatedVariant = {
                        ...variant,
                        specifications: e.target.value,
                      };
                      prevVariants.splice(index, 1, updatedVariant);

                      return prevVariants;
                    });
                  }}
                  required={true}
                  label={"Specifications"}
                />
              </div>
            </div>

            {/* Variant Preview view wrapper */}
            <div
              className={`variant-preview-view-wrapper ${
                variant.variantView === "form-view" ? "none" : ""
              }`}
            >
              {/* Left Wrapper */}
              <div className="variant-preview-img-wrapper variant=preview-left-wrapper">
                <img src={variant.imgUrl} alt="" />
              </div>
              {/* Right Wrapper */}
              <div className="variant-preview-info-wrapper variant=preview-left-wrapper">
                <div className="edit-variant-button">
                  <button
                    onClick={(e) =>
                      handleEditCurrentVariant(e, variant._id, variant)
                    }
                  >
                    edit
                  </button>
                </div>

                <div className="variant-preview-info--inner">
                  <div className="preview--product-name-wrapper">
                    {productFormInputState.productName}
                  </div>
                  <div className="preview--variant-specs-wrapper">
                    {variant.specifications}
                  </div>
                  <div className="preview--variant-quantity-wrapper">
                    {variant.quantity} pcs
                  </div>
                  <div className="preview--variant-price-wrapper">
                    &#8358;{variant.price?.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="add-new-variant-button-wrapper">
          <button onClick={handleAddNewVariant}>
            {" "}
            <img src={plusIcon} alt="" /> Add New Variants
          </button>
        </div>
        {/* Submit form button */}
        <div className="submit-setup-form-button-wrapper">
          {isLoading && <LoadingSpinner />}
          <button onClick={handleSubmitStockSetupForm}>Submit</button>
        </div>
      </form>
    </div>
  );
}

export default ProductVariantsForm;
