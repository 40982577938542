import { useEffect, useState } from "react";

/**
 * This function performs some operation.
 * @param {IProps["inactivityExpireTime"]} inactivityExpireTime - The expireTime parameter reprents the expire time for inactivity in seconds.
 * @param {IProps["checkIntervalTime"]} param2 - The checkIntervalTime parameter represents the time interval, in seconds, in which a check would run to determine inactivity, should be less than the expire time.
 * @returns {IProps["onExpired"]} - The onExpired parameter is the function that executes when the page is inactive.
 */


function useInactivityLogout({
  inactivityExpireTime,
  checkIntervalTime = 2,
  onExpired,
}) {
  // Functions, States and Variables
  //   States
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  //   Functions
  //   Handles check for inactivity and executes the given function if app is in an inactive state
  const handleCheckForInactivity = function () {
    // console.log("checked");
    const expiryTime = localStorage.getItem("expiryTime");
    const currentTime = Date.now();
    if (Number(expiryTime) < currentTime) {
      // Call the functions to be triggered at inactivity
      setIsLoggedIn(false);
      onExpired();
    }
  };

  //   Calculates and updates the expiryTime based on the current time and the inactive expire time
  const handleUpdateExpireTime = function () {
    const currentTime = Date.now();
    const expiryTime = currentTime + inactivityExpireTime * 1000;
    localStorage.setItem("expiryTime", expiryTime.toString());
  };

  //   UseEffects
  //   Updated Expire Time when an event is triggered by  the user
  useEffect(() => {
    // Initial Trigger
    handleUpdateExpireTime();

    // On event triggers,
    window.addEventListener("click", handleUpdateExpireTime);
    window.addEventListener("scroll", handleUpdateExpireTime);
    window.addEventListener("keypress", handleUpdateExpireTime);
    window.addEventListener("mousemove", handleUpdateExpireTime);
    window.addEventListener("mouseover", handleUpdateExpireTime);

    // Cleanup functions for event listeners
    return () => {
      window.removeEventListener("click", handleUpdateExpireTime);
      window.removeEventListener("scroll", handleUpdateExpireTime);
      window.removeEventListener("keypress", handleUpdateExpireTime);
      window.removeEventListener("mousemove", handleUpdateExpireTime);
      window.addEventListener("mouseover", handleUpdateExpireTime);
    };
  }, []);

  //   Checks for inactivity every selected amount of seconds
  useEffect(() => {
    const interval = setInterval(
      handleCheckForInactivity,
      checkIntervalTime * 1000
    );

    // Cleanup function that cleans up the set interval
    return () => {
      clearInterval(interval);
    };
  }, []);

  return { isLoggedIn };
}

export default useInactivityLogout;
