import React from "react";
import "./RegisterSuccessful.css";
import CongratulationsIcon from "../../image/congratulation-icon.svg";

function RegisterSuccessful() {
  return (
    <div className="successful-form-submission-container--inner">
      <div className="congratulations-image-wrapper">
        <img src={CongratulationsIcon} alt="" />
      </div>

      {/* Main Header text wrapper */}
      <div className="container-main-header-text">Congratulations</div>

      {/* Sub Header text wrapper */}
      <div className="container-sub-header-text">
        You have successfully registered your account and a validation link has
        been sent to your mail.
      </div>
    </div>
  );
}

export default RegisterSuccessful;
