import React from "react";
import "./MerchantOrders.css";
import Top from "../../components/app-top/Top";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Main from "../../components/main-container/Main";
import useDisplayMessage from "../../utils/useDisplayMessage";
import StockOrders from "./stock-orders/StockOrders";
import VoucherOrders from "./voucher-orders/VoucherOrders";

function MerchantOrders() {
  // Hooks
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  return (
    <div className="app-page-container">
      <Top />
      <Sidebar activeName={"Purchases"} />

      <Main>
        <div className="app-page-container--inner">
          <div className="app--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />

            {/* Success handler component */}
            <SuccessHandler successHandlerObj={successHandlerObj} />
          </div>

          {/* app-container-main-section */}
          <section className="app-container-main-section">
            {/* For Normal Stock Products Orders */}
            {/* <StockOrders
              errorHandlerObj={errorHandlerObj}
              setErrorHandlerObj={setErrorHandlerObj}
              setSuccessHandlerObj={setSuccessHandlerObj}
            /> */}

            {/* Voucher Orders*/}
            <VoucherOrders
              errorHandlerObj={errorHandlerObj}
              successHandlerObj={successHandlerObj}
              setErrorHandlerObj={setErrorHandlerObj}
              setSuccessHandlerObj={setSuccessHandlerObj}
            />
          </section>
        </div>
      </Main>
    </div>
  );
}

export default MerchantOrders;
