import React from "react";
import "./DropdownInput.css";
import dropdownIcon from "../../../image/angle-down.svg";

const DropdownInput = ({
  className,
  id,
  name,
  optionArray,
  required,
  value,
  onChange,
  label,
}) => {
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <div className="select-dropdown-wrapper">
        <select
          name={name}
          id={id}
          className={className}
          required={required}
          value={value}
          onChange={onChange}
        >
          {optionArray.map((optionValue, i) => (
            <option
              key={i + 1}
              disabled={optionValue.default ? true : false}
              value={optionValue.key}
              hidden={optionValue.default ? true : false}
            >
              {optionValue.value}
            </option>
          ))}
        </select>
        <div className="dropdown-icon">
          <img src={dropdownIcon} alt="" />
        </div>
      </div>
    </>
  );
};

export default DropdownInput;
