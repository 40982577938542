import React, { useContext, useEffect, useState } from "react";
import "./VouchersToBeRedeemed.css";
import PrimaryButton from "../../../components/buttons/primary-button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../../components/contexts/SearchContext";
import closeIcon from "../../../image/close-icon.svg";
import { getVouchersToBeRedeemed } from "../../../redux/actions/vouchersActions";
import VouchersToBeRedeemedTable from "./vouchers-to-be-redeemed-table/VouchersToBeRedeemedTable";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";

const voucherFilterOptions = [
  {
    key: "all",
    value: "All",
  },
  {
    key: "redeemed",
    value: "Redeemed",
  },
  {
    key: "non-redeemed",
    value: "Non Redeemed",
  },
];
function VouchersToBeRedeemed({
  isVoucherSearched,
  setIsVoucherSearched,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  setIsSearchVoucherModalOpened,
  setIsRedeemVoucherModalOpened,
  setSelectedVoucherCodeForRedemption,
}) {
  // Functions, States and Variables
  const dispatch = useDispatch();
  const { searchedValue } = useContext(SearchContext);
  const vouchersToBeRedeemed = useSelector(
    (state) => state.vouchers.vouchersToBeRedeemed
  );
  const searchedVoucher = useSelector(
    (state) => state.vouchers.searchedVoucher
  );
  // States
  const [allVouchersToBeRedeemed, setAllVouchersToBeRedeemed] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPosts, setCurrentPosts] = useState(null);

  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  const [vouchersFilterParam, setVouchersFilterParam] =
    useState("non-redeemed");

  // Functions
  const handleOpenVoucherSearchModal = function () {
    setIsSearchVoucherModalOpened(true);
  };

  // handle Filter Vouchers TO Be Redeemed
  const handleFilterVouchersToBeRedeemed = function () {
    let filteredVouchers = vouchersToBeRedeemed
      ?.filter((voucher) => voucher.isActive === true)
      ?.filter((voucher) => {
        if (vouchersFilterParam === "redeemed") {
          return voucher.isRedemed === true;
        } else if (vouchersFilterParam === "non-redeemed") {
          return voucher.isRedemed === false;
        } else {
          return voucher;
        }
      })
      ?.filter(
        (voucher) =>
          voucher.user.firstName
            .toLowerCase()
            .includes(searchedValue.toLowerCase()) ||
          voucher.user.lastName
            .toLowerCase()
            .includes(searchedValue.toLowerCase())
      );

    console.log(filteredVouchers);
    // Update table state to filtered || searched rows
    if (!filteredVouchers) return false;
    setAllVouchersToBeRedeemed(filteredVouchers);
  };

  useEffect(() => {
    // dispatch(getAllCategories(setIsLoading, setErrorHandlerObj));
    dispatch(getVouchersToBeRedeemed(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    // If Voucher is searched, display searched voucher data, else, display all vouchers to be redeemed
    if (isVoucherSearched) {
      if (searchedVoucher) setAllVouchersToBeRedeemed(searchedVoucher);
    } else {
      if (searchedVoucher) handleFilterVouchersToBeRedeemed();
    }
  }, [vouchersToBeRedeemed, searchedVoucher, isVoucherSearched]);

  useEffect(() => {
    handleFilterVouchersToBeRedeemed();
  }, [searchedValue, vouchersFilterParam]);

  useEffect(() => {
    if (allVouchersToBeRedeemed) {
      const currentRows = allVouchersToBeRedeemed?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );
      setCurrentPosts(currentRows);
    }
  }, [allVouchersToBeRedeemed]);

  return (
    <>
      <section className="primary-table-section merchant-store-section">
        {/* Table Top Wrapper */}
        <div className="primary-table--top-wrapper ">
          {/*  Table Filter Wrapper */}
          {!isVoucherSearched ? (
            <div className="primary-table-filter-option-wrapper vouchers-to-be-redeemed-table-filter-wrapper">
              <div className="primary-table-filter-row-title">Filter:</div>
              {/* Date */}
              <div className="primary-table--date-filter-form-group form-group">
                <DropdownInput
                  className={"order-date-filter-dropdown-input"}
                  id={"order-date-filter-input"}
                  label={""}
                  required={true}
                  optionArray={voucherFilterOptions}
                  value={vouchersFilterParam}
                  onChange={(e) => setVouchersFilterParam(e.target.value)}
                />
              </div>
            </div>
          ) : (
            <div className=""></div>
          )}
          <div className="primary-btn-container">
            {!isVoucherSearched ? (
              <PrimaryButton
                className="open-search-modal-button-wrapper"
                placeholder="Search for a Voucher"
                onClick={() => handleOpenVoucherSearchModal()}
              />
            ) : (
              <PrimaryButton
                className="cancel-search-modal-button-wrapper"
                placeholder="Cancel Search"
                onClick={() => setIsVoucherSearched(false)}
              >
                <img src={closeIcon} alt="" />
              </PrimaryButton>
            )}
          </div>
        </div>

        {/*  Vouchers To Be Redeemed Table Container */}
        <VouchersToBeRedeemedTable
          currentPosts={currentPosts}
          allVouchersToBeRedeemed={allVouchersToBeRedeemed}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={isLoading}
          rowsPerPage={rowsPerPage}
          isVoucherSearched={isVoucherSearched}
          setIsRedeemVoucherModalOpened={setIsRedeemVoucherModalOpened}
          setSelectedVoucherCodeForRedemption={
            setSelectedVoucherCodeForRedemption
          }
          setErrorHandlerObj={setErrorHandlerObj}
          setSuccessHandlerObj={setSuccessHandlerObj}
        />
      </section>
    </>
  );
}

export default VouchersToBeRedeemed;
