import { GET_ALL_WITHDRAWALS } from "../constants";

const initialState = {
    withdrawals: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_WITHDRAWALS:
            return {
                ...state,
                withdrawals: action.payload
            };
        default:
            return {
                ...state
            };
    }
}