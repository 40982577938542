import axios from "axios";
import { GET_ALL_MERCHANT_VOUCHERS, GET_SEARCHED_VOUCHER, GET_VOUCHERS_TO_BE_REDEEMED, } from "../constants";
import { api } from "../../api/config";
import { handleAPIError } from "../../utils/handleAPIError";
// URL
const url = `${api}/api/v1/merchant`

const token = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;
// Get all Merchant's vouchers
export const getAllMerchantVouchers = (setIsLoading, setErrorHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.get(`${url}/get-merchant-voucher`)
        .then((res) => {
            dispatch({
                type: GET_ALL_MERCHANT_VOUCHERS,
                payload: res.data.data?.reverse(),
            });
            setIsLoading(false)
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}

// Get vouchers to be redeemed
export const getVouchersToBeRedeemed = (setIsLoading, setErrorHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.get(`${url}/get-users-vouchers`)
        .then((res) => {
            dispatch({
                type: GET_VOUCHERS_TO_BE_REDEEMED,
                payload: res.data.data,
            });
            // console.log(res.data.data)
            setIsLoading(false)
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}

// Get searched vouchers
export const getSearchedVoucher = (voucherCode, setIsLoading, setErrorHandlerObj, setIsVoucherSearched, setIsModalOpened) => (dispatch) => {
    setIsLoading(true)
    axios.get(`${url}/search-voucher/${voucherCode}`)
        .then((res) => {
            // TODO If my analysis is right, pass the data into the all merchant vouchers with a cancel search button on search and recall the get all merchants API on search 
            dispatch({
                type: GET_SEARCHED_VOUCHER,
                payload: res.data.data,
            });
            // Trigger change in table data value to searched voucher by updating the sstate to true
            setIsVoucherSearched(true)
            // Close Modal
            setIsModalOpened(false)
            setIsLoading(false)
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}


// Create a voucher
export const createMerchantVoucher = (data, setIsLoading, setErrorHandlerObj, navigate) => (dispatch) => {
    setIsLoading(true)
    axios.post(`${url}/voucher`, data)
        .then((res) => {
            setIsLoading(false);
            navigate("/merchant/stock", {
                state: { currentPageContent: "store-vouchers", message: "Voucher created successfully!" },
            });
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}

// Update a voucher
export const updateMerchantVoucher = (data, voucherId, setIsLoading, setErrorHandlerObj, navigate) => (dispatch) => {
    setIsLoading(true)
    axios.patch(`${url}/voucher/${voucherId}`, data)
        .then((res) => {
            setIsLoading(false);
            navigate("/merchant/stock", {
                state: { currentPageContent: "store-vouchers", message: "Voucher updated successfully!" },
            });
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}

// Send OTP Code For Voucher
export const sendOTPCodeForVoucher = (data, setIsLoading, setErrorHandlerObj, setSuccessHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.patch(`${url}/send-otp`, data)
        .then((res) => {
            setIsLoading(false);
            setSuccessHandlerObj({ isSuccess: true, message: "OTP Code sent to user successfully!" })
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}

// Redeem a voucher
export const redeemMerchantVoucher = (data, setIsLoading, setErrorHandlerObj, setSuccessHandlerObj, setIsModalOpened) => (dispatch) => {
    setIsLoading(true)
    axios.patch(`${url}/redeem-voucher`, data)
        .then((res) => {
            setIsLoading(false);
            setIsModalOpened(false)
            setSuccessHandlerObj({ isSuccess: true, message: "Voucher redeemed successfully!" });
            // Refectch Vouchers To Be Redeemed
            dispatch(getVouchersToBeRedeemed(setIsLoading, setErrorHandlerObj))
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}