import axios from "axios";
import { GET_ALL_STOCK, GET_ERRORS } from "../constants";
import isEmpty from "../../validation/isEmpty";
import { api } from "../../api/config";
import { handleAPIError } from "../../utils/handleAPIError";


const url = `${api}/api/v1/products`

const token = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;
// Get all Merchant's stock
export const getAllMerchantStock = (setIsLoading, setErrorHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.get(`${url}/merchant`)
        .then((res) => {
            dispatch({
                type: GET_ALL_STOCK,
                payload: res.data,
            });
            // // console.log(res.data)
            setIsLoading(false)
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}

// Create a stock
export const createMerchantStock = (data, setIsLoading, setErrorHandlerObj, setSuccessHandlerObj, setPageContent) => (dispatch) => {
    setIsLoading(true)
    axios.post(`${url}`, data)
        .then((res) => {
            // console.log(res.data)
            setIsLoading(false);

            setSuccessHandlerObj({ isSuccess: true, message: "Stock created successfully!" });
            setPageContent("setup-done");
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}


// Update a Merchant's stock
export const updateMerchantStock = (id, data, setIsLoading, setErrorHandlerObj, setSuccessHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.put(`${url}/${id}`, data)
        .then((res) => {
            // console.log(res.data)
            setIsLoading(false);

            setSuccessHandlerObj({ isSuccess: true, message: "Stock updated successfully!" })
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}


// Delete a stock
export const deleteMerchantStock = (id, setIsLoading, setErrorHandlerObj, setSuccessHandlerObj, navigate) => (dispatch) => {
    setIsLoading(true)
    axios.patch(`${url}/delete/${id}`)
        .then((res) => {
            // console.log(res.data)
            setIsLoading(false);

            setSuccessHandlerObj({ isSuccess: true, message: "Stock deleted successfully!" })

            setTimeout(() => {
                navigate(-1)
            }, 5000)
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}



