import { GET_MERCHANT_WALLET } from "../constants";

const initialState = {
    wallet: {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_MERCHANT_WALLET:
            return {
                ...state,
                wallet: action.payload
            };
        default:
            return {
                ...state
            };
    }
}