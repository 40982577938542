import { GET_ALL_ORDERS, GET_AN_ORDER, } from "../constants";

const initialState = {
    orders: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_ORDERS:
            return {
                ...state,
                orders: action.payload
            };
        case GET_AN_ORDER:
            return {
                ...state,
                order: action.payload
            };
        default:
            return {
                ...state
            };
    }
}