import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import isEmpty from "../../../../validation/isEmpty";
import pencilIcon from "../../../../image/pencil-icon.svg";
import angleDownIcon from "../../../../image/angle-down.svg";
import FEPagination from "../../../../components/pagination/fe-pagination/FEPagination";

function StoreTable({
  currentPosts,
  categories,
  allStoreStocks,
  currentPage,
  setCurrentPage,
  isLoading,
  rowsPerPage,
  handleToggleVariantsDisplay,
}) {
  // Functions, States and variables
  // Functions
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Get Category Name
  const getCategoryName = function (categoryId) {
    const currentCategoryArr = categories?.filter(
      (category) => category._id === categoryId
    );

    return currentCategoryArr[0]?.name;
  };

  // handle Stock Active Status Change
  const handleStockActiveStatusChange = function (stock, index) {};

  return (
    <>
      {/* All Store Stock Table Container */}
      <div className="primary-table-container">
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "PRODUCT NAME",
                "VARIANT",
                "QUANTITY",
                "CATEGORY",
                "PRICE (N)",
                "STATUS",
                "",
                "",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentPosts?.map((stock, index) => (
              <React.Fragment key={index + 1}>
                <tr
                  className={`${
                    stock.isVariantsOpened ? "active-table-row" : ""
                  }`}
                >
                  {/* Product Name Column */}
                  <td className="td-product-name">
                    <div className="td-product-name--wrapper">
                      {/* profile image wrapper */}
                      <div className="profile-image-wrapper">
                        <img src={allStoreStocks && stock.image} alt="" />
                      </div>
                      {stock.name}
                    </div>
                  </td>

                  {/*  Variants Column */}
                  <td className="td-variants">
                    <div className="td-variants--wrapper">
                      {isEmpty(stock.variants) ? (
                        "--"
                      ) : (
                        <>{stock.variants.length} variants</>
                      )}
                    </div>
                  </td>

                  {/*  Total quantity Column */}
                  <td className="td-total-quantity">
                    <div className="td-total-quantity--wrapper">
                      {stock.quantity}
                    </div>
                  </td>

                  {/*  Category Column */}
                  <td className="td-stock-category">
                    <div className="td-stock-category--wrapper">
                      {getCategoryName(stock.category)}
                    </div>
                  </td>

                  {/* Stock Price Column*/}
                  <td className="td-stock-price">
                    <div className="td-stock-price--wrapper">
                      {" "}
                      {stock.price?.toLocaleString()}
                    </div>
                  </td>

                  {/* Status Column*/}
                  <td className="td-status ">
                    <div className={`td-status--wrapper`}>
                      {stock.isActive ? "Active" : "Inactive"}
                      {/* Toggl switch container container */}
                      <div className="toggle-switch-container">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={stock.isActive}
                            onChange={() =>
                              handleStockActiveStatusChange(stock, index)
                            }
                            readOnly
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </td>

                  {/* Edit Stock Row Column */}
                  <td className="td-edit-stock-row">
                    <div className="td-edit-stock-row--wrapper">
                      <Link
                        to={"/merchant/stock/edit"}
                        state={{
                          stock,
                          toVariants: false,
                        }}
                      >
                        <img src={pencilIcon} alt="" />
                      </Link>
                    </div>
                  </td>

                  {/* Stock Status Column*/}
                  <td className="td-stock-status">
                    <div className="td-stock-status--wrapper">
                      <div
                        className={`stock-status-color-box ${
                          stock.quantity === 0
                            ? "out-of-stock-color-box"
                            : !stock.isApproved
                            ? "not-approved-color-box"
                            : "new-stock-color-box "
                        }`}
                      ></div>

                      {stock.quantity === 0
                        ? "Out of stock"
                        : !stock.isApproved
                        ? "Not Approved"
                        : "New Stock"}
                    </div>
                  </td>

                  {/* Show Variants Button Column*/}
                  <td
                    className="td-show-variants"
                    data-toggle="collapse"
                    data-target={`#folderRow${index}`}
                  >
                    <div className="td-show-variants--wrapper">
                      {isEmpty(stock.variants) ? (
                        ""
                      ) : (
                        <button
                          onClick={() =>
                            handleToggleVariantsDisplay(stock, index)
                          }
                        >
                          <img
                            className={`${
                              stock?.isVariantsOpened ? "opened" : ""
                            }`}
                            src={angleDownIcon}
                            alt=""
                          />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>

                {/* NESTED */}
                {/* Nested Table Rows / Variants Table Rows */}
                {!isEmpty(stock.variants) && (
                  <tr
                    className={`tr--variants-row collapse ${
                      stock.isVariantsOpened && "show"
                    }`}
                    id={`folderRow${index}`}
                  >
                    {/* Product Name Column */}
                    <td className="td-product-name hiddenRow">
                      <div className="td-product-name--wrapper td-variants-name--wrapper">
                        {/* profile image wrapper */}
                        {stock.variants.map((variant, i) => (
                          <div
                            key={i + 1}
                            className="variants--profile-image-wrapper"
                          >
                            <div className="profile-image-wrapper">
                              <img
                                src={allStoreStocks && variant.image}
                                alt=""
                              />
                            </div>
                            {stock.name}
                          </div>
                        ))}
                      </div>
                    </td>

                    {/*  Variants Column */}
                    <td className="td-variants hiddenRow">
                      <div className="td-variants--wrapper"></div>
                    </td>

                    {/*  Total quantity Column */}
                    <td className="td-total-quantity hiddenRow">
                      {stock.variants.map((variant, i) => (
                        <div
                          key={i + 1}
                          className="td-total-quantity--wrapper td--variants-total-quantity-wrapper"
                        >
                          {variant.quantity}
                        </div>
                      ))}
                    </td>

                    {/*  Category Column */}
                    <td className="td-stock-category hiddenRow">
                      {stock.variants.map((variant, i) => (
                        <div
                          key={i + 1}
                          className="td-stock-category--wrapper td--variants-stock-category-wrapper"
                        >
                          {getCategoryName(stock.category)}
                        </div>
                      ))}
                    </td>

                    {/* Stock Price Column*/}
                    <td className="td-stock-price hiddenRow">
                      {stock.variants.map((variant, i) => (
                        <div
                          key={i + 1}
                          className="td-stock-price--wrapper td--variants-stock-price-wrapper"
                        >
                          {stock.price?.toLocaleString()}
                        </div>
                      ))}
                    </td>

                    {/* Status Column*/}
                    <td className="td-status hiddenRow">
                      {stock.variants.map((variant, i) => (
                        <div
                          key={i + 1}
                          className={`td-status--wrapper td--variants-status-wrapper`}
                        >
                          {stock.isActive ? "Active" : "Inactive"}
                          {/* Toggl switch container container */}
                          <div className="toggle-switch-container">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={stock.isActive}
                                onChange={() =>
                                  handleStockActiveStatusChange(stock, i)
                                }
                                readOnly
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      ))}
                    </td>

                    {/* Edit Stock Row Column */}
                    <td className="td-edit-stock-row hiddenRow">
                      {stock.variants.map((variant, i) => (
                        <div
                          key={i + 1}
                          className="td-edit-stock-row--wrapper td--variants-edit-stock-wrapper"
                        >
                          <Link
                            to={"/merchant/stock/edit"}
                            state={{
                              stock,
                              toVariants: true,
                            }}
                          >
                            <img src={pencilIcon} alt="" />
                          </Link>
                        </div>
                      ))}
                    </td>

                    {/* Stock Status Column*/}
                    <td className="td-stock-status hiddenRow">
                      {stock.variants.map((variant, i) => (
                        <div
                          key={i + 1}
                          className="td-stock-status--wrapper td--variants-stock-status-wrapper"
                        >
                          <div
                            className={`stock-status-color-box ${
                              stock.quantity === 0
                                ? "out-of-stock-color-box"
                                : !stock.isApproved
                                ? "not-approved-color-box"
                                : "new-stock-color-box "
                            }`}
                          ></div>

                          {stock.quantity === 0
                            ? "Out of stock"
                            : !stock.isApproved
                            ? "Not Approved"
                            : "New Stock"}
                        </div>
                      ))}
                    </td>

                    {/* Show Variants Button Column*/}
                    <td className="td-show-variants hiddenRow">
                      <div className="td-show-variants--wrapper "></div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        <div className="stock-loader-wrapper">
          {/* Loading Spinner Wrapper */}
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {allStoreStocks && !isEmpty(allStoreStocks) && (
        <FEPagination
          paginate={paginate}
          rowsPerPage={rowsPerPage}
          totalPosts={allStoreStocks.length}
          currentPage={currentPage}
        />
      )}
    </>
  );
}

export default StoreTable;
