export const allMonths = [
    {
        key: "",
        value: "Month",
        default: "default",
    },

    {
        key: "January",
        value: "January",
    },
    {
        key: "February",
        value: "February",
    },
    {
        key: "March",
        value: "March",
    },
    {
        key: "April",
        value: "April",
    },
    {
        key: "May",
        value: "May",
    },
    {
        key: "June",
        value: "June",
    },
    {
        key: "July",
        value: "July",
    },
    {
        key: "August",
        value: "August",
    },
    {
        key: "September",
        value: "September",
    },
    {
        key: "October",
        value: "October",
    },
    {
        key: "November",
        value: "November",
    },
    {
        key: "December",
        value: "December",
    },
];