import { GET_ALL_MERCHANT_VOUCHERS, GET_SEARCHED_VOUCHER, GET_VOUCHERS_TO_BE_REDEEMED, } from "../constants";

const initialState = {
    vouchers: [],
    vouchersToBeRedeemed: [],
    searchedVoucher: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_MERCHANT_VOUCHERS:
            return {
                ...state,
                vouchers: action.payload
            };
        case GET_VOUCHERS_TO_BE_REDEEMED:
            return {
                ...state,
                vouchersToBeRedeemed: action.payload
            };
        case GET_SEARCHED_VOUCHER:
            return {
                ...state,
                searchedVoucher: action.payload
            };
        default:
            return {
                ...state
            };
    }
}