import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../../components/contexts/SearchContext";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import { getAllOrdersForMerchant } from "../../../redux/actions/orderActions";
import VoucherOrdersTable from "./voucher-orders-table/VoucherOrdersTable";
import "./VoucherOrders.css";

function VoucherOrders({
  errorHandlerObj,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}) {
  // Functions, States and Variables
  const dispatch = useDispatch();
  const { searchedValue } = useContext(SearchContext);
  const purchases = useSelector((state) => state.orders.orders);

  // States
  const [allPurchases, setAllPurchases] = useState(null);
  const [purchasesDateFilter, setPurchasesDateFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPosts, setCurrentPosts] = useState(null);

  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  //   Functions
  // handle Filter Merchant Purchases
  const handleFilterMerchantPurchases = function () {
    let filteredPurchasesData = purchases?.filter((order) => {
      // Searches through the purchases product name and buyer name
      //   TODO App product name to search paramenter when connecting API
      return (
        order?.userId?.firstName
          .toLowerCase()
          .includes(searchedValue.toLowerCase()) ||
        order?.userId?.lastName
          .toLowerCase()
          .includes(searchedValue.toLowerCase())
      );
    });
    setAllPurchases(filteredPurchasesData);
  };

  //   Hooks
  //   UseEffects
  useEffect(() => {
    dispatch(getAllOrdersForMerchant(setIsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    if (purchases) setAllPurchases(purchases);
  }, [purchases]);

  useEffect(() => {
    if (allPurchases) {
      const currentRows = allPurchases.slice(indexOfFirstPost, indexOfLastPost);
      setCurrentPosts(currentRows);
    }
  }, [allPurchases, currentPage]);

  useEffect(() => {
    handleFilterMerchantPurchases();
    setCurrentPage(1);
  }, [searchedValue]);

  return (
    <div className="voucher-orders-container">
      {/* Top  Container*/}
      <div className="app-page--top-container">
        {/* Top Companies Box Container */}
        <div className="merchants--top--box-container">
          {/* Box Title Wrapper */}
          <div className="box-title-wrapper">TOTAL PURCHASES</div>
          {/* Box Value Wrapper */}
          <div className="box-value-wrapper">{purchases?.length || 0}</div>
        </div>
        {/* Top Companies Box Container */}
        <div className="merchants--top--box-container">
          {/* Box Title Wrapper */}
          <div className="box-title-wrapper">TOTAL AMOUNT IN (₦)</div>
          {/* Box Value Wrapper */}
          <div className="box-value-wrapper">{(3000).toLocaleString()}</div>
        </div>
      </div>

      <section className={`primary-table-section`}>
        {/* Table Top Wrapper */}
        <div className="primary-table--top-wrapper">
          {/*  Table Filter Wrapper */}
          <div className="primary-table-filter-option-wrapper">
            <div className="primary-table-filter-row-title">Filter:</div>
            {/* Filter by date added dropdown */}
            <div className="primary-table--date-filter-form-group form-group">
              <DropdownInput
                className={"table-date-filter-dropdown-input"}
                label={""}
                required={true}
                optionArray={[{ key: "", value: "Date", default: "Default" }]}
                value={purchasesDateFilter}
                onChange={(e) => setPurchasesDateFilter(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Table Container */}
        <VoucherOrdersTable
          isLoading={isLoading}
          allPurchases={allPurchases}
          currentPosts={currentPosts}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </section>
    </div>
  );
}

export default VoucherOrders;
