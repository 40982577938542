import axios from "axios";
import { GET_ALL_CATEGORIES, GET_A_CATEGORY, GET_ERRORS } from "../constants";
import isEmpty from "../../validation/isEmpty";
import { api } from "../../api/config";
import { handleAPIError } from "../../utils/handleAPIError";


const url = `${api}/api/v1/category`

// Get all category
export const getAllCategories = (setIsLoading, setErrorHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.get(`${url}`)
        .then((res) => {
            dispatch({
                type: GET_ALL_CATEGORIES,
                payload: res.data,
            });
            // // console.log(res.data);
            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}

// Get a single category
export const getSingleCategories = (data, id, setIsLoading, setErrorHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.get(`${url}/${id}`)
        .then((res) => {
            dispatch({
                type: GET_A_CATEGORY,
                payload: res.data,
            });
            // // console.log(res.data)
            setIsLoading(false)
        })
        .catch((err) => {
            setIsLoading(false)
            // Set the error handler state
            handleAPIError(err, dispatch, setErrorHandlerObj)
        })
}
