import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../../../../../../api/config";
import { getAllCategories } from "../../../../../../redux/actions/categoryActions";
import {
  createMerchantStock,
  updateMerchantStock,
} from "../../../../../../redux/actions/stockActions";
import plusIcon from "../../../../../../image/plus-icon.svg";
import DropdownInput from "../../../../../input-components/dropdown-input/DropdownInput";
import TextInput from "../../../../../input-components/text-input/TextInput";
import LoadingSpinner from "../../../../../loading-spinner/LoadingSpinner";
import BrandsPopup from "../brands-popup/BrandsPopup";
import isEmpty from "../../../../../../validation/isEmpty";
import { getAllBrands } from "../../../../../../redux/actions/brandsActions";

function ProductInfoForm({
  pageContent,
  pageType,
  currentStockObj,
  currentUploadId,
  productImgArr,
  productFormInputState,
  isFileUploadLoading,
  variantsExists,
  setVariantsExists,
  setProductFormInputState,
  setIsFileUploadLoading,
  setCurrentUploadId,
  setPageContent,
  setProductImgArr,
  isBrandsPopupModalOpened,
  setIsBrandsPopupModalOpened,
  isLoading,
  setIsLoading,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}) {
  // Functions, States and Variables
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const brands = useSelector((state) => state.brands.brands);

  // States
  // Product Info form states
  const [allBrands, setAllBrands] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
  const [isAllBrandsLoading, setIsAllBrandsLoading] = useState(false);

  //   Functions
  // Handle Submit Product Info form
  const handleSubmitProductInfoForm = function (e) {
    if (
      productFormInputState.productImg !== "" &&
      productFormInputState.category !== "" &&
      productFormInputState.productQuantity !== "" &&
      productFormInputState.productDescription !== "" &&
      productFormInputState.price !== "" &&
      productFormInputState.productSizeCategory !== ""
    ) {
      e.preventDefault();

      const pictures = productImgArr.map((imgObj) => {
        return imgObj.imgUrl;
      });

      const data = {
        image: pictures[0],
        pictures,
        name: productFormInputState.productName,
        category: productFormInputState.category,
        quantity: parseInt(productFormInputState.productQuantity),
        description: productFormInputState.productDescription,
        price: parseInt(productFormInputState.price),
        brandId: productFormInputState.productBrand,
        size: productFormInputState.productSizeCategory,
        variants: [],
      };
      if (variantsExists) {
        console.log(data);
        setPageContent("variants");
      } else {
        if (pageType === "create") {
          // Call the create product API
          dispatch(
            createMerchantStock(
              data,
              setIsLoading,
              setErrorHandlerObj,
              setSuccessHandlerObj,
              setPageContent
            )
          );
        } else {
          //  Call the edit product API
          dispatch(
            updateMerchantStock(
              currentStockObj._id,
              data,
              setIsLoading,
              setErrorHandlerObj,
              setSuccessHandlerObj,
              setPageContent
            )
          );

          console.log("edit data", data);
        }
      }
    }
  };

  //   Handle Update product form input state
  const handleUpdateProductFormInputState = function (fieldName, fieldValue) {
    setProductFormInputState((prevFormInputState) => {
      const updatedFormInputStates = {
        ...prevFormInputState,
        [fieldName]: fieldValue,
      };
      return updatedFormInputStates;
    });
  };

  // handle Upload Main Product Img
  const handleUploadMainProductImg = async function (e, id) {
    setErrorHandlerObj({ hasError: false, message: "" });
    setCurrentUploadId(id);
    if (e.target.files[0].size <= 512000) {
      // handle Upload Product Img
      const token = localStorage.getItem("jwtToken");
      axios.defaults.headers.common["authorization"] = token;

      try {
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append("file", selectedFile);

        setIsFileUploadLoading(true);
        const { data } = await axios.post(`${api}/api/v1/upload`, formData);

        if (data) {
          handleUpdateProductFormInputState("productImg", e.target.value);
        }

        setProductImgArr((prevProductImg) => {
          if (!prevProductImg) return prevProductImg;

          let index = prevProductImg.findIndex(
            (currentProductImg) => currentProductImg._id === id
          );
          let updatedProductImg = {
            ...prevProductImg[index],
            imgUrl: data,
          };

          prevProductImg.splice(index, 1, updatedProductImg);
          console.log(prevProductImg);
          return prevProductImg;
        });
        setIsFileUploadLoading(false);
      } catch (ex) {
        setIsFileUploadLoading(false);

        setErrorHandlerObj({
          hasError: true,
          message: "Upload failed!",
        });
      }
    } else {
      setErrorHandlerObj({
        hasError: true,
        message: "File size exceeds 500kb!",
      });
    }
  };

  // handle Add Additional Product Img
  const handleAddAdditionalProductImg = function (e) {
    if (productFormInputState.productImg !== "") {
      e.preventDefault();
      //   Set product image to default empty statess
      handleUpdateProductFormInputState("productImg", "");
      setProductImgArr((prevProductImg) => {
        if (!prevProductImg) return prevProductImg;

        let allPrevProductImgArr = [...prevProductImg];

        let newProductObj = {
          _id: Math.floor(Math.random() * 1000).toString(),
          imgUrl: "",
        };
        allPrevProductImgArr.push(newProductObj);
        // console.log(allPrevProductImgArr);
        return allPrevProductImgArr;
      });
    }
  };

  // Get Brand Name from Id
  const getBrandNameFromId = function (brandId) {
    let currentBrand = allBrands?.filter((brand) => brand.key === brandId);
    return currentBrand[0]?.value || "Generic";
  };

  //   UseEffects
  useEffect(() => {
    dispatch(getAllCategories(setIsCategoriesLoading, setErrorHandlerObj));
    dispatch(getAllBrands(setIsAllBrandsLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    setAllCategories((prevCategories) => {
      let newCategoriesArr = categories?.map((category) => {
        return { key: category._id, value: category.name };
      });
      return newCategoriesArr;
    });
  }, [categories]);

  useEffect(() => {
    if (!isEmpty(brands)) {
      let brandsOptionArr = brands.data?.map((brand) => {
        return {
          key: brand._id,
          value: brand.name,
        };
      });
      // setAllBrands([{ key: "Generic", value: "Generic" }, ...brandsOptionArr]);
      setAllBrands([...brandsOptionArr]);
    }
  }, [brands]);

  useEffect(() => {
    if (!isEmpty(currentStockObj)) {
      setProductImgArr((prevProductImg) => {
        let newProductImgArr = currentStockObj?.pictures?.map(
          (productPicture) => {
            return {
              _id: Math.floor(Math.random() * 1000).toString(),
              imgUrl: productPicture,
            };
          }
        );

        return newProductImgArr;
      });
      setProductFormInputState((prevFormInputState) => {
        const updatedFormInputStates = {
          ...prevFormInputState,
          productName: currentStockObj.name,
          productImg: currentStockObj.image,
          category: currentStockObj.category._id,
          productQuantity: currentStockObj.quantity,
          productDescription: currentStockObj.description,
          price: currentStockObj.price,
          productSizeCategory: currentStockObj.size || "",
        };
        return updatedFormInputStates;
      });
    }
  }, [currentStockObj]);

  useEffect(() => {
    // Set Product Brand to current Stock brand or generic brand by default
    if (currentStockObj && brands?.data) {
      const genericBrandObj = brands.data.filter(
        (brand) => brand.name === "Generic"
      );
      setProductFormInputState((prevFormInputState) => {
        const updatedFormInputStates = {
          ...prevFormInputState,
          productBrand:
            currentStockObj?.brandId?._id || genericBrandObj[0]?.id || "",
        };
        return updatedFormInputStates;
      });
    }
  }, [currentStockObj, brands]);

  return (
    <div
      className={`product-info-setup--inner ${
        pageContent !== "product-info" ? "none" : ""
      }`}
    >
      <form>
        {/* Product Image form group */}
        {productImgArr?.map((productImgObj, i) => (
          <div
            key={i + 1}
            className="product-info-setup-product-image-form-group file-form-group"
            onClick={() => setIsBrandsPopupModalOpened(false)}
          >
            <div className="file-form-group--inner">
              <div className="file-input-wrapper">
                <div className="file-input-title">
                  {!productImgObj.imgUrl && "Upload image"}
                </div>
                {currentUploadId === productImgObj._id &&
                  isFileUploadLoading && (
                    <div className="upload-loading-spinner-wrapper"></div>
                  )}
                <input
                  type="file"
                  id={"product-img-file-input"}
                  className={"product-img-input"}
                  placeholder={""}
                  onChange={(e) =>
                    handleUploadMainProductImg(e, productImgObj._id)
                  }
                  required
                />
              </div>

              {productImgObj.imgUrl && (
                <>
                  <img
                    className="product-img-preview"
                    src={productImgObj.imgUrl}
                    alt=""
                  />
                </>
              )}
            </div>

            {productImgObj.imgUrl && (
              <div className="edit-variant-img-input">
                <span>Edit image</span>
                <input
                  type="file"
                  id={"product-img-file-2-input"}
                  className={"product-img-input"}
                  placeholder={""}
                  // value={variantImg}
                  onChange={(e) =>
                    handleUploadMainProductImg(e, productImgObj._id)
                  }
                />
              </div>
            )}
          </div>
        ))}

        {/* Add new product im button */}
        {productImgArr.length < 3 && (
          <div
            className="add-new-product-img-button-wrapper"
            onClick={() => setIsBrandsPopupModalOpened(false)}
          >
            <button onClick={handleAddAdditionalProductImg}>
              <img src={plusIcon} alt="" /> Add Additional Images
            </button>
          </div>
        )}

        {/* Flex form wrapper */}
        <div
          className="form-body-wrapper category-and-quantity-form-group-wrapper"
          onClick={() => setIsBrandsPopupModalOpened(false)}
        >
          {/* Category Formgroup */}
          <div className="form-group">
            <DropdownInput
              className={"product-category-dropdown-input"}
              id={"product-category-name-input"}
              label={"Category"}
              required={true}
              optionArray={[
                {
                  key: "",
                  value: "",
                  default: "default",
                },
                ...allCategories,
              ]}
              value={productFormInputState.category}
              onChange={(e) =>
                handleUpdateProductFormInputState("category", e.target.value)
              }
            />
          </div>

          {/* Product Quantity */}
          <div className="form-group">
            <TextInput
              type={"text"}
              id={"product-quantity-text-input"}
              className={"product-quantity-input"}
              placeholder={""}
              value={productFormInputState.productQuantity}
              onChange={(e) => {
                handleUpdateProductFormInputState(
                  "productQuantity",
                  e.target.value
                );
              }}
              required={true}
              label={"Quantity"}
            />
          </div>
        </div>

        {/* Product Name form group */}
        <div
          className="product-info-setup-product-name-form-group form-group"
          onClick={() => setIsBrandsPopupModalOpened(false)}
        >
          <TextInput
            type={"text"}
            id={"product-name-text-input"}
            className={"product-name-input"}
            placeholder={""}
            value={productFormInputState.productName}
            onChange={(e) =>
              handleUpdateProductFormInputState("productName", e.target.value)
            }
            required={true}
            label={"Product Name"}
          />
        </div>

        {/* Product Description form group */}
        <div
          className="product-info-setup-product-descrp-form-group shop-details-setup-shop-address-form-group form-group"
          onClick={() => setIsBrandsPopupModalOpened(false)}
        >
          <label htmlFor="product-descrp-textarea-input">
            Product Description
          </label>
          <textarea
            id={"product-descrp-textarea-input"}
            className={"product-descrp-input"}
            placeholder={""}
            value={productFormInputState.productDescription}
            onChange={(e) =>
              handleUpdateProductFormInputState(
                "productDescription",
                e.target.value
              )
            }
            required={true}
          />
        </div>

        {/* Product Price form group */}
        <div
          className="product-info-setup-price-form-group form-group"
          onClick={() => setIsBrandsPopupModalOpened(false)}
        >
          <TextInput
            type={"number"}
            id={"product-price-text-input"}
            className={"product-price-input"}
            placeholder={""}
            value={productFormInputState.price}
            onChange={(e) => {
              handleUpdateProductFormInputState("price", e.target.value);
            }}
            label={"Price"}
          />
        </div>

        {/* Product Brand form group */}
        <div
          className={`product-brand-setup-product-name-form-group ${
            isBrandsPopupModalOpened ? "form-group-active" : ""
          }`}
        >
          {/* All Brands Popup */}
          <BrandsPopup
            isBrandsPopupModalOpened={isBrandsPopupModalOpened}
            setIsBrandsPopupModalOpened={setIsBrandsPopupModalOpened}
            handleUpdateProductFormInputState={
              handleUpdateProductFormInputState
            }
            allBrands={allBrands}
            isAllBrandsLoading={isAllBrandsLoading}
          />

          <div
            className="form-group"
            onClick={() => setIsBrandsPopupModalOpened(true)}
          >
            <label htmlFor={"product-brand-text-input"}>Brand</label>
            <input
              type={"text"}
              id={"product-brand-text-input"}
              className={"product-brand-input"}
              placeholder={""}
              value={getBrandNameFromId(productFormInputState.productBrand)}
              onFocus={() => setIsBrandsPopupModalOpened(true)}
              required={true}
              label={"Brand"}
              readOnly={true}
            />
          </div>
        </div>

        {/* Products Size Category Form Wrapper */}
        <div
          className="product-info-setup-size-category-form-group"
          onClick={() => setIsBrandsPopupModalOpened(false)}
        >
          <div className="form-check">
            <label>
              <input
                type="radio"
                name="product-size-category"
                checked={Boolean(
                  productFormInputState.productSizeCategory === "small"
                )}
                className="form-check-input"
                onChange={(e) => {
                  handleUpdateProductFormInputState(
                    "productSizeCategory",
                    "small"
                  );
                }}
                required
              />
              Small
            </label>
          </div>

          <div className="form-check">
            <label>
              <input
                type="radio"
                name="product-size-category"
                checked={Boolean(
                  productFormInputState.productSizeCategory === "medium"
                )}
                className="form-check-input"
                onChange={(e) => {
                  handleUpdateProductFormInputState(
                    "productSizeCategory",
                    "medium"
                  );
                }}
                required
              />
              Medium
            </label>
          </div>

          <div className="form-check">
            <label>
              <input
                type="radio"
                name="product-size-category"
                checked={Boolean(
                  productFormInputState.productSizeCategory === "large"
                )}
                className="form-check-input"
                onChange={(e) => {
                  handleUpdateProductFormInputState(
                    "productSizeCategory",
                    "large"
                  );
                }}
                required
              />
              Large
            </label>
          </div>
        </div>

        {/* Variants checkbox form group */}
        <div
          className="product-info-setup-variants-exists-form-group"
          onClick={() => setIsBrandsPopupModalOpened(false)}
        >
          <input
            type="checkbox"
            checked={variantsExists}
            onChange={(e) => setVariantsExists(!variantsExists)}
            id="variants-exists-checkbox-input"
          />
          <label htmlFor="variants-exists-checkbox-input">Variants</label>
        </div>

        {/* Submit form button */}
        <div
          className="submit-setup-form-button-wrapper"
          onClick={() => setIsBrandsPopupModalOpened(false)}
        >
          {isLoading && <LoadingSpinner />}
          <button onClick={handleSubmitProductInfoForm}>
            {variantsExists ? " Save & Next" : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ProductInfoForm;
