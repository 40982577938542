import React, { useState, useEffect } from "react";
import AuthNavbar from "../../components/auth-navbar/AuthNavbar";
import "./MerchantSignup.css";
import authWaveImage from "../../image/signin-wave-image.svg";
import TextInput from "../../components/input-components/text-input/TextInput";
import { Link } from "react-router-dom";
import RegisterSuccessful from "../../components/register-successful-comp/RegisterSuccessful";
import eyeOpenIcon from "../../image/eye-open-icon.svg";
import eyeCloseIcon from "../../image/eye-close-icon.svg";
import { useDispatch } from "react-redux";
import { registerUser } from "../../redux/actions/userActions";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import ErrorHandler from "../../components/error/ErrorHandler";
import PhoneInput from "../../components/input-components/phone-input/PhoneInput";

function MerchantSignup() {
  // Functions, States and Variables
  // const resStatusCode = useSelector((state) => state.resStatusCode);

  const dispatch = useDispatch();
  // States
  const [shopEmail, setShopEmail] = useState("");
  const [shopName, setShopName] = useState("");
  const [phoneNumberOne, setPhoneNumberOne] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] =
    useState(false);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const [isPasswordMatching, setIsPasswordMatching] = useState(true);
  const [registerSuccessful, setRegisterSuccessful] = useState(false);

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message:
      "lorem ipsum dolir dolor, orem ipsum dolir dolo, orem ipsum dolir dolo",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: true,
    message: "Product successfully added!",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  // handle submit signup form
  const handleSubmitMerchantSignupDetails = function (e) {
    setIsPasswordMatching(true);

    if (
      shopEmail !== "" &&
      shopName !== "" &&
      phoneNumberOne !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      isTermsAndConditionsChecked
    ) {
      e.preventDefault();
      if (password !== confirmPassword) {
        e.preventDefault();
        setIsPasswordMatching(false);
      } else {
        const userData = {
          email: shopEmail,
          shopName,
          phoneNumber: phoneNumberOne,
          password,
        };

        dispatch(
          registerUser(
            userData,
            setIsLoading,
            setErrorHandlerObj,
            setRegisterSuccessful
          )
        );
      }
      setTimeout(() => {
        setIsPasswordMatching(true);
      }, 10000);
    }
  };

  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      let xCoord = 0,
        yCoord = 0;

      //scroll to page top
      window.scrollTo(xCoord, yCoord);
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  return (
    <div className="merchant-signup-container">
      <AuthNavbar />
      {/* Successful Register Form Submission Container */}
      <div
        className={`successful-form-submission-container ${
          !registerSuccessful && "none"
        }`}
      >
        <RegisterSuccessful />
      </div>
      <div
        className={`merchant-signup-container--inner ${
          registerSuccessful && "none"
        }`}
      >
        {/* Merchant Signup Left section  / Auth Left Section*/}
        <section className="auth-left-section merchant-signup-left-section">
          {/* Merchant Signup left section text section */}
          <div className="merchant-signup-left-section-text-wrapper">
            Make sales and your money is ensured
          </div>

          {/* Auth Left Image Wrapper */}
          <div className="left-auth-section-image-wrapper">
            <img src={authWaveImage} alt="" />
          </div>
        </section>

        {/* Merchant Signup Right section  / Auth Right Section*/}
        <section className="auth-right-section merchant-signup-right-section">
          <div className="auth-message-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
          </div>
          <div className="merchant-signup-right-section--inner">
            {/* Merchant Signup right section header */}
            <div className="merchant-signup-right-section-header">
              {/* Merchant Signup right section header title */}
              <div className="merchant-signup-right-section-header-title">
                Merchant Sign up
              </div>

              <div className="merchant-signup-right-section-header-sub-title">
                Already a Merchant,{" "}
                <span>
                  <Link to={"/signin"}>Sign in</Link>
                </span>
              </div>
            </div>

            {/* Merchant Signup main form section */}
            <div className="merchant-signup-main-form-section">
              <form>
                {/* Shop Name form group */}
                <div className="merchant-shop-name-form-group form-group">
                  <TextInput
                    type={"text"}
                    id={"merchant-shop-name-text-input"}
                    className={"merchant-shop-name-input"}
                    placeholder={""}
                    value={shopName}
                    onChange={(e) => setShopName(e.target.value)}
                    required={true}
                    label={"Store Name"}
                  />
                </div>

                {/* Shop Email form group */}
                <div className="merchant-shop-email-form-group form-group">
                  <TextInput
                    type={"email"}
                    id={"merchant-shop-email-text-input"}
                    className={"merchant-shop-email-input"}
                    placeholder={""}
                    value={shopEmail}
                    onChange={(e) => setShopEmail(e.target.value)}
                    required={true}
                    label={"Email"}
                    subLabel={"Preferably Shop mail"}
                  />
                </div>

                {/* Phone Number One form group */}
                <div className="merchant-phone-number-one-form-group form-group">
                  <PhoneInput
                    type={"tel"}
                    id={"merchant-phone-number-one-text-input"}
                    className={"merchant-phone-number-input"}
                    placeholder={""}
                    value={phoneNumberOne}
                    onChange={(e) => setPhoneNumberOne(e.target.value)}
                    required={true}
                    label={"Phone Number"}
                    maxLength={11}
                  />
                </div>

                {/* Password form group */}
                <div className="merchant-signup-password-form-group form-group">
                  <img
                    src={isPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                    alt=""
                    className="password-icon-wrapper"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  />

                  <label htmlFor={"merchant-signup-password-input"}>
                    Password
                    <span>(Min 1 upper-cased, 1 number and 1 symbol)</span>
                  </label>
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    id={"merchant-signup-password-input"}
                    className={
                      "merchant-signup-password-text-input password-input"
                    }
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required={true}
                    placeholder="At least 8 characters"
                  />
                </div>

                {/* Password form group */}
                <div className="merchant-signup-confirm-password-form-group form-group">
                  <img
                    src={isConfirmPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                    alt=""
                    className="password-icon-wrapper"
                    onClick={() =>
                      setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                    }
                  />

                  <label htmlFor={"merchant-signup-confirm-password-input"}>
                    Retype Password
                  </label>
                  <input
                    type={isConfirmPasswordVisible ? "text" : "password"}
                    id={"merchant-signup-confirm-password-input"}
                    className={
                      "merchant-signup-confirm-password-text-input password-input"
                    }
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required={true}
                  />
                </div>

                {/* Password not matching error message */}
                <div
                  className={`password-not-matching-error-message-wrapper ${
                    !isPasswordMatching ? "" : "none"
                  }`}
                >
                  Passwords do not match!
                </div>

                {/* Terms and Conditions Form Group */}
                <div className="terms-conditions-form-group">
                  <input
                    type="checkbox"
                    name="terms-conditions-checkbox"
                    checked={isTermsAndConditionsChecked}
                    onChange={(e) =>
                      setIsTermsAndConditionsChecked(
                        !isTermsAndConditionsChecked
                      )
                    }
                    id="terms-and-conditions-checkbox"
                    required
                  />
                  <label htmlFor="terms-and-conditions-checkbox">
                    I agree to the{" "}
                    <Link to="/employer-mou" target="_blank">
                      Terms and Conditions
                    </Link>{" "}
                    and{" "}
                    <a
                      href={`${process.env.REACT_APP_LANDING_URL}/privacy`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </label>
                </div>

                {/* Submit form button wrapper */}
                <div className="merchant-signup-button-wrapper">
                  {isLoading && <LoadingSpinner />}
                  <button onClick={handleSubmitMerchantSignupDetails}>
                    Submit
                  </button>
                </div>

                {/* Not a merchant wrapper wrapper  */}
                <div className="not-a-merchant-wrapper">
                  <div className="not-a-merchant-wrapper--inner">
                    <div className="row-one">
                      Not a Merchant,
                      <span>
                        <a
                          href={`${process.env.REACT_APP_COMPANY_URL}/employer/signup`}
                        >
                          Sign up as Employer
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default MerchantSignup;
