import React, { useEffect, useState } from "react";
import moment from "moment";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import isEmpty from "../../../validation/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantWalletHistory } from "../../../redux/actions/userActions";
import BEPagination from "../../../components/pagination/be-pagination/BEPagination";

const tableFilterOptions = [
  { key: "all", value: "All" },
  { key: "credit", value: "Credits" },
  { key: "debit", value: "Debits" },
];

function WalletHistoryTable({ setErrorHandlerObj }) {
  // Functions, States and Variables
  const dispatch = useDispatch();
  const walletHistory = useSelector((state) => state.users.walletHistory);
  // States
  const [walletHistoryPosts, setWalletHistoryPosts] = useState(null);
  const [filterOption, setFilterOption] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumberIndex, setPageNumberIndex] = useState(1);

  // Functions
  //   Filter History By Wallet History By Type
  const handleFilterWalletHistoryByType = function (filterParam) {
    if (filterParam === "all") {
      setWalletHistoryPosts(walletHistory?.data);
    } else {
      // Handle Filter Transaction History Table on change of search value
      const walletHistoryData = walletHistory?.data?.filter(
        (history) => history.transactionType === filterParam
      );
      // Update table state to Filtered Staff for the filter section
      if (walletHistoryData) setWalletHistoryPosts(walletHistoryData);
    }
  };

  const handleFetchMerchantWalletHistory = function () {
    dispatch(
      getMerchantWalletHistory(
        pageNumberIndex,
        setIsLoading,
        setErrorHandlerObj
      )
    );
  };
  //   UseEffects
  useEffect(() => {
    // Fetch voucher based on voucher filter parameters and selected paginated page
    handleFetchMerchantWalletHistory();
  }, [pageNumberIndex]);

  useEffect(() => {
    handleFilterWalletHistoryByType(filterOption);
  }, [walletHistory, filterOption]);

  return (
    <section className="recent-wallet-transactions-container">
      {/* <div className="section-title">RECENT WALLET TRANSACTIONS</div> */}
      <h3>Wallet History</h3>

      <div className="secondary--table-filter-options-container">
        {tableFilterOptions?.map((filterObj, index) => (
          <div
            key={index + 1}
            className={`secondary--table-filter-option ${
              filterOption === filterObj.key
                ? "secondary--active-filter-option"
                : ""
            }`}
            onClick={() => {
              setFilterOption(filterObj.key);
            }}
          >
            {filterObj.value}
          </div>
        ))}
      </div>
      <div className="primary-table-container">
        <Table
          responsive="lg"
          className="primary-table all-benefits-request-table"
        >
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {["TYPE", "DATE", "AMOUNT"].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {!isLoading &&
              walletHistoryPosts?.map((history, i) => (
                <tr key={i + 1}>
                  {/*  Type Column */}
                  <td className="td-type">
                    <div
                      className={`td-type--wrapper ${
                        history.transactionType === "credit"
                          ? "credit-type-wrapper"
                          : "debit-type-wrapper"
                      }`}
                    >
                      {history.transactionType === "credit"
                        ? "Credit"
                        : "Debit"}
                    </div>
                  </td>

                  {/* Date Column*/}
                  <td className="td-date">
                    <div className="td-date--wrapper">
                      {moment(history.createdAt).format("Do MMM, YY")}
                    </div>
                  </td>

                  {/* Action Column */}
                  {/* <td className="td-action">
                  <div className={`td-action--wrapper`}>{history.action}</div>
                </td> */}

                  {/* Amount Column */}
                  <td className="td-amount">
                    <div className="td-amount--wrapper">
                      &#8358;{history.amount.toLocaleString()}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="below-table-loader-wrapper">
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {!isEmpty(walletHistoryPosts) &&
        !(isLoading && isEmpty(walletHistoryPosts)) && (
          <BEPagination
            currentPage={walletHistory?.currentPage || 1}
            lastPage={walletHistory.totalPages}
            currentPosts={walletHistoryPosts}
            setPageNumberIndex={setPageNumberIndex}
          />
        )}
    </section>
  );
}

export default WalletHistoryTable;
