export const allBanks = [
    { value: "Access Bank", key: "044" },
    { value: "Citibank", key: "023" },
    { value: "Diamond Bank", key: "063" },
    { value: "Dynamic Standard Bank", key: "" },
    { value: "Ecobank", key: "050" },
    { value: "Fidelity Bank", key: "070" },
    { value: "First Bank of Nigeria", key: "011" },
    { value: "First City Monument Bank", key: "214" },
    { value: "Globus Bank", key: "100" },
    { value: "GTBank", key: "058" },
    { value: "Heritage Bank Plc", key: "030" },
    { value: "Keystone Bank Limited", key: "082" },
    { value: "Kuda Bank", key: "39" },
    { value: "Providus Bank Plc", key: "101" },
    { value: "Polaris Bank", key: "076" },
    { value: "Stanbic IBTC Bank Nigeria Limited", key: "221" },
    { value: "Standard Chartered Bank", key: "068" },
    { value: "Sterling Bank", key: "232" },
    { value: "Suntrust Bank Nigeria Limited", key: "101" },
    { value: "Titan Trust Bank Ltd", key: "126" },
    { value: "Union Bank of Nigeria", key: "032" },
    { value: "United Bank for Africa", key: "033" },
    { value: "Unity Bank Plc", key: "215" },
    { value: "Wema Bank", key: "035" },
    { value: "Zenith Bank", key: "057" },
];