export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_USER_VERIFICATION_STATUS = "GET_USER_VERIFICATION_STATUS";
export const GET_ERRORS = "GET_ERRORS";
// Stock
export const GET_ALL_STOCK = "GET_ALL_STOCK";
export const GET_A_STOCK = "GET_A_STOCK";
// Vouchers
export const GET_ALL_MERCHANT_VOUCHERS = "GET_ALL_MERCHANT_VOUCHERS";
export const GET_VOUCHERS_TO_BE_REDEEMED = "GET_VOUCHERS_TO_BE_REDEEMED";
export const GET_SEARCHED_VOUCHER = "GET_SEARCHED_VOUCHER";
// Get all categories
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_A_CATEGORY = "GET_A_CATEGORY";
// Orders
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_AN_ORDER = "GET_AN_ORDER";
// Withdrawals
export const GET_ALL_WITHDRAWALS = "GET_ALL_WITHDRAWALS";
// Wallet
export const GET_MERCHANT_WALLET = "GET_MERCHANT_WALLET";
// Revenues
export const GET_ALL_REVENUES = "GET_ALL_REVENUES";
// Delivery Locations
export const GET_MERCHANT_DELIVERY_LOCATIONS = "GET_MERCHANT_DELIVERY_LOCATIONS";
// Brands
export const GET_ALL_BRANDS = "GET_ALL_BRANDS";
// 
export const GET_MERCHANT_WALLET_HISTORY = "GET_MERCHANT_WALLET_HISTORY";

