import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/buttons/primary-button/PrimaryButton";
import { SearchContext } from "../../../components/contexts/SearchContext";
import DropdownInput from "../../../components/input-components/dropdown-input/DropdownInput";
import SubstituteLoadingSpinner from "../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import { getCurrentUser } from "../../../redux/actions/authActions";
import isEmpty from "../../../validation/isEmpty";
import "./MerchantVouchers.css";
import VouchersTable from "./vouchers-table/VouchersTable";
import { getAllMerchantVouchers } from "../../../redux/actions/vouchersActions";
import { getAllCategories } from "../../../redux/actions/categoryActions";

function MerchantVouchers({ setErrorHandlerObj, setSuccessHandlerObj }) {
  // Functions, States and Variables
  const { searchedValue } = useContext(SearchContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.users.user);
  const categories = useSelector((state) => state.categories.categories);
  const voucherStock = useSelector((state) => state.vouchers.vouchers);

  // States
  const [allVoucherStocks, setAllVouchersStocks] = useState(null);
  const [stockDateFilter, setStockDateFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // is Add New Stock Stock button Clicked
  const [isAddNewStockButtonClicked, setIsAddNewStockButtonClicked] =
    useState(false);
  const [isUserInfoloading, setIsUserInfoLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPosts, setCurrentPosts] = useState(null);

  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  // Functions
  // Handle Navigate to add new stock
  const handleNavigateToAddNewStock = function () {
    setIsAddNewStockButtonClicked(true);
    setErrorHandlerObj({ hasError: false, message: "" });

    // Check if userInfo is not empty(i.e is gotten), then check and navigate / throw error respectively
    if (!isEmpty(userInfo)) {
      if (
        userInfo?.address &&
        userInfo?.accountNumber &&
        userInfo?.bvn &&
        !isEmpty(userInfo?.deliverycost)
      ) {
        // If user is approved
        if (userInfo.isApproved) {
          navigate("/merchant/voucher/create");
        } else {
          navigate("/merchant/awaiting-approval");
        }
      } else {
        setErrorHandlerObj({
          hasError: true,
          message: "Kindly complete account setup before you can proceed!",
        });
      }
    }
  };

  // handle Filter Merchant Stock
  const handleFilterMerchantStock = function (value) {
    let filteredVoucherStock = voucherStock
      ?.filter((voucher) => voucher.isActive === true)
      ?.filter((voucher) =>
        voucher.voucherDescription
          .toLowerCase()
          .includes(searchedValue.toLowerCase())
      );
    // Update table state to filtered || searched rows
    if (!filteredVoucherStock) return false;
    setAllVouchersStocks(filteredVoucherStock);
  };

  //   Useeffects
  useEffect(() => {
    // If Button is clicked before userInfo is gotten, trigger call on change of user info
    if (isAddNewStockButtonClicked) {
      if (
        userInfo?.profilePicture &&
        userInfo?.address &&
        userInfo?.cacDocument &&
        userInfo?.accountNumber &&
        !isEmpty(userInfo?.deliverycost)
      ) {
        // If user is approved
        if (userInfo.isApproved) {
          navigate("/merchant/stock/create");
        } else {
          navigate("/merchant/awaiting-approval");
        }
      } else {
        setErrorHandlerObj({
          hasError: true,
          message: "Kindly complete account setup before you can proceed!",
        });
      }
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(getAllCategories(setIsLoading, setErrorHandlerObj));
    dispatch(getAllMerchantVouchers(setIsLoading, setErrorHandlerObj));
    dispatch(getCurrentUser(setIsUserInfoLoading, setErrorHandlerObj));
  }, []);

  useEffect(() => {
    console.log(voucherStock);
    setAllVouchersStocks(voucherStock);
  }, [voucherStock]);

  useEffect(() => {
    handleFilterMerchantStock();
  }, [searchedValue, currentPage]);

  useEffect(() => {
    // console.log(allStoreStocks);
    if (allVoucherStocks) {
      const currentRows = allVoucherStocks?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentPosts(currentRows);
    }
  }, [allVoucherStocks]);

  return (
    <>
      <section className="primary-table-section merchant-store-section">
        {/* Table Top Wrapper */}
        <div className="primary-table--top-wrapper">
          {/*  Table Filter Wrapper */}
          <div className="primary-table-filter-option-wrapper">
            <div className="primary-table-filter-row-title">Filter:</div>
            {/* Date */}
            <div className="primary-table--date-filter-form-group form-group">
              <DropdownInput
                className={"order-date-filter-dropdown-input"}
                id={"order-date-filter-input"}
                label={""}
                required={true}
                optionArray={[
                  { key: "", value: "Date", default: "Default" },
                  {
                    key: "yesterday",
                    value: "Yesterday",
                  },
                ]}
                value={stockDateFilter}
                onChange={(e) => setStockDateFilter(e.target.value)}
              />
            </div>
          </div>

          <div className="primary-btn-container">
            <PrimaryButton
              className="add-new-stock-button-wrapper"
              placeholder="Add New"
              onClick={() => handleNavigateToAddNewStock()}
            />
            {isAddNewStockButtonClicked && isUserInfoloading && (
              <SubstituteLoadingSpinner />
            )}
          </div>
        </div>

        {/*  Vouchers Table Container */}
        <VouchersTable
          categories={categories}
          currentPosts={currentPosts}
          allVoucherStocks={allVoucherStocks}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={isLoading}
          rowsPerPage={rowsPerPage}
        />
      </section>
    </>
  );
}

export default MerchantVouchers;
