import dashboardIcon from "../image/sidebar-svg/dashboard-icon.svg";
import dashboardIconActive from "../image/sidebar-svg/dashboard-icon-active.svg";
import productsIcon from "../image/sidebar-svg/products-icon.svg";
import productsIconActive from "../image/sidebar-svg/products-icon-active.svg";
import purchasesIcon from "../image/sidebar-svg/purchases-icon.svg";
import purchasesIconActive from "../image/sidebar-svg/purchases-icon-active.svg";
import walletIcon from "../image/sidebar-svg/wallet-icon.svg";
import walletIconActive from "../image/sidebar-svg/wallet-icon-active.svg";

export const allNavLinks = [
  {
    name: "Dashboard",
    active: false,
    icon: dashboardIcon,
    activeIcon: dashboardIconActive,
    link: "/merchant/dashboard",
  },
  {
    name: "Products",
    active: false,
    icon: productsIcon,
    activeIcon: productsIconActive,
    link: "/merchant/stock",
  },
  {
    name: "Purchases",
    active: false,
    icon: purchasesIcon,
    activeIcon: purchasesIconActive,
    link: "/merchant/orders",
  },
  {
    name: "Wallet History",
    active: false,
    icon: walletIcon,
    activeIcon: walletIconActive,
    link: "/merchant/wallet-history",
  },
];
