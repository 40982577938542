import React from "react";
import Top from "../../app-top/Top";
import VoucherSetupLayout from "./voucher-setup-layout/VoucherSetupLayout";

function VoucherSetup({
  pageType,
  currentVoucherObj,
  pageContent,
  setPageContent,
  errorHandlerObj,
  setErrorHandlerObj,
  successHandlerObj,
  setSuccessHandlerObj,
  isLoading,
  setIsLoading,
}) {
  return (
    <div className={`dashboard-setup-container voucher-setup-container `}>
      <Top />

      {/*Dashboard Setup Section */}
      <section
        className={`dashboard-setup-form-section ${
          pageContent === "setup-done" ? "none" : ""
        }`}
      >
        <VoucherSetupLayout
          pageContent={pageContent}
          setPageContent={setPageContent}
          pageType={pageType}
          currentVoucherObj={currentVoucherObj}
          errorHandlerObj={errorHandlerObj}
          setErrorHandlerObj={setErrorHandlerObj}
          successHandlerObj={successHandlerObj}
          setSuccessHandlerObj={setSuccessHandlerObj}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </section>
    </div>
  );
}

export default VoucherSetup;
