import React from "react";

function ProductSetupNav({
  pageType,
  pageContent,
  variantsExists,
  setPageContent,
  setIsBrandsPopupModalOpened,
  setIsDeleteStockModalOpened,
}) {
  // Functions, States and Variables
  // Functions
  // handle Open Delete Stock Modal
  const handleOpenDeleteStockModal = function () {
    setIsDeleteStockModalOpened(true);
  };

  return (
    <div
      className="dashboard-setup-layout--left-section dashboard-setup-layout-navigation-container"
      onClick={() => setIsBrandsPopupModalOpened(false)}
    >
      {/* Account Details Setup Nav Link */}
      <div className="form-navigation-link">
        <button
          className={`${
            pageContent === "product-info" && "active-form-nav-link"
          }`}
          onClick={() => setPageContent("product-info")}
        >
          Product Info
        </button>
      </div>

      {/* Account Details Setup Nav Link */}
      <div className="form-navigation-link">
        <button
          className={`${pageContent === "variants" && "active-form-nav-link"}`}
          onClick={() => {
            if (variantsExists) {
              setPageContent("variants");
            }
          }}
        >
          Variants
        </button>
      </div>

      {/* delete stock btn wrapper */}
      {pageType === "edit" && (
        <div className="delete-stock-btn-wrapper">
          <button onClick={() => handleOpenDeleteStockModal()}>
            Delete Product
          </button>
        </div>
      )}
    </div>
  );
}

export default ProductSetupNav;
