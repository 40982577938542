import React, { useEffect, useState } from "react";
import "./AccountVerification.css";
import { useNavigate, useParams } from "react-router-dom";
import smileEmojiIcon from "../../image/smile-emoji.png";
import ErrorHandler from "../error/ErrorHandler";
import SuccessHandler from "../success/SuccessHandler";
import AuthNavbar from "../auth-navbar/AuthNavbar";
import { useDispatch } from "react-redux";
import { verifyCurrentUser } from "../../redux/actions/authActions";

function AccountVerification() {
  const { token, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Functions, States and Variables
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (token && id) {
      const data = {
        token: token,
        merchantId: id,
      };
      //  Call the verify merchant API with the token and redirect user to signin
      dispatch(
        verifyCurrentUser(
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          navigate
        )
      );
    }
  }, [token]);

  return (
    <div className="account-verification-container">
      <AuthNavbar />

      <div className="verification-message-wrapper">
        {/* Error handler component */}
        <ErrorHandler errorHandlerObj={errorHandlerObj} />

        {/* Success handler component */}
        <SuccessHandler successHandlerObj={successHandlerObj} />
      </div>

      <section className="account-verification-wrapper">
        {/* Container Header  */}
        <div className="dashboard-awaiting-data-section-header">
          {/* <div className="congratulations-image-wrapper">
            <img src={CongratulationsIcon} alt="" />
          </div> */}
          <div className="dashboard-awaiting-data-section-header-title">
            Just give us one sec <img src={smileEmojiIcon} alt="" />
          </div>

          <div className="dashboard-awaiting-data-section-header-sub-title">
            Your Account is currently being verified!
          </div>
        </div>
      </section>
    </div>
  );
}

export default AccountVerification;
