import React, { useState } from "react";
import useDisplayMessage from "../../../utils/useDisplayMessage";
import StockSetup from "../stock-setup/StockSetup";

function StockCreate() {
  // Funtion, State and Variables
  // States
  const [currentStockObj, setCurrentStockObj] = useState([]);
  const [pageContent, setPageContent] = useState("product-info");
  const [isLoading, setIsLoading] = useState(false);

  // Hooks
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  return (
    <>
      {currentStockObj !== null && (
        <StockSetup
          pageType={"create"}
          currentStockObj={currentStockObj}
          pageContent={pageContent}
          setPageContent={setPageContent}
          errorHandlerObj={errorHandlerObj}
          setErrorHandlerObj={setErrorHandlerObj}
          successHandlerObj={successHandlerObj}
          setSuccessHandlerObj={setSuccessHandlerObj}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
}

export default StockCreate;
