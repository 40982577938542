import { GET_ALL_CATEGORIES, GET_A_CATEGORY, } from "../constants";

const initialState = {
    categories: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            };
        case GET_A_CATEGORY:
            return {
                ...state,
                category: action.payload
            };
        default:
            return {
                ...state
            };
    }
}