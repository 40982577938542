import axios from "axios";
import { GET_ALL_WITHDRAWALS, GET_ERRORS } from "../constants";
import isEmpty from "../../validation/isEmpty";
import { api } from "../../api/config";


const url = `${api}/api/v1/withdrawal`

const token = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;
// Get all Withdrawals for merchant
export const getAllWithdrawalsForMerchant = (setIsLoading, setErrorHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.get(`${url}/merchant`)
        .then((res) => {
            dispatch({
                type: GET_ALL_WITHDRAWALS,
                payload: res.data,
            });
            // console.log(res.data)
            setIsLoading(false)
        })
        .catch((err) => {
            if (!isEmpty(err) && !isEmpty((err.response))) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                })
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: "Something Went Wrong. Please Check your Connection and try again"
                })
            }

            setIsLoading(false)
            // Set the error handler state
            setErrorHandlerObj({
                hasError: true,
                message:
                    err?.response?.data ||
                    "Something Went Wrong. Please Check your Connection and try again",
            });

        })
}


// Create a withdrawal for merchant
export const createAWithdrawalForMerchant = (data, setIsLoading, setErrorHandlerObj, setSuccessHandlerObj,
    setIsWithdrawalSuccessful) => (dispatch) => {
        setIsLoading(true)
        axios.post(`${url}`, data)
            .then((res) => {
                // console.log(res.data)
                setIsLoading(false)

                setSuccessHandlerObj({ isSuccess: true, message: "Withdrawal successfull!" })
                setIsWithdrawalSuccessful(true)
            })
            .catch((err) => {
                if (!isEmpty(err) && !isEmpty((err.response))) {
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response
                    })
                } else {
                    dispatch({
                        type: GET_ERRORS,
                        payload: "Something Went Wrong. Please Check your Connection and try again"
                    })
                }

                setIsLoading(false)
                // Set the error handler state
                setErrorHandlerObj({
                    hasError: true,
                    message:
                        err?.response?.data ||
                        "Something Went Wrong. Please Check your Connection and try again",
                });

            })
    }
