import React from "react";
import "./DashboardAwaiting.css";
import whiteStarIcon from "../../../image/white-star.svg";
import arrowRight from "../../../image/arrow-long-right.svg";
import smileEmoji from "../../../image/smile-emoji.png";
import financialLiteracyIcon from "../../../image/financial-literacy-icon.jpg";
import { useNavigate } from "react-router-dom";

function DashboardAwaiting() {
  // Functions, States and Variables
  const navigate = useNavigate();
  return (
    <section className="dashboard-awaiting-data-section">
      {/* Container Header  */}
      <div className="dashboard-awaiting-data-section-header">
        <div className="dashboard-awaiting-data-section-header-hero-img-wrapper">
          <img src={financialLiteracyIcon} alt="" />
        </div>

        <div className="dashboard-awaiting-data-section-header-title">
          Sit back and keep on making sales
        </div>

        <div className="dashboard-awaiting-data-section-header-sub-title">
          We are preparing your data 🚀
        </div>
      </div>

      {/* Dashboard Setup Onboarding Section Main Container */}
      <div className="dashboard-awaiting-data-section-main-container">
        {/* Go to Wrapper Container */}
        <div className="go-to-setup-links-wrapper-container">
          {/* Stock setup link wrapper */}
          <div className="go-to-setup-link-wrapper go-to-stock-setup-wrapper">
            <button onClick={() => navigate("/merchant/stock/create")}>
              Set up your Stock
              {/* Arrow Image Wrapper */}
              <div className="arrow-img-wrapper">
                <img src={arrowRight} alt="" />
              </div>
            </button>

            {/* Star Image Icon */}
            <div className="star-image-wrapper">
              <img src={whiteStarIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DashboardAwaiting;
