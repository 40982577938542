import React from "react";
import "./ProductsNav.css";
import { productPages } from "../../../utils/allProductPages";

function ProductsNav({ pageContent, setPageContent }) {
  // Functions, States and Variables
  return (
    <section className="products-nav-section">
      {/* Products filter section */}
      <div className="products-filter-container">
        {productPages?.map((page, index) => (
          <div
            key={index + 1}
            className={`products-filter-option ${
              pageContent === page.key ? "active-filter-option" : ""
            }`}
            onClick={() => {
              setPageContent(page.key);
            }}
          >
            {page.value}
          </div>
        ))}
      </div>
    </section>
  );
}

export default ProductsNav;
