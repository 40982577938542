import { GET_USER_VERIFICATION_STATUS, } from "../constants";

const initialState = {
    status: {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USER_VERIFICATION_STATUS:
            return {
                ...state,
                status: action.payload
            };

        default:
            return {
                ...state
            };
    }
}