import React from "react";
import "./Modals.css";
import attentionIcon from "../../../../image/attention-icon.svg";
import { useDispatch } from "react-redux";
import { deleteMerchantStock } from "../../../../redux/actions/stockActions";
import { useNavigate } from "react-router-dom";

function DeleteStockModal({
  isDeleteStockModalOpened,
  setIsDeleteStockModalOpened,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  setIsLoading,
  isLoading,
  stock,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   handle Delete Stock
  const handleDeleteStock = function () {
    // Call the dispatch function to delete stock
    setIsDeleteStockModalOpened(false);
    dispatch(
      deleteMerchantStock(
        stock._id,
        setIsLoading,
        setErrorHandlerObj,
        setSuccessHandlerObj,
        navigate
      )
    );
  };
  return (
    <div
      className={`confirm-withdrawal-modal-container delete-stock-modal-container ${
        isDeleteStockModalOpened ? "" : "none"
      }`}
    >
      <div className="confirm-withdrawal-modal-container--inner">
        <div className="attention-img-wrapper">
          <img src={attentionIcon} alt="" />
        </div>

        <div className="confirm-withdrawal-header-text">
          Kindly confirm this action
        </div>
        <div className="confirm-withdrawal-sub-text">
          Would you like to delete this product <span>{stock.name}</span> from
          your stock
        </div>

        <div className="modal-action-button-wrapper">
          {/* Cancel Modal Button */}
          <div className="cancel-modal-button-wrapper">
            <button onClick={() => setIsDeleteStockModalOpened(false)}>
              Cancel
            </button>
          </div>
          {/* Conffirm Action Button */}
          <div className="confirm-modal-action-button">
            <button onClick={() => handleDeleteStock()}>Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteStockModal;
