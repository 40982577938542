import React, { useState } from "react";
import "./StockSetup.css";
import StockSetupLayout from "./stock-setup-layout/StockSetupLayout";
import StockAwaiting from "../stock-awaiting/StockAwaiting";
import Top from "../../app-top/Top";
import DeleteStockModal from "../stock-edit/modal/DeleteStockModal";
import Main from "../../main-container/Main";
function StockSetup({
  pageType,
  currentStockObj,
  pageContent,
  setPageContent,
  errorHandlerObj,
  setErrorHandlerObj,
  successHandlerObj,
  setSuccessHandlerObj,
  isLoading,
  setIsLoading,
}) {
  // Funtion, State and Variables
  const [isDeleteStockModalOpened, setIsDeleteStockModalOpened] =
    useState(false);

  // Function
  return (
    <div
      className={`app-page-container stock-setup-container ${
        isDeleteStockModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />
      {/* <Navbar /> */}

      <div
        onClick={() => setIsDeleteStockModalOpened(false)}
        className={`gray-overlay ${isDeleteStockModalOpened ? "" : "none"}`}
      ></div>

      {/* Confirm Withrawal Modal */}
      <DeleteStockModal
        isDeleteStockModalOpened={isDeleteStockModalOpened}
        setIsDeleteStockModalOpened={setIsDeleteStockModalOpened}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        stock={currentStockObj}
      />

      <Main>
        <section className="app-container-without-sidebar">
          {/*Dashboard Setup Section */}
          <section
            className={`dashboard-setup-form-section ${
              pageContent === "setup-done" ? "none" : ""
            }`}
          >
            <StockSetupLayout
              pageContent={pageContent}
              setPageContent={setPageContent}
              pageType={pageType}
              currentStockObj={currentStockObj}
              isDeleteStockModalOpened={isDeleteStockModalOpened}
              setIsDeleteStockModalOpened={setIsDeleteStockModalOpened}
              errorHandlerObj={errorHandlerObj}
              setErrorHandlerObj={setErrorHandlerObj}
              successHandlerObj={successHandlerObj}
              setSuccessHandlerObj={setSuccessHandlerObj}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </section>

          {/* Setup Done / Awaiting Dashboard Data Section */}
          <section
            className={`awaiting-dashboard-data-section-container ${
              pageContent !== "setup-done" ? "none" : ""
            }`}
          >
            <StockAwaiting setPageContent={setPageContent} />
          </section>
        </section>
      </Main>
    </div>
  );
}

export default StockSetup;
