
export const deliveryLocations = [
    { key: "lagosAgege", address: "Agege", value: "Lagos - Agege", },
    { key: "lagosAjeromiIfelodun", address: "Ajeromi-Ifelodun", value: "Lagos - Ajeromi-Ifelodun", },
    { key: "lagosAlimosho", address: "Alimosho", value: "Lagos - Alimosho", },
    { key: "lagosAmuwoOdofin", address: "Amuwo-Odofin", value: "Lagos - Amuwo-Odofin", },
    { key: "lagosBadagry", address: "Badagry", value: "Lagos - Badagry", },
    { key: "lagosApapa", address: "Apapa", value: "Lagos - Apapa", },
    { key: "lagosEpe", address: "Epe", value: "Lagos - Epe", },
    { key: "lagosEtiOsa", address: "Eti Osa", value: "Lagos - Eti Osa", },
    { key: "lagosIbejuLekki", address: "Ibeju-Lekki", value: "Lagos - Ibeju-Lekki", },
    { key: "lagosIfakoIjaiye", address: "Ifako-Ijaiye", value: "Lagos - Ifako-Ijaiye", },
    { key: "lagosIkeja", address: "Ikeja", value: "Lagos - Ikeja", },
    { key: "lagosIkorodu", address: "Ikorodu", value: "Lagos - Ikorodu", },
    { key: "lagosKosofe", address: "Kosofe", value: "Lagos - Kosofe", },
    { key: "lagosLagosIsland", address: "Lagos Island", value: "Lagos - Lagos Island", },
    { key: "lagosMushin", address: "Mushin", value: "Lagos - Mushin", },
    { key: "lagosLagosMainland", address: "Lagos Mainland", value: "Lagos - Lagos Mainland", },
    { key: "lagosOjo", address: "Ojo", value: "Lagos - Ojo", },
    { key: "lagosOshodiIsolo", address: "Oshodi-Isolo", value: "Lagos - Oshodi-Isolo", },
    { key: "lagosShomolu", address: "Shomolu", value: "Lagos - Shomolu", },
    { key: "lagosSurulereLagosState", address: "Surulere", value: "Lagos - Surulere Lagos State", },
    { key: "northCentral", address: "North Central", value: "North Central", },
    { key: "northEast", address: "North East", value: "North East", },
    { key: "northWest", address: "North West", value: "North West", },
    { key: "southEast", address: "South East", value: "South East" },
    { key: "southSouth", address: "South South", value: "South South", },
    { key: "southWest", address: "South West", value: "South West", },
]