import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./AccountSetup.css";
import whiteStarIcon from "../../image/white-star.svg";
import arrowRight from "../../image/arrow-long-right.svg";
import smileEmoji from "../../image/smile-emoji.png";
import SetupLayout from "./setup-layout/SetupLayout";
import DashboardAwaiting from "../dashboard-comps/dashboard-awaiting/DashboardAwaiting";
import Top from "../app-top/Top";
import Navbar from "../app-navbar/Navbar";
import { getCurrentUser } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import ErrorHandler from "../error/ErrorHandler";
import isEmpty from "../../validation/isEmpty";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import SubstituteLoadingSpinner from "../substitute-loading-spinner/SubstituteLoadingSpinner";
import UseFlatFeeModal from "./modals/UseFlatFeeModal";

function AccountSetup({ pageType, pageStateObj }) {
  // Since this comp is reusable, it takes a pageType prop specifying the page u the comp
  // Funtion, State and Variables
  const userInfo = useSelector((state) => state.users.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // States
  const [pageContent, setPageContent] = useState("main");
  const [isUseFlatFeeModalOpened, setIsUseFlatFeeModalOpened] = useState(false);
  const [isUseFlatFeeSubmitted, setIsUseFlatFeeSubmitted] = useState(false);

  const [flatFeePrices, setFlatFeePrices] = useState({
    smallItemPrice: "0",
    mediumItemPrice: "0",
    largeItemPrice: "0",
  });

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // is Setup Stock Stock button Clicked
  const [isSetupStockButtonClicked, setIsSetupStockButtonClicked] =
    useState(false);
  // Function
  // USEEFFECT

  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  useEffect(() => {
    //   If page setup is settings setup, set page content to account details straight
    if (pageType !== "dash-setup") {
      setPageContent("account-details-setup");
    }
  }, []);

  useEffect(() => {
    dispatch(getCurrentUser(setIsLoading, setErrorHandlerObj));
  }, []);

  // Handle Navigate to setup stock
  const handleNavigateToSetupStock = function () {
    setIsSetupStockButtonClicked(true);
    setErrorHandlerObj({ hasError: false, message: "" });

    // Check if userInfo is not empty(i.e is gotten), then check and navigate / throw error respectively
    if (!isEmpty(userInfo)) {
      if (
        userInfo?.address &&
        userInfo?.accountNumber &&
        userInfo?.bvn &&
        !isEmpty(userInfo?.deliverycost)
      ) {
        // If user is approved
        if (userInfo.isApproved) {
          navigate("/merchant/stock/create");
        } else {
          navigate("/merchant/awaiting-approval");
        }
      } else {
        setErrorHandlerObj({
          hasError: true,
          message: "Kindly complete acount setup before you can proceed!",
        });
      }
    }
  };

  useEffect(() => {
    // If Button is clicked before userInfo is gotten, trigger call on change of user info
    if (isSetupStockButtonClicked) {
      if (
        userInfo?.profilePicture &&
        userInfo?.address &&
        userInfo?.cacDocument &&
        userInfo?.accountNumber &&
        !isEmpty(userInfo?.deliverycost)
      ) {
        // If user is approved
        if (userInfo.isApproved) {
          navigate("/merchant/stock/create");
        } else {
          navigate("/merchant/awaiting-approval");
        }
      } else {
        setErrorHandlerObj({
          hasError: true,
          message: "Kindly complete acount setup before you can proceed!",
        });
      }
    }
  }, [userInfo]);

  useEffect(() => {
    setErrorHandlerObj({ hasError: false, message: "" });
  }, [pageContent]);

  useEffect(() => {
    if (isUseFlatFeeModalOpened) setIsUseFlatFeeSubmitted(false);
  }, [isUseFlatFeeModalOpened]);
  return (
    <div
      className={`dashboard-setup-container ${
        isUseFlatFeeModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />
      {/* <Navbar /> */}

      {/* Gray Overlay */}
      <div
        onClick={() => {
          setIsUseFlatFeeModalOpened(false);
        }}
        className={`gray-overlay ${isUseFlatFeeModalOpened ? "" : "none"}`}
      ></div>

      {/* Create New Group Modal */}
      <UseFlatFeeModal
        isUseFlatFeeModalOpened={isUseFlatFeeModalOpened}
        setIsUseFlatFeeModalOpened={setIsUseFlatFeeModalOpened}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
        flatFeePrices={flatFeePrices}
        setFlatFeePrices={setFlatFeePrices}
        setIsUseFlatFeeSubmitted={setIsUseFlatFeeSubmitted}
      />
      {pageType === "dash-setup" && (
        <div
          className={`dashboard-setup-container--inner ${
            pageContent !== "main" ? "none" : ""
          }`}
        >
          {/* Error handler component */}
          <ErrorHandler errorHandlerObj={errorHandlerObj} />
          {/* Dashboard Setup Onboarding Section */}
          <section className="dashboard-setup-onboarding-section">
            {/* Container Header  */}
            <div className="dashboard-setup-onboarding-section-header">
              <div className="dashboard-setup-container-onboarding-section-title">
                Your account is almost done{" "}
                <>
                  <img src={smileEmoji} alt="" />
                </>
              </div>
            </div>

            {/* Dashboard Setup Onboarding Section Main Container */}
            <div className="dashboard-setup-onboarding-section-main-container">
              {/* Go to Wrapper Container */}
              <div className="go-to-setup-links-wrapper-container">
                {/* Shop Details setup link wrapper */}
                <div className="go-to-setup-link-wrapper go-to-shop-details-setup-wrapper">
                  <button onClick={() => setPageContent("shop-details-setup")}>
                    Set up your Shop Details
                    {/* Arrow Image Wrapper */}
                    <div className="arrow-img-wrapper">
                      <img src={arrowRight} alt="" />
                    </div>
                  </button>

                  {/* Star Image Icon */}
                  <div className="star-image-wrapper">
                    <img src={whiteStarIcon} alt="" />
                  </div>
                </div>

                {/* Bank Account setup link wrapper */}
                <div className="go-to-setup-link-wrapper go-to-bank-account-setup-wrapper">
                  <button onClick={() => setPageContent("bank-account-setup")}>
                    Set up your Bank Account
                    {/* Arrow Image Wrapper */}
                    <div className="arrow-img-wrapper">
                      <img src={arrowRight} alt="" />
                    </div>
                  </button>

                  {/* Star Image Icon */}
                  <div className="star-image-wrapper">
                    <img src={whiteStarIcon} alt="" />
                  </div>
                </div>

                {/* Stock setup link wrapper */}
                <div className="go-to-setup-link-wrapper go-to-stock-setup-wrapper">
                  <button
                    onClick={() => {
                      // navigate("/merchant/stock/create");
                      // TODO Check if KYC is complete then check approval before Navigating
                      handleNavigateToSetupStock();
                    }}
                  >
                    Set up your Stock
                    {/* Arrow Image Wrapper */}
                    {isSetupStockButtonClicked && isLoading && (
                      <SubstituteLoadingSpinner />
                    )}
                    <div className="arrow-img-wrapper">
                      <img src={arrowRight} alt="" />
                    </div>
                  </button>

                  {/* Star Image Icon */}
                  <div className="star-image-wrapper">
                    <img src={whiteStarIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      {/*Dashboard Setup Section */}
      <section
        className={`dashboard-setup-form-section ${
          pageContent === "main" || pageContent === "setup-done" ? "none" : ""
        }`}
      >
        <SetupLayout
          pageContent={pageContent}
          setPageContent={setPageContent}
          pageType={pageType}
          pageStateObj={pageStateObj}
          setIsUseFlatFeeModalOpened={setIsUseFlatFeeModalOpened}
          isUseFlatFeeSubmitted={isUseFlatFeeSubmitted}
          flatFeePrices={flatFeePrices}
        />
      </section>
      {/* Setup Done / Awaiting Dashboard Data Section */}
      {pageType === "dash-setup" && (
        <section
          className={`awaiting-dashboard-data-section-container ${
            pageContent !== "setup-done" ? "none" : ""
          }`}
        >
          <DashboardAwaiting />
        </section>
      )}
    </div>
  );
}

export default AccountSetup;
