import React, { useState } from "react";
import { Table } from "react-bootstrap";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import isEmpty from "../../../../validation/isEmpty";
import SubstituteLoadingSpinner from "../../../../components/substitute-loading-spinner/SubstituteLoadingSpinner";
import { useDispatch } from "react-redux";
import { sendOTPCodeForVoucher } from "../../../../redux/actions/vouchersActions";
import FEPagination from "../../../../components/pagination/fe-pagination/FEPagination";

function VouchersToBeRedeemedTable({
  currentPosts,
  allVouchersToBeRedeemed,
  currentPage,
  setCurrentPage,
  isLoading,
  rowsPerPage,
  isVoucherSearched,
  setIsRedeemVoucherModalOpened,
  setSelectedVoucherCodeForRedemption,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}) {
  // Functions, States and variables
  const dispatch = useDispatch();
  // States
  const [isSecondaryLoading, setIsSecondaryLoading] = useState(false);
  // Functions
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSendResendVoucherOTPCode = function (voucherCode) {
    const data = {
      voucherCode,
    };
    console.log(data);
    //  Call the API to send or resend voucher OTP code
    dispatch(
      sendOTPCodeForVoucher(
        data,
        setIsSecondaryLoading,
        setErrorHandlerObj,
        setSuccessHandlerObj
      )
    );
  };

  const handleOpenRedeemVoucherModal = function (voucherCode) {
    setIsRedeemVoucherModalOpened(true);
    setSelectedVoucherCodeForRedemption(voucherCode);
  };

  return (
    <>
      {/* All Voucher Stock Table Container */}
      <div className="primary-table-container">
        {
          <div className="table-top-loader-wrapper">
            {isSecondaryLoading && <SubstituteLoadingSpinner />}
          </div>
        }
        <Table responsive="lg" className="primary-table">
          <thead
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              {[
                "USER NAME",
                "USER EMAIL",
                "SOURCE",
                "AMOUNT",
                "PRODUCT CODE",
                "CURRENCY CODE",
                "",
                "",
              ].map((COLUMN, index) => (
                <th key={index + 1}>{COLUMN}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentPosts?.map((voucher, index) => (
              <tr key={index + 1}>
                {/* User Name Column */}
                <td className="td-primary-table">
                  <div className="td-primary-table--wrapper">
                    {`${voucher.user.firstName} ${voucher.user.lastName}`}
                  </div>
                </td>
                {/* User Email Column */}
                <td className="td-primary-table">
                  <div className="td-primary-table--wrapper td-email--wrapper">
                    {voucher.user.email}
                  </div>
                </td>
                {/* Source Column */}
                <td className="td-primary-table">
                  <div className="td-primary-table--wrapper">
                    {voucher.source}
                  </div>
                </td>
                {/* Amount Column */}
                <td className="td-primary-table">
                  <div className="td-primary-table--wrapper">
                    {voucher.amount?.toLocaleString()}
                  </div>
                </td>
                {/* Product Code Column */}
                <td className="td-primary-table">
                  <div className="td-primary-table--wrapper">
                    {voucher.productCode}
                  </div>
                </td>
                {/* Currency Code Column */}
                <td className="td-primary-table">
                  <div className="td-primary-table--wrapper">
                    {voucher.currencyCode}
                  </div>
                </td>
                {/* Send/Resend OTP Code*/}
                <td className="td-primary-table">
                  <div className="td-send-voucher-otp-button-wrapper">
                    <button
                      onClick={() =>
                        handleSendResendVoucherOTPCode(voucher.voucherCode)
                      }
                    >
                      Send/Resend OTP Code
                    </button>
                  </div>
                </td>
                {/* Redeem Voucher Code*/}
                <td className="td-primary-table">
                  <div className="td-redeem-voucher--button-wrapper">
                    <button
                      onClick={() =>
                        handleOpenRedeemVoucherModal(voucher.voucherCode)
                      }
                    >
                      Redeem Voucher
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="stock-loader-wrapper">
          {/* Loading Spinner Wrapper */}
          {isLoading && <LoadingSpinner />}
        </div>
      </div>

      {!isVoucherSearched &&
        allVouchersToBeRedeemed &&
        !isEmpty(allVouchersToBeRedeemed) && (
          <FEPagination
            paginate={paginate}
            rowsPerPage={rowsPerPage}
            totalPosts={allVouchersToBeRedeemed.length}
            currentPage={currentPage}
          />
        )}
    </>
  );
}

export default VouchersToBeRedeemedTable;
