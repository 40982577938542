import { GET_ALL_STOCK, GET_A_STOCK, } from "../constants";

const initialState = {
    stocks: [],
    stock: {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_STOCK:
            return {
                ...state,
                stocks: action.payload
            };
        case GET_A_STOCK:
            return {
                ...state,
                stock: action.payload
            };
        default:
            return {
                ...state
            };
    }
}