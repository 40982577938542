import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useInactivityLogout from "../../hooks/useInactivityLogout";
import { logoutUser } from "../../redux/actions/authActions";

function Main({ children }) {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Functions
  // handle Log Out User
  const handleLogOutUser = function () {
    dispatch(logoutUser(navigate));
  };

  useInactivityLogout({
    inactivityExpireTime: 120,
    onExpired: handleLogOutUser,
  });

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    // Check if User exists || is logged in
    if (!token) {
      window.location.href = "/";
    }
  }, []);
  return <div className="app--main-section-wrapper">{children}</div>;
}

export default Main;
