import axios from "axios";
import { GET_MERCHANT_WALLET, GET_ERRORS } from "../constants";
import isEmpty from "../../validation/isEmpty";
import { api } from "../../api/config";


const url = `${api}/api/v1/merchantWallet`

const token = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;
// Get Merchant Wallet
export const getMerchantWallet = (setIsLoading, setErrorHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.get(`${url}/merchant`)
        .then((res) => {
            dispatch({
                type: GET_MERCHANT_WALLET,
                payload: res.data,
            });
            // console.log(res.data)
            setIsLoading(false)
        })
        .catch((err) => {
            if (!isEmpty(err) && !isEmpty((err.response))) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                })
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: "Something Went Wrong. Please Check your Connection and try again"
                })
            }

            setIsLoading(false)
            // Set the error handler state
            setErrorHandlerObj({
                hasError: true,
                message:
                    err?.response?.data ||
                    "Something Went Wrong. Please Check your Connection and try again",
            });

        })
}