import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import angleLeftIcon from "../../../../image/angle-left.svg";
import ErrorHandler from "../../../error/ErrorHandler";
import SuccessHandler from "../../../success/SuccessHandler";
import ProductInfoForm from "./comps/product-info-form/ProductInfoForm";
import ProductVariantsForm from "./comps/product-variants-form/ProductVariantsForm";
import ProductSetupNav from "./comps/setup-nav/ProductSetupNav";

function StockSetupLayout({
  pageContent,
  setPageContent,
  pageType,
  currentStockObj,
  isDeleteStockModalOpened,
  setIsDeleteStockModalOpened,
  errorHandlerObj,
  setErrorHandlerObj,
  successHandlerObj,
  setSuccessHandlerObj,
  isLoading,
  setIsLoading,
}) {
  // Functions, States and Variables
  const navigate = useNavigate();
  // States
  const [productImgArr, setProductImgArr] = useState([
    {
      _id: Math.floor(Math.random() * 1000).toString(),
      imgUrl: "",
    },
  ]);
  const [productFormInputState, setProductFormInputState] = useState({
    productImg: "",
    productName: "",
    productBrand: "",
    productQuantity: "",
    productDescription: "",
    category: "",
    price: "",
    productSizeCategory: "",
  });
  // Brands Popup Modal
  const [isBrandsPopupModalOpened, setIsBrandsPopupModalOpened] =
    useState(false);
  // State to keep track of which upload elements to determine where the loading spinner will ONLY show
  const [currentUploadId, setCurrentUploadId] = useState("");
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);
  const [variantsExists, setVariantsExists] = useState(false);

  // Useeffects
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pageContent]);

  return (
    <div className={`dashboard-setup-layout-container`}>
      {/* Dashboard Setup Top */}
      <div
        className="dashboard-setup-top-container"
        onClick={() => setIsBrandsPopupModalOpened(false)}
      >
        {/* Left Wrapper */}
        <div className="dashboard-setup-top-container--left-wrapper">
          {/* Back button Wrapper */}
          <div className="back-button-wrapper">
            <button onClick={() => navigate(-1)}>
              <img src={angleLeftIcon} alt="" />
            </button>
          </div>

          {/* Setup Top Title */}
          <div className="dashboard-setup-top-title">
            {pageType === "create" ? "Add Stock" : "Edit Stock"}
          </div>
        </div>
      </div>

      <div className="app--actions-handler-wrapper">
        {/* Error handler component */}
        <ErrorHandler errorHandlerObj={errorHandlerObj} />

        {/* Success handler component */}
        <SuccessHandler successHandlerObj={successHandlerObj} />
      </div>

      {/* Setup Layout main Container */}
      <div className="dashboard-setup-layout-main-container">
        {/* Dasboard setup Left Section / Dashboard setup navigation container */}
        <ProductSetupNav
          pageType={pageType}
          pageContent={pageContent}
          variantsExists={variantsExists}
          setPageContent={setPageContent}
          setIsBrandsPopupModalOpened={setIsBrandsPopupModalOpened}
          setIsDeleteStockModalOpened={setIsDeleteStockModalOpened}
        />

        {/* STOCK Setup Right Section / STOCK setup form container */}
        <div className="dashboard-setup-layout--right-section stock-setup-layout-form-container">
          {/* Store Product Info Form Container */}

          <ProductInfoForm
            pageContent={pageContent}
            pageType={pageType}
            currentStockObj={currentStockObj}
            currentUploadId={currentUploadId}
            productImgArr={productImgArr}
            productFormInputState={productFormInputState}
            isFileUploadLoading={isFileUploadLoading}
            variantsExists={variantsExists}
            setVariantsExists={setVariantsExists}
            setProductFormInputState={setProductFormInputState}
            setIsFileUploadLoading={setIsFileUploadLoading}
            setCurrentUploadId={setCurrentUploadId}
            setPageContent={setPageContent}
            setProductImgArr={setProductImgArr}
            isBrandsPopupModalOpened={isBrandsPopupModalOpened}
            setIsBrandsPopupModalOpened={setIsBrandsPopupModalOpened}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setErrorHandlerObj={setErrorHandlerObj}
            setSuccessHandlerOb={setSuccessHandlerObj}
          />

          {/* Store Product Variants Form Container */}
          <ProductVariantsForm
            pageContent={pageContent}
            pageType={pageType}
            currentStockObj={currentStockObj}
            currentUploadId={currentUploadId}
            productImgArr={productImgArr}
            productFormInputState={productFormInputState}
            isFileUploadLoading={isFileUploadLoading}
            isLoading={isLoading}
            setIsBrandsPopupModalOpened={setIsBrandsPopupModalOpened}
            setCurrentUploadId={setCurrentUploadId}
            setPageContent={setPageContent}
            setIsFileUploadLoading={setIsFileUploadLoading}
            setVariantsExists={setVariantsExists}
            setIsLoading={setIsLoading}
            setErrorHandlerObj={setErrorHandlerObj}
            setSuccessHandlerObj={setSuccessHandlerObj}
          />
        </div>
      </div>
    </div>
  );
}

export default StockSetupLayout;
