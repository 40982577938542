import React, { useState } from "react";
import ErrorHandler from "../../../error/ErrorHandler";
import SuccessHandler from "../../../success/SuccessHandler";
import ProductSetupNav from "./comps/setup-nav/ProductSetupNav";
import angleLeftIcon from "../../../../image/angle-left.svg";
import { useNavigate } from "react-router-dom";
import VoucherInfoForm from "./comps/voucher-info-form/VoucherInfoForm";

function VoucherSetupLayout({
  pageContent,
  setPageContent,
  pageType,
  currentVoucherObj,
  errorHandlerObj,
  setErrorHandlerObj,
  successHandlerObj,
  setSuccessHandlerObj,
  isLoading,
  setIsLoading,
}) {
  // Functions, Status and Variables
  const navigate = useNavigate();

  //   States
  const [productFormInputState, setProductFormInputState] = useState({
    voucherDescription: "",
    voucherDiscount: "",
    minAmount: "",
    maxAmount: "",
    expiryDate: "",
    categoryId: "",
  });

  return (
    <div className={`dashboard-setup-layout-container`}>
      {/* Dashboard Setup Top */}
      <div className="dashboard-setup-top-container">
        {/* Left Wrapper */}
        <div className="dashboard-setup-top-container--left-wrapper">
          {/* Back button Wrapper */}
          <div className="back-button-wrapper">
            <button onClick={() => navigate(-1)}>
              <img src={angleLeftIcon} alt="" />
            </button>
          </div>

          {/* Setup Top Title */}
          <div className="dashboard-setup-top-title">
            {pageType === "create" ? "Add Voucher" : "Edit Voucher"}
          </div>
        </div>
      </div>

      <div className="app--actions-handler-wrapper">
        {/* Error handler component */}
        <ErrorHandler errorHandlerObj={errorHandlerObj} />

        {/* Success handler component */}
        <SuccessHandler successHandlerObj={successHandlerObj} />
      </div>

      {/* Setup Layout main Container */}
      <div className="dashboard-setup-layout-main-container">
        {/* Dasboard setup Left Section / Dashboard setup navigation container */}
        <ProductSetupNav
          pageType={pageType}
          pageContent={pageContent}
          setPageContent={setPageContent}
        />

        {/* STOCK Setup Right Section / STOCK setup form container */}
        <div className="dashboard-setup-layout--right-section">
          <VoucherInfoForm
            pageContent={pageContent}
            pageType={pageType}
            currentVoucherObj={currentVoucherObj}
            productFormInputState={productFormInputState}
            setProductFormInputState={setProductFormInputState}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setErrorHandlerObj={setErrorHandlerObj}
          />
        </div>
      </div>
    </div>
  );
}

export default VoucherSetupLayout;
