import React from "react";
import "./WalletHistory.css";
import Top from "../../components/app-top/Top";
import Sidebar from "../../components/app-sidebar/Sidebar";
import Main from "../../components/main-container/Main";
import ErrorHandler from "../../components/error/ErrorHandler";
import useDisplayMessage from "../../utils/useDisplayMessage";
import WalletHistoryTable from "./wallet-history-table/WalletHistoryTable";

function WalletHistory() {
  // Functions, States and Variables
  // Hooks
  const { errorHandlerObj, setErrorHandlerObj } = useDisplayMessage();
  return (
    <div className={`app-page-container`}>
      <Top />
      <Sidebar activeName={"Wallet History"} />

      <Main>
        <div className="app-page-container--inner">
          <div className="app--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />
          </div>

          {/* app-container-main-section */}
          <section className="app-container-main-section">
            {/* Wallet History Table */}
            <WalletHistoryTable setErrorHandlerObj={setErrorHandlerObj} />
          </section>
        </div>
      </Main>
    </div>
  );
}

export default WalletHistory;
