import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useDisplayMessage from "../../../utils/useDisplayMessage";
import isEmpty from "../../../validation/isEmpty";
import StockSetup from "../stock-setup/StockSetup";

function StockEdit() {
  // Funtion, State and Variables
  const navigate = useNavigate();
  const location = useLocation();
  // States
  const [currentStockObj, setCurrentStockObj] = useState([]);
  const [pageContent, setPageContent] = useState("product-info");
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  // Hooks
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    if (location.state) {
      const { stock, toVariants } = location.state;
      console.log(stock, toVariants, "ditt");

      toVariants ? setPageContent("variants") : setPageContent("product-info");
      setCurrentStockObj((prevCurrentStock) => {
        let newStockObj = stock;
        return newStockObj;
      });
    } else {
      navigate(-1);
    }
  }, []);

  return (
    <>
      {currentStockObj !== null && !isEmpty(currentStockObj) ? (
        <StockSetup
          pageType={"edit"}
          currentStockObj={currentStockObj}
          pageContent={pageContent}
          setPageContent={setPageContent}
          errorHandlerObj={errorHandlerObj}
          setErrorHandlerObj={setErrorHandlerObj}
          successHandlerObj={successHandlerObj}
          setSuccessHandlerObj={setSuccessHandlerObj}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default StockEdit;
