import React from "react";
import "./MerchantAgreement.css";
import AuthNavbar from "../../components/auth-navbar/AuthNavbar";

function MerchantAgreement() {
  return (
    <div className="merchant-agreement-container">
      <AuthNavbar />
      <div className="merchant-agreement-container-main-section">
        <div>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              This&nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              MERCHANT AGREEMENT
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;(“the Agreement”) is made this&nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ____
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;of&nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ______
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              2022.&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              BETWEEN&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              CREDLEY GLOBAL LIMITED,
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;a limited liability company incorporated and registered
              under Part A of the Companies and Allied Matters Act 2020 with RC
              No: 1904745 whose office is at 29/31, 1
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              <span style={{ fontSize: "0.6em", verticalAlign: "super" }}>
                st
              </span>
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;Floor, Awolowo Way, Ikeja, Lagos State, Nigeria (hereinafter
              referred to as
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;"Credley"
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              )
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              which expression shall where the context so admits, include its
              agents, assigns and successors–in-title) of the one part.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              AND
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              _____________________________,
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;a ___________________ company incorporated and registered
              under Part ___ of the Companies and Allied Matters Act 2020 with
              RC No: __________ whose office is at_________________________
              (hereinafter referred to as
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;the "Merchant"
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              )
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              which expression shall where the context so admits, include its
              agents, assigns and successors–in-title) of the other part.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Credley and the Merchant are hereinafter individually referred to
              as “
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              the Party
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ” and collectively referred to as “
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              the Parties
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ”.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "14pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              WHEREAS,
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.28",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Credley is a company engaged in providing pay day loan and Buy
                  Now-Pay Later services/solutions.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.28",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Company is a _______________
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.28",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Credley and Company are desirous of entering this Agreement
                  for the Merchant to display for sale (either on an outright
                  sale or on a Buy Now-Pay Later sale) its goods on the Credley
                  Platform/Application.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.27",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Parties are now entering into this Agreement for the
                  purpose of documenting all terms agreed upon between them.
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "14pt",
              marginBottom: "14pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              NOW, THEREFORE,
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;it is agreed that:
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  INTERPRETATION
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The following definitions and rules of interpretation apply in
              this Agreement.
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "14pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "underline",
                    WebkitTextDecorationSkip: "none",
                    textDecorationSkipInk: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Definitions:
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "12pt",
              marginBottom: "12pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Affiliate
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : includes, in relation to either party, each and any subsidiary
              or holding company of that party and each and any subsidiary of a
              holding company of that party OR either party or any business
              entity from time to time Controlling, Controlled by, or under
              common Control with, either party.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textIndent: "36pt",
              textAlign: "justify",
              marginTop: "12pt",
              marginBottom: "12pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Business
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : the business of Credley or any of its Affiliates.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "12pt",
              marginBottom: "12pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Business Day
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : &nbsp;a day other than a Saturday, Sunday or public holiday in
              Nigeria, when banks in Nigeria are open for business.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "12pt",
              marginBottom: "12pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Commencement Date
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : the date first above written.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "12pt",
              marginBottom: "12pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Confidential Information
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : information of commercial value, in whatever form or medium,
              disclosed by the party (or any of its Affiliates) to the other
              party (or any of its Affiliates) including commercial or technical
              know-how, technology, information pertaining to business
              operations and strategies, and information pertaining to
              customers, pricing and marketing and, for clarity, including (in
              the case of the Company's information) information relating to the
              Application, the Company Software or any of its constituent parts,
              the Source Code relating to the Company Software or any such
              parts.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "12pt",
              marginBottom: "12pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Credley application/platform
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : the software program developed by Credley through which it will
              provide consumer loans and Buy Now Pay Later solutions to the
              members of staff of the Company and to the general public.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "12pt",
              marginBottom: "12pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Credley Representative
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : a person duly authorised by Credley to act on its behalf for the
              purposes of this Agreement and identified to Company by written
              notice from the Customer.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Deliverables
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : &nbsp;all documents, products and materials developed by the
              Company or its agents, subcontractors and personnel as part of or
              in relation to the Services in any form, including without
              limitation computer programs, data, reports and specifications
              (including drafts), and the Key Deliverables set out in this
              Agreement.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "12pt",
              marginBottom: "12pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Data Protection Legislation
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : the Nigerian Data Protection Legislation and any other
              applicable international convention or treaty relating to personal
              data and all other legislation and regulatory requirements in
              force from time to time which apply to a party relating to the use
              of personal data (including, without limitation, the privacy of
              electronic communications) [and the guidance and codes of practice
              issued by the relevant data protection or supervisory authority
              and applicable to a party. &nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "12pt",
              marginBottom: "12pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Documentation
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : the operating manuals, user instruction manuals, technical
              literature and all other related materials in human-readable
              and/or machine-readable forms supplied by the Company.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "12pt",
              marginBottom: "12pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Good Industry Practice
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : the exercise of that degree of skill, care, prudence,
              efficiency, foresight and timeliness as would be expected from a
              leading company within the relevant industry or business sector.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Intellectual Property Rights
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : &nbsp;patents, rights to inventions, copyright and neighbouring
              and related rights, trade marks, business names and domain names,
              rights in get-up, goodwill and the right to sue for passing off,
              rights in designs, database rights, rights to use, and protect the
              confidentiality of, confidential information (including know-how)
              and all other intellectual property rights, in each case whether
              registered or unregistered and including all applications and
              rights to apply for and be granted, renewals or extensions of, and
              rights to claim priority from, such rights and all similar or
              equivalent rights or forms of protection which subsist or will
              subsist now or in the future in any part of the world.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Services
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : the consumer loan and Buy Now Pay Later services provided by the
              Credley via the Credley app.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Goods and Services or Goods/Services
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : the goods or services displayed for sale on the Credley
              platform/application by the Merchant. &nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tools
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : any tools and know-how developed and methods invented by the
              Company in the course of, or as a result of, carrying out the
              Work, whether or not developed or invented specifically or used
              exclusively to carry out the Work.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "12pt",
              marginBottom: "12pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Virus
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : any program which contains malicious code or infiltrates or
              damages a computer system without the owner's informed consent or
              is designed to do so or which is hostile, intrusive or annoying to
              the owner or user and has no legitimate purpose.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Work
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              : all the works, duties and obligations to be carried out by the
              Company under this agreement.
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "underline",
                    WebkitTextDecorationSkip: "none",
                    textDecorationSkipInk: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Interpretation&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              In this Agreement where the context admits:&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  any reference to a person includes any individual, company,
                  corporation, firm, partnership, joint venture, association,
                  organization or trust (in each case, whether or not having
                  separate legal personality) and references to any of the same
                  shall include a reference to the others;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.450000000000003pt",
                paddingLeft: "18.549999999999997pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  reference to any statute or statutory provisions include a
                  reference to those provisions as amended or re-enacted or as
                  their application is modified by other provisions from time to
                  time and any reference to a statutory provision shall include
                  any subordinate legislation made from time to time under that
                  provision;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={3}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  reference to this Agreement or to any other Agreement or
                  document referred to in this Agreement means this Agreement or
                  such other Agreement or document as amended, varied,
                  supplemented, modified or novated from time to time, and
                  include the schedules;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={4}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  words importing the singular shall include the plural and vice
                  versa;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={5}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  references in this Agreement to any Clause or any Schedule
                  shall be to a clause or schedule contained in this Agreement;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={6}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  any reference to writing shall include typewriting, printing,
                  lithography, photography, telex, facsimile and the printed-out
                  version of a communication by electronic mail and other modes
                  of representing or reproducing words in a legible form;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={7}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  any words following the terms&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  including, include, in particular, for example&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  or any similar expression shall be construed as illustrative
                  and shall not limit the sense of the words, description,
                  definition, phrase or term preceding those terms;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={8}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Clause headings shall not affect the interpretation of this
                  Agreement;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={9}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  A&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  person
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  &nbsp;includes a natural person, corporate or unincorporated
                  body (whether or not having separate legal personality);
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={10}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  This agreement shall be binding on, and inure to the benefit
                  of, the Parties to this Agreement and their respective
                  personal representatives, successors and permitted assigns,
                  and references to any party shall include that party's
                  personal representatives, successors and permitted assigns;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={11}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  A reference to a statute or statutory provision shall include
                  all subordinate legislation made from time to time under that
                  statute or statutory provision; and&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={12}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={3}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Any obligation on a party not to do something includes an
                  obligation not to allow that thing to be done.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  PURPOSE OF THE AGREEMENT
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The purpose of this Agreement is to define and agree to the terms
              upon which the Merchant will upload and display its services for
              either an outright sale or a Buy Now-Pay Later sale, on the
              Credley platform/application.&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol
            start={3}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  TERM &nbsp;&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              This Agreement shall commence on the date first above written and
              shall remain in force for until terminated by either Party, in
              accordance with its terms.
            </span>
          </p>
          <ol
            start={4}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  OBLIGATIONS
                </span>
              </p>
            </li>
          </ol>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "underline",
                    WebkitTextDecorationSkip: "none",
                    textDecorationSkipInk: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Merchant
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={8}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-alpha",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-18.55000000000001pt",
                    paddingLeft: "10.350000000000001pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      The Merchant agrees to only display for sale, goods and
                      services that are deemed merchantable under the relevant
                      laws.&nbsp;
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={8}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.44999999999999pt",
                paddingLeft: "10.350000000000001pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Merchant agrees to fully comply with all terms provided by
                  Credley which shall govern its relationship with its
                  customers. These terms include but are not limited to the
                  adequate protection of the data of the customers. &nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={3}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={8}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.44999999999999pt",
                paddingLeft: "10.350000000000001pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Merchant has a duty to issue receipts/tax invoices or any
                  evidence of receipt to customers and/or clients as prescribed
                  by law.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={4}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={8}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.44999999999999pt",
                paddingLeft: "10.350000000000001pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  If the Merchant intends to modify its payment type and/or
                  amend the method or conditions for selling of goods, engaging
                  of services or performing of works, affecting to Credley’s
                  performance of services hereunder, the Merchant agrees to
                  notify Credley of such in writing, ten (10) days in advance.
                  In this case, Credley reserves the right to charge an
                  additional fee as proper and both parties shall agree and make
                  it in writing and such document shall be deemed an integral
                  part of this Agreement.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={5}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={8}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.44999999999999pt",
                paddingLeft: "10.350000000000001pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "#ffff00",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Merchant shall indicate at the point of uploading the
                  details of the goods and services on the Credley platform, the
                  warranty of each individual product.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={6}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={8}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.44999999999999pt",
                paddingLeft: "10.350000000000001pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "#ffff00",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Merchant shall ensure that all goods uploaded on the
                  Credley platform and purchased, are merchantable and are
                  adequately delivered to the purchaser, as 100% (hundred
                  percent) payment will only be made for the goods and services
                  upon confirmation of delivery.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={7}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={8}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.44999999999999pt",
                paddingLeft: "10.350000000000001pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Merchant acknowledges and agrees that in case any damages
                  or errors occur from Credley’s performance of Services
                  according to information as delivered by the Merchant, the
                  Merchant shall not claim any damages against the Credley
                  and/or shall be solely responsible for such damages or errors
                  with its customers.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "underline",
                    WebkitTextDecorationSkip: "none",
                    textDecorationSkipInk: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Credley&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={8}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.44999999999999pt",
                paddingLeft: "10.350000000000001pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Credley shall ensure that its Credley platform/application is
                  always accessible for end users and customers, except where
                  there is a system glitch beyond Credley’s control.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={8}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.44999999999999pt",
                paddingLeft: "10.350000000000001pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Credley shall ensure that it keeps confidential, all relevant
                  information provided by the Merchant during this
                  Agreement.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <ol
            start={5}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ACKNOWLEDGEMENT OF RISKS IN ELECTRONIC TRANSACTIONS&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Merchant has carefully considered and realized that
                  electronic transactions are of risk and accepts any consequent
                  damages incurred from such electronic transactions.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Merchant agrees to strictly comply with the
                  following:&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={8}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "24.55000000000001pt",
                paddingLeft: "3.25pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  In case of receipt of the payments for goods and/or services,
                  the Merchant must keep, not less than twelve (12) months,
                  sales record or proof of trade, delivery of goods/services and
                  receipt of payments such as tax invoice, shipping slip,
                  receipt, etc. as proof in any disputes that may occur
                  thereafter. The Merchant further grants Credley the right to
                  very its records based on the transactions carried out on the
                  Credley application/platform. The Merchant also agrees to
                  assist Credley in order to acquire such information and
                  documents and shall not, in any circumstances, obstruct,
                  weaken, destroy or hinder Credley from acquiring such
                  information and documents.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={8}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "24.55000000000001pt",
                paddingLeft: "3.25pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  In case of refusal and/or non-receivable payment, card
                  cancellation or hold, or having a reasonable suspicion cause
                  regarding the dishonest use of credit card or any other
                  reasons causing Credley’s money to be deducted or compensated
                  or claimed as damages by any banks, financial institutes or
                  other persons, the Merchant agrees to reimburse Credley in
                  full amount which and that Credley is entitled to immediately
                  set-off such amount with the amount of payment for goods
                  and/or services payable to the Merchant without any objection
                  or attempt to decline the said responsibility in all respects.
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "90pt",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;
            </span>
          </p>
          <ol
            start={6}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  SERVICE FEE&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Credley is entitled to a service fee which shall constitute
                  10% of the price/amount for each goods displayed and sold via
                  the Credley platform/application.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  During the period of this Agreement, Credley reserve the right
                  to amend the rate of service fee by giving at least thirty
                  (30) days advance notice to the Merchant and such notice shall
                  be deemed to be a part of this Agreement. &nbsp;
                </span>
              </p>
            </li>
          </ol>
          <ol
            start={7}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "18pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  MARKETING ACTIVITIES&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Credley may advertise and publicize in connection with the
                  goods and services dispalyed by the Merchant on the Credley
                  Platform/application, and the Merchant shall provide the
                  cooperation with Credley in the advertisement and publication
                  through various media or other networks from time to time as
                  mutually agreed by both parties.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Merchant agrees to advertise and publicize its goods or
                  services as well as its use of Credley’s Services for receipt
                  of payments for goods and/or services of the Merchant.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={3}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  In the case that the Merchant uses any other service, method
                  or channel for receiving of payments for goods and/or services
                  from customers or clients, provided by other service
                  provider(s) other than Credley, the Merchant agrees to not act
                  such as publicizing, advertising, supporting or giving any
                  right to such service, method or channel of other service
                  provider(s) significantly or in addition to doing to the
                  services of Credley hereunder.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={8}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  COMMENCEMENT OF SERVICES&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The Merchant agrees to correctly fill and submit the Merchant
              Information Form contained in Schedule 1 to this Agreement,
              together with any supporting documents that are required by
              Credley, on the Commencement date, for Credley’s consideration.
              Furthermore, the Merchant shall only be entitled to use the
              Credley platform/application for the sale of goods and for
              receiving the payments for goods and/or services sold on the
              Credley platform/application, only upon obtaining the Credley’s
              permission.
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol
            start={9}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  INTELLECTUAL PROPERTY RIGHTS
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "12pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The Parties agree that this Agreement shall not transfer or grant
              title to any of the respective intellectual property rights as
              defined under Clause 1 of this Agreement unless otherwise agreed
              upon by the Parties. However, the Merchant grants to Credley the
              right to use its Intellectual property for the purpose of
              marketing of its Credley platform/application and the
              goods/services sold therein.&nbsp;
            </span>
          </p>
          <ol
            start={10}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  CONFIDENTIALITY AND DATA PRODUCTION&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "-0.5499999999999972pt",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              During the term of this Agreement, and for a period of two (2)
              years thereafter, all confidential and/or proprietary information,
              whether oral, written or contained in any medium whatsoever, that
              is made available to either party (the “Recipient Party”) shall be
              kept strictly confidential and such information shall not be
              divulged to any third party without the prior written consent of
              the other party (the “
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Disclosing Party
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ”) except to legal, accounting and financial advisers of either
              party or third Parties providing services with respect to any of
              the activities herein, and provided that such advisers or other
              third Parties agree in writing to maintain the confidentiality of
              such information. The Parties shall comply with the requirements
              of all legislation and regulatory requirements in force from time
              to time relating to the use of personal data, including (without
              limitation) the Data Protection Legislation.
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol
            start={11}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  WARRANTIES AND INDEMNITY
                </span>
              </p>
            </li>
          </ol>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Merchant represents and warrants that:
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  any information delivered to Credley under this Agreement is
                  correct.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.450000000000003pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  the Merchant is engaged in a lawful business that includes the
                  sale of Goods and/or Services, and duly licenses to conduct
                  such business under laws of all jurisdictions in which the
                  Merchant conducts business. The Merchant shall comply with all
                  laws, policies, guidelines, regulations ordinances or rules
                  applicable to Merchant’s business and this Agreement.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.450000000000003pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  all goods and/or services, which the Merchant seeks to upload
                  and display on the Credley platform/application to receive the
                  payments hereunder are not goods and/or services prohibited by
                  law and good morals or that would expose the Company’s image
                  to risk.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.450000000000003pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  the Merchant shall comply with the relevant consumer
                  protection laws, regulations and treaties to which Nigerians
                  are generally bound by, including but not limited to the
                  Federal Competition and Consumer Protection Act.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.450000000000003pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  the Merchant shall comply with the relevant cybersecurity
                  laws, regulations and treaties to which Nigerians are
                  generally bound by, including but not limited to the
                  Cybercrimes (Prohibition, Prevention, etc) Act 2015.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.450000000000003pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "8pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  it is under no restriction that would prevent it from entering
                  into and performing this Agreement and that it can perform its
                  obligations under this Agreement fully and completely.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Failing of the above representations and warranties, the Merchant
              agrees that Credley is entitled to terminate this Agreement
              immediately and the Merchant shall be responsible for all damages
              incurred to Credley, including any loss occurred from the Merchant
              as the result of its failure to comply with this clause.
            </span>
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "3.3000000000000007pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "8pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Credley represents and warrants that:
                </span>
              </p>
            </li>
          </ol>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.450000000000003pt",
                paddingLeft: "3.25pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  it has obtained all relevant authorizations, permits and
                  licenses from the Board of Directors, needed to enter into
                  this Agreement.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.450000000000003pt",
                paddingLeft: "3.25pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  it will at all times ensure that the Credley
                  platform/application is in a proper state and can be easily
                  accessed by customers and consumers that seek to patronize the
                  products and services of the Merchant.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={3}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "3.3000000000000007pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Both Parties represents and warrants that:
                </span>
              </p>
            </li>
          </ol>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={8}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.44999999999999pt",
                paddingLeft: "3.25pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  any requisite regulatory license needed before and during the
                  subsistence of this Agreement has either been obtained or will
                  be obtained at their individual expense.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={8}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.44999999999999pt",
                paddingLeft: "3.25pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  they are not aware or cannot reasonably foresee any internal
                  or regulatory requirement arising from prior Agreement or
                  incidental issues capable of threatening the performance of
                  their respective obligations under this Agreement AND that
                  should they become aware after the execution of this
                  Agreement, they shall take all necessary and reasonable steps
                  to facilitate, address and resolve the hindrance. This shall
                  not apply to Force Majeure Events.
                </span>
              </p>
            </li>
            <li
              aria-level={8}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "17.44999999999999pt",
                paddingLeft: "3.25pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  they shall comply fully with their obligations under this
                  Agreement and shall be liable in breach to each other in the
                  event of a breach arising from a failure (which is not a Force
                  Majeure Event), neglect or refusal to comply or adhere to the
                  provisions of this Agreement.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "35.45pt",
              textIndent: "0.5499999999999972pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The Parties indemnify each other against all liabilities, costs,
              expenses, damages and losses (including legal fees) that either of
              the Parties may suffer as a result of the breach of any warranty
              contained in this Agreement.
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol
            start={12}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  NOTICE.
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "35.45pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Any notice or communication required or permitted under this
              Agreement shall be sufficiently given if delivered in person or by
              certified mail, return receipt requested, to the address set forth
              in the opening paragraph or to such other address as one party may
              have furnished to the other in writing.
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol
            start={13}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  WAIVER OF CONTRACTUAL RIGHT.
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "35.45pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The failure of either party to enforce any provision of this
              Agreement shall not be construed as a waiver or limitation of that
              party's right to subsequently enforce and compel strict compliance
              with every provision of this Agreement.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;
            </span>
          </p>
          <ol
            start={14}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ASSIGNMENT.
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "35.45pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Neither party may assign or transfer this Agreement without the
              prior written consent of the non-assigning party, which approval
              shall not be unreasonably withheld.
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol
            start={15}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  FORCE MAJEURE&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textIndent: "-36pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
              padding: "0pt 0pt 0pt 36pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Upon the occurrence of an Event of Force Majeure, the obligations
              of the parties shall be suspended for so long as the Event of
              Force Majeure renders performance of the agreement impossible.
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "14pt",
                  marginBottom: "14pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Neither party will be liable for any delay in performing or
                  failure to perform any of its obligations under this Agreement
                  caused by an Event of Force Majeure.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The party claiming an Event of Force Majeure will promptly
                  notify the other party in writing of the reasons for the delay
                  or stoppage (and the likely duration) and will take all
                  reasonable steps to overcome the delay or stoppage.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={3}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  If the Party claiming the Event of Force Majeure has complied
                  with the foregoing, its performance under this Agreement will
                  be suspended for the period that the Event of Force Majeure
                  continues and the party will have an extension of time for
                  performance, which is reasonable and, in any event, equal to
                  the period of delay or stoppage. As regards such delay or
                  stoppage, any cost arising from the delay or stoppage will be
                  borne by the party incurring those costs; either party may, if
                  the delay or stoppage continues for more than Six (6)
                  continuous month, terminate this Agreement with immediate
                  effect on giving written notice to the other and neither party
                  will be liable to the other for such termination.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={16}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "#ffffff",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  TERMINATION&nbsp;
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={2}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.295",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      This Agreement may be terminated as follows:
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "36pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  by mutual consent in writing of the Parties; and
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "36pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "12pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Without prejudice to any other right or remedy it may have,
                  either Party may terminate this Agreement at any time by a
                  written notice where the other Party breaches this Agreement
                  and fails to remedy the breach within seven (7) days of
                  receiving written notice from the non-defaulting Party
                  specifying the breach and requiring it to be remedied
                </span>
              </p>
            </li>
          </ol>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "24.549999999999997pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "14pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Termination of this Agreement releases any Party from further
                  performance of any liability or obligation under this
                  Agreement but does not:
                </span>
              </p>
            </li>
          </ol>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "38.7pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "14pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  affect any provision of this Agreement expressed to operate or
                  have effect subsequent to termination; or
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "38.7pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "14pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  have any prejudicial effect on any accrued rights, liabilities
                  or obligations of any Party in relation to any default under
                  this Agreement by the other Party occurring prior to
                  termination.
                </span>
              </p>
            </li>
          </ol>
          <ol
            start={3}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "14pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Each party shall, return to the other party all documents and
                  materials (and all copies thereof) containing the other
                  Party’s Confidential Information and certify in writing to the
                  other party that it has complied with the requirements of this
                  Sub-Clause.
                </span>
              </p>
            </li>
          </ol>
          <ol
            start={17}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "12pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  APPLICABLE LAW&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "35.45pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The applicable law for all purposes in this Agreement shall be the
              laws of the Federal Republic of Nigeria and any other law or laws
              as may be agreed upon by the parties.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "40.5pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;
            </span>
          </p>
          <ol
            start={18}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  DISPUTE RESOLUTION
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={2}
                  dir="ltr"
                  style={{
                    listStyleType: "decimal",
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                    marginLeft: "-54pt",
                    paddingLeft: "17.450000000000003pt",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.2",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "0pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: "Georgia",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Any difference, dispute, controversy or claim (“Dispute”)
                      which may arise between the Parties out of or in relation
                      to or in connection with this Agreement, or the breach,
                      termination, effect, validity, interpretation or
                      application of this Agreement or as to their rights,
                      duties or liabilities hereunder, other than a dispute for
                      which provisions specifically made in this Agreement, the
                      parties shall use their best endeavours to settle such
                      dispute by mutual negotiations and agreement.&nbsp;
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={2}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  If for any reason, the Parties cannot resolve such dispute
                  amicably within one month from the commencement of the
                  resolution process, such dispute shall be referred to
                  arbitration. Both Parties shall jointly contribute to the fees
                  of the Arbitrator.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            start={19}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  REMEDIES.
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "35.45pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              In addition to any and all other rights, a party may have
              available according to law, if a party defaults by failing to
              substantially perform any provision, term, or condition of this
              Agreement (including without limitation the failure to make a
              monetary payment when due), the other party may terminate the
              Agreement by providing written notice to the defaulting party.
              This notice shall describe with sufficient detail the nature of
              the default. The party receiving such notice shall have Seven (7)
              days from the effective date of such notice to cure the
              default(s). Unless waived by a party providing notice, the failure
              to cure the default(s) within such period shall result in the
              automatic termination of this Agreement.
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol
            start={20}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  SEVERABILITY.
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "35.45pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              If any provision of this Agreement will be held to be invalid or
              unenforceable for any reason, the remaining provisions will
              continue to be valid and enforceable. If a court finds that any
              provision of this Agreement is invalid or unenforceable, but that
              by limiting such provision it would become valid and enforceable,
              then such provision will be deemed to be written, construed, and
              enforced as so limited.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;
            </span>
          </p>
          <ol
            start={21}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  AMENDMENT.
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "35.45pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              This Agreement may be modified or amended in writing if the
              writing is signed by the party obligated under the amendment.
            </span>
          </p>
          <p>
            <br />
          </p>
          <ol
            start={22}
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "17.450000000000003pt",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: "Georgia",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ENTIRE AGREEMENT.
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginLeft: "35.45pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              This Agreement contains the entire agreement of the parties, and
              there are no other promises or conditions in any other agreement
              whether oral or written concerning the subject matter of this
              Agreement. This Agreement supersedes any prior written or oral
              agreements between the parties.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              IN WITNESS OF WHICH,&nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              the Parties have executed this Agreement in the manner below, day
              and year first above written.&nbsp;
            </span>
          </p>
          <p>
            <br />
          </p>
          <div align="left" dir="ltr" style={{ marginLeft: "10.4pt" }}>
            <table style={{ border: "none", borderCollapse: "collapse" }}>
              <tbody>
                <tr style={{ height: "24.8pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "8pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        For
                      </span>
                    </p>
                    <br />
                    <br />
                  </td>
                  <td
                    style={{
                      borderBottom: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "8pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        CREDLEY GLOBAL LIMITED&nbsp;
                      </span>
                    </p>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "23.85pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "8pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Name:
                      </span>
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <span
                          className="Apple-tab-span"
                          style={{ whiteSpace: "pre" }}
                        >
                          &nbsp; &nbsp;&nbsp;
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderBottom: "solid #000000 0.75pt",
                      borderTop: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                  <td
                    style={{
                      borderTop: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "8pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Position:
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderBottom: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                  <td
                    style={{
                      borderTop: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "23.45pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "8pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Date:
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderBottom: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                  <td
                    style={{
                      borderTop: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "22.55pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "8pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Sign:
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderBottom: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <div align="left" dir="ltr" style={{ marginLeft: "10.4pt" }}>
            <table style={{ border: "none", borderCollapse: "collapse" }}>
              <tbody>
                <tr style={{ height: "24.8pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "8pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        For
                      </span>
                    </p>
                    <br />
                    <br />
                  </td>
                  <td
                    style={{
                      borderBottom: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "8pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        ______________________________________&nbsp;
                      </span>
                    </p>
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "23.85pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "8pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Name:
                      </span>
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <span
                          className="Apple-tab-span"
                          style={{ whiteSpace: "pre" }}
                        >
                          &nbsp; &nbsp;&nbsp;
                        </span>
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderBottom: "solid #000000 0.75pt",
                      borderTop: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                  <td
                    style={{
                      borderTop: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "8pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Position:
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderBottom: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                  <td
                    style={{
                      borderTop: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "23.45pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "8pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Date:
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderBottom: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                  <td
                    style={{
                      borderTop: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "22.55pt" }}>
                  <td
                    style={{
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.295",
                        marginTop: "0pt",
                        marginBottom: "8pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Sign:
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderBottom: "solid #000000 0.75pt",
                      verticalAlign: "top",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              SCHEDULE 1
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              . &nbsp; &nbsp; &nbsp;- MERCHANT INFORMATION FORM&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              SECTION 1 – BUSINESS INFORMATION&nbsp;
            </span>
          </p>
          <div align="left" dir="ltr" style={{ marginLeft: "0pt" }}>
            <table style={{ border: "none", borderCollapse: "collapse" }}>
              <tbody>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Date&nbsp;
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Registered Business Name
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Business Objectives/Type
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Merchant Domain Name (if available)
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        RC Number (if business is registered as a company
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Registered Business Address&nbsp;
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Mailing/Notice Address
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Telephone Number&nbsp;
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Fax Number&nbsp;
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              SECTION 2- CONTACT INFORMATION&nbsp;
            </span>
          </p>
          <div align="left" dir="ltr" style={{ marginLeft: "0pt" }}>
            <table style={{ border: "none", borderCollapse: "collapse" }}>
              <tbody>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Name of Business Contact Person
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Position of Contact Person
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Mobile Number of Contact Person
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        E-Mail Number of Contact Person
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Name of Technical Contact Person&nbsp;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        (if any)
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Position Technical Contact Person
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Mobile Number of Technical Contact Person
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        E-Mail of Technical Contact Person
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Accounting Contact Person (Mandatory)
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Position of Accounting Contact Person
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Mobile Number of Accounting Contact Person
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        E-Mail Address of Accounting Contact Person
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.295",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: "Georgia",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              SECTION 3 – ACCOUNT/PAYMENT DETAILS
            </span>
          </p>
          <div align="left" dir="ltr" style={{ marginLeft: "0pt" }}>
            <table style={{ border: "none", borderCollapse: "collapse" }}>
              <tbody>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Merchant’s Preferred Payment Gateway&nbsp;
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Merchant’s Bank Account Information
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Bank Name and Branch
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
                <tr style={{ height: "0pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.2",
                        marginTop: "0pt",
                        marginBottom: "0pt",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: "Georgia",
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        Account Type
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      padding: "0pt 5.4pt 0pt 5.4pt",
                      overflow: "hidden",
                      overflowWrap: "break-word",
                    }}
                  >
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantAgreement;
