import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, logoutUser } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import AccountSetup from "../../components/account-setup/AccountSetup";
import { getMerchantDeliveryAddress } from "../../redux/actions/deliveryLocationActions";
import isEmpty from "../../validation/isEmpty";
import useInactivityLogout from "../../hooks/useInactivityLogout";

function AccountSettings() {
  const userInfo = useSelector((state) => state.users.user);
  // Funtion, State and Variables
  //   const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // States
  const [pageStateObj, setPageStateObj] = useState(null);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  // handle Log Out User
  const handleLogOutUser = function () {
    dispatch(logoutUser(navigate));
  };

  useInactivityLogout({
    inactivityExpireTime: 120,
    onExpired: handleLogOutUser,
  });

  // UseEffects
  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      // Get Current User data
      dispatch(getCurrentUser(setIsLoading, setErrorHandlerObj));
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    // console.log(userInfo, "user info");
    setPageStateObj((prevStateObj) => {
      if (!userInfo) return prevStateObj;
      let newStateObj = {
        // Prefilled from user data
        email: userInfo.email,
        phoneNumber: userInfo.phoneNumber,
        shopName: userInfo.shopName,
        accountName: userInfo.accountName || "",
        accountNumber: userInfo.accountNumber || "",
        address: userInfo.address || "",
        location: userInfo.location || "",
        bank: userInfo.bank || "",
        bvn: userInfo.bvn || "",
        cacDocument: userInfo.cacDocument || "",
        deliveryCost: userInfo.deliverycost || {},
        profilePicture: userInfo.profilePicture || "",
      };
      return newStateObj;
    });
  }, [userInfo]);

  return (
    <>
      {pageStateObj ? (
        <AccountSetup pageType={"settings"} pageStateObj={pageStateObj} />
      ) : (
        ""
      )}
    </>
  );
}

export default AccountSettings;
