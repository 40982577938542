import React, { useState, useEffect } from "react";
import "./FundWithdrawal.css";
import Navbar from "../app-navbar/Navbar";
import Top from "../app-top/Top";
import angleLeftIcon from "../../image/angle-left.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import percentIncreaseIcon from "../../image/percent-increase-icon.svg";
import percentDecreaseIcon from "../../image/percent-decrease-icon.svg";
import TextInput from "../input-components/text-input/TextInput";
import dropdownIcon from "../../image/angle-down.svg";
import { Table } from "react-bootstrap";
import ConfirmWithdrawalModal from "./modal/ConfirmWithdrawalModal";
import ErrorHandler from "../error/ErrorHandler";
import SuccessHandler from "../success/SuccessHandler";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { allMonths } from "../../utils/allMonths";
import { getAllWithdrawalsForMerchant } from "../../redux/actions/withdrawalActions";
import isEmpty from "../../validation/isEmpty";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import { getMerchantWallet } from "../../redux/actions/walletActions";
import moment from "moment";
import FEPagination from "../pagination/fe-pagination/FEPagination";

const merchantWalletData = {
  walletBalancePercentageChange: +5,
};

const userWithdrawalHistory = [
  {
    amount: "2000000",
    balance: "2000000",
    date: "20, Jul 2022",
    status: "Successful",
  },
  {
    amount: "2000000",
    balance: "2000000",
    date: "20, Jul 2022",
    status: "Failed",
  },
  {
    amount: "2000000",
    balance: "2000000",
    date: "20, Jul 2022",
    status: "Failed",
  },
  {
    amount: "2000000",
    balance: "2000000",
    date: "20, Jul 2022",
    status: "Failed",
  },
  {
    amount: "2000000",
    balance: "2000000",
    date: "20, Jul 2022",
    status: "Successful",
  },
  {
    amount: "2000000",
    balance: "2000000",
    date: "20, Jul 2022",
    status: "Failed",
  },
  {
    amount: "2000000",
    balance: "2000000",
    date: "20, Jul 2022",
    status: "Successful",
  },
];
function FundWithdrawal() {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const withdrawals = useSelector((state) => state.withdrawals.withdrawals);
  const wallet = useSelector((state) => state.wallet.wallet);

  //   States
  const [walletData, setWalletData] = useState(merchantWalletData);
  const [merchantWallet, setMerchantWallet] = useState(null);
  const [amount, setAmount] = useState("");
  const [historyMonthSort, setHistoryMonthSort] = useState("");
  const [withdrawalHistory, setWithdrawalHistory] = useState(null);

  const [currentPosts, setCurrentPosts] = useState(null);

  const [isConfirmWithdrawalModalOpened, setIsConfirmWithdrawalModalOpened] =
    useState(false);
  const [isWithdrawalSuccessful, setIsWithdrawalSuccessful] = useState(false);

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const indexOfLastPost = currentPage * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Functions
  //   Structured Money Number Format;

  //   handle Make Withdrawal
  const handleMakeWithdrawal = function (e) {
    if (amount !== "") {
      e.preventDefault();

      setIsConfirmWithdrawalModalOpened(true);
      setSuccessHandlerObj({ isSuccess: false, message: "" });
      setErrorHandlerObj({ hasError: false, message: "" });
      setIsWithdrawalSuccessful(false);
    }
  };

  //   Useeffect

  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      let xCoord = 0,
        yCoord = 0;

      //scroll to page top
      window.scrollTo(xCoord, yCoord);
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  useEffect(() => {
    dispatch(getMerchantWallet(setIsLoading, setErrorHandlerObj));
  }, []);
  useEffect(() => {
    dispatch(
      getAllWithdrawalsForMerchant(setIsHistoryLoading, setErrorHandlerObj)
    );

    if (isWithdrawalSuccessful) {
      setAmount("");
    }
  }, [isWithdrawalSuccessful]);

  useEffect(() => {
    setMerchantWallet(wallet);
  }, [wallet]);

  useEffect(() => {
    setWithdrawalHistory(withdrawals);
  }, [withdrawals]);

  useEffect(() => {
    const currentRows = withdrawalHistory?.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    setCurrentPosts(currentRows);
  }, [withdrawalHistory, currentPage]);

  return (
    <div
      className={`fund-withdrawal-container  ${
        isConfirmWithdrawalModalOpened ? "overflow-hidden" : ""
      }`}
    >
      <Top />
      {/* <Navbar /> */}

      <div
        onClick={() => setIsConfirmWithdrawalModalOpened(false)}
        className={`gray-overlay ${
          isConfirmWithdrawalModalOpened ? "" : "none"
        }`}
      ></div>
      {/* Confirm Withrawal Modal */}
      <ConfirmWithdrawalModal
        isConfirmWithdrawalModalOpened={isConfirmWithdrawalModalOpened}
        setIsConfirmWithdrawalModalOpened={setIsConfirmWithdrawalModalOpened}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        amount={amount}
        setAmount={setAmount}
        merchantWallet={merchantWallet}
        setIsWithdrawalSuccessful={setIsWithdrawalSuccessful}
      />

      <div className="fund-withdrawal-container--inner">
        <div className="fund-withdrawal-top-container">
          <div className="fund-withdrawal-top--left">
            {/* Back button Wrapper */}
            <div className="back-button-wrapper">
              <button onClick={() => navigate(-1)}>
                <img src={angleLeftIcon} alt="" />
              </button>
            </div>

            {/* Setup Top Title */}
            <div className="fund-withdrawal-top-title">Make Withdrawal</div>
          </div>

          {/* Error handler component */}
          <ErrorHandler errorHandlerObj={errorHandlerObj} />

          {/* Success handler component */}
          <SuccessHandler successHandlerObj={successHandlerObj} />
        </div>

        {/* Main Section Container */}
        <div className="fund-withdrawal-main-section-container">
          {/* ROW ONE SECTION */}
          <section className="fund-withdrawal-row--one fund-withdrawal-row-section">
            {/* Row One Left */}
            <div className="fund-withdrawal-row--one--left">
              {/* Wallet Balance container / Row one Left */}
              <div className="row-one--left-container wallet-balance-container fund-withdrawal--wallet-balance-container">
                {/* Top Wrapper */}
                <div className="row-one--left--top-wrapper">
                  <div className="row-one--box-title wallet-balance-box-title">
                    WALLET BALANCE
                  </div>
                </div>

                {/* Bottom Wrapper */}
                <div className="row-one--left--bottom-wrapper">
                  {/* Wallet balancee value wrapper */}
                  <div className="wallet-balance-value-wrapper">
                    &#8358;
                    {numberWithCommas(merchantWallet?.balance || 0)}
                  </div>

                  {/* wallet balance percent chang wrapper */}
                  <div
                    className={`row--one-percent-change-wrapper wallet-balance-percent-change-wrapper ${
                      walletData.walletBalancePercentageChange < 0
                        ? "percent-decrease-wrapper"
                        : "percent-increase-wrapper"
                    }`}
                  >
                    0%{" "}
                    <img
                      src={
                        walletData.walletBalancePercentageChange < 0
                          ? percentDecreaseIcon
                          : percentIncreaseIcon
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Row One Right */}
            <div className="fund-withdrawal-row--one--right">
              <form>
                {/* Amount to withdraw form ggroup */}
                <div className="form-group amount-to-withdraw-form-group">
                  <TextInput
                    type={"text"}
                    id={"amount-to-withdraw-text-input"}
                    className={"amount-to-withdraw-input"}
                    placeholder={""}
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                      // }
                    }}
                    required={true}
                    label={"Amount"}
                  />
                </div>

                <div className="withdraw-amount-btn-wrapper">
                  <button onClick={(e) => handleMakeWithdrawal(e)}>
                    Withdraw
                  </button>
                </div>
              </form>
            </div>
          </section>

          {/* ROW TWO SECTION */}
          <section className="fund-withdrawal-row--two withdrawal-history-row-section">
            {/* Section Top Container */}
            <div className="withdrawal-history-row-top-container">
              {/* Section title */}
              <div className="withdrawal-history-section-title">
                Withdrawal History
              </div>

              {/* Withrawal histtory sort select dropdown */}
              <div className="merchant-revenue-sort-dropdown-wrapper">
                <select
                  id={"withdrawal-history-sort-dropdown"}
                  className={"withdrawal-history-sort-dropdown-input"}
                  value={historyMonthSort}
                  onChange={(e) => {
                    // Change later to stand-alone function when more info is gathered
                    setHistoryMonthSort(e.target.value);
                  }}
                >
                  {allMonths.map((optionValue, i) => (
                    <option
                      key={i + 1}
                      disabled={optionValue.default ? true : false}
                      value={optionValue.key}
                      hidden={optionValue.default ? true : false}
                      className=""
                    >
                      {optionValue.value}
                    </option>
                  ))}
                </select>
                <div className="dropdown-icon">
                  <img src={dropdownIcon} alt="" />
                </div>
              </div>
            </div>

            {/* Section Main Container */}
            <div className="withdrawal-history-table-container">
              <Table responsive="lg" className="withdrawal-history-table">
                <thead
                  style={{
                    textAlign: "left",
                  }}
                >
                  <tr>
                    {[
                      // "Date",
                      "AMOUNT",
                      // "BALANCE",
                      "DATE",
                      "STATUS",
                    ].map((COLUMN, index) => (
                      <th key={index + 1}>{COLUMN}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {currentPosts?.map((historyRow, i) => (
                    <tr key={i + 1}>
                      {/* Withdrawal Amount Column */}
                      <td className="td-withdrawal-amount">
                        <div className="td-withdrawal-amount--wrapper">
                          {numberWithCommas(historyRow?.amount || 0)}
                        </div>
                      </td>

                      {/*  Withdrawal Balance Column */}
                      {/* <td className="td-withdrawal-balance">
                        <div className="td-withdrawal-balance--wrapper">
                          {numberWithCommas(historyRow?.balance || 0)}
                        </div>
                      </td> */}

                      {/* Withdrawal Date Column */}
                      <td className="td-withdrawal-date">
                        <div className="td-withdrawal-date--wrapper">
                          {moment(historyRow.createdAt).format("Do MMM, YY")}
                        </div>
                      </td>

                      {/* Withdrawal Status Column*/}
                      <td className="td-withdrawal-status">
                        <div
                          className={`td-withdrawal-status--wrapper ${
                            historyRow.status === 0
                              ? "failed-withdrawal-wrapper"
                              : "successful-withdrawal-wrapper"
                          }`}
                        >
                          {historyRow.status === 0 ? "Failed" : "Success"}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {isHistoryLoading && <LoadingSpinner />}
            </div>

            {withdrawalHistory && !isEmpty(withdrawalHistory) && (
              <>
                <FEPagination
                  paginate={paginate}
                  rowsPerPage={rowsPerPage}
                  totalPosts={withdrawalHistory.length}
                  currentPage={currentPage}
                />
              </>
            )}
          </section>
        </div>
      </div>
    </div>
  );
}

export default FundWithdrawal;
