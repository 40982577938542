import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Top.css";
import credleyLogo from "../../image/credley-logo.svg";
import settingsIcon from "../../image/settings-icon.svg";
import searchIcon from "../../image/search-icon.svg";
import { getCurrentUser } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../validation/isEmpty";
import jwtDecode from "jwt-decode";
import { SearchContext } from "../contexts/SearchContext";

function Top() {
  const { searchedValue, setSearchedValue } = useContext(SearchContext);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.users.user);
  const navigate = useNavigate();
  const location = useLocation();
  // Functions and states
  const [user, setUser] = useState(null);

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  const handleNavigateHome = function () {
    const token = localStorage.getItem("jwtToken");
    const decoded = jwtDecode(token);

    if (token && decoded?.shopName) {
      navigate("/merchant/dashboard");
    } else {
      navigate("/signin");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    const decoded = jwtDecode(token);
    // Check if User exists || is logged in
    if (token && decoded?.shopName) {
      // Check if User exists || is logged in
      if (token) {
        // Get Current User data
        dispatch(getCurrentUser(setIsLoading, setErrorHandlerObj));
      } else {
        navigate("/");
      }
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    setUser(userInfo);
    if (token && !isEmpty(userInfo) && !userInfo.isVerified) {
      navigate("/merchant/verification");
    }
  }, [userInfo]);

  useEffect(() => {
    // Set searched value to default location change
    setSearchedValue("");
  }, [location]);

  return (
    <div className="app-top-container">
      <div className="app-top-container--inner">
        {/* Company Logo section / Left Section  */}
        <div className="company-logo-wrapper" onClick={handleNavigateHome}>
          <img src={credleyLogo} width={100} height={30} alt="" />
        </div>

        {/* Search Bar Container / Middle Section */}
        <div className="top-search-bar-container">
          <div className="search-icon-wrapper">
            <img src={searchIcon} alt="" />
          </div>
          <input
            type="search"
            id="app-top-search-bar-input"
            className="top-search-input"
            placeholder="Search"
            value={searchedValue}
            onChange={(e) => setSearchedValue(e.target.value)}
          />
        </div>

        {/* Profile section Container / Right Section */}
        <div className="top-profile-container">
          {/* Profile image wrapper */}
          <div className="profile-img-wrapper">
            {user?.profilePicture && <img src={user.profilePicture} alt="" />}
          </div>

          {/* Merchant Name wrapper */}
          <div className="merchant-name-wrapper">
            <div className="merchant-user-name">{user?.shopName}</div>
            <div className="merchant-title">Merchant</div>
          </div>

          {/* Settings icon wrapper */}
          <div className="settings-icon-wrapper">
            <Link to={"/settings"}>
              <img src={settingsIcon} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Top;
