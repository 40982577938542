import React, { useState } from "react";
import useDisplayMessage from "../../../utils/useDisplayMessage";
import VoucherSetup from "../voucher-setup/VoucherSetup";

function VoucherCreate() {
  // Funtion, State and Variables
  // States
  const [currentVoucherObj, setCurrentVoucherObj] = useState([]);
  const [pageContent, setPageContent] = useState("voucher-info");
  const [isLoading, setIsLoading] = useState(false);

  // Hooks
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  return (
    <>
      {currentVoucherObj !== null && (
        <VoucherSetup
          pageType={"create"}
          currentVoucherObj={currentVoucherObj}
          pageContent={pageContent}
          setPageContent={setPageContent}
          errorHandlerObj={errorHandlerObj}
          setErrorHandlerObj={setErrorHandlerObj}
          successHandlerObj={successHandlerObj}
          setSuccessHandlerObj={setSuccessHandlerObj}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
}

export default VoucherCreate;
