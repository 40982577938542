import React from "react";
import Top from "../app-top/Top";
import "./AccountAwaitingApproval.css";
import smileEmojiIcon from "../../image/smile-emoji.png";
import PrimaryButton from "../buttons/primary-button/PrimaryButton";
import { useNavigate } from "react-router-dom";

function AccountAwaitingApproval() {
  // Functions, States and Variables
  const navigate = useNavigate();
  return (
    <>
      <Top />
      {/* <Navbar /> */}
      <section className="account-awaiting-approval-section">
        {/* Container Header  */}
        <div className="dashboard-awaiting-data-section-header">
          <div className="dashboard-awaiting-data-section-header-title awaiting-approval-header-title ">
            Just give us one sec <img src={smileEmojiIcon} alt="" />
          </div>

          <div className="dashboard-awaiting-data-section-header-sub-title awaiting-approval-sub-title">
            Your Account is currently undergoing approval.
          </div>

          {/* <div className="dashboard-awaiting-data-section-header-sub-title awaiting-approval-sub-text-body">
            The Credley team is currently checking your account to get you
            approved!
          </div> */}

          <div className="primary-btn-container">
            <PrimaryButton
              className="awaiting--go-back-button-wrapper"
              placeholder="Go Back"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default AccountAwaitingApproval;
