import axios from "axios";
import { GET_ERRORS, GET_MERCHANT_DELIVERY_LOCATIONS } from "../constants";
import isEmpty from "../../validation/isEmpty";
import { api } from "../../api/config";
import jwtDecode from "jwt-decode";


const url = `${api}/api/v1/merchant`

const token = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;

// Get all merchant's delivery address;
export const getMerchantDeliveryAddress = (setIsLoading, setErrorHandlerObj) => (dispatch) => {
    setIsLoading(true)
    axios.get(`${url}/delivery-details`)
        .then((res) => {
            dispatch({
                type: GET_MERCHANT_DELIVERY_LOCATIONS,
                payload: res.data,
            });
            // console.log(res.data)
            setIsLoading(false)
        })
        .catch((err) => {
            if (!isEmpty(err) && !isEmpty((err.response))) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                })
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: "Something Went Wrong. Please Check your Connection and try again"
                })
            }

            setIsLoading(false)
            // Set the error handler state
            setErrorHandlerObj({
                hasError: true,
                message:
                    err?.response?.data ||
                    "Something Went Wrong. Please Check your Connection and try again",
            });

        })
}


// Update Delivery Price Per Location
export const updateDeliveryPricePerLocation = (data, setIsLoading, setErrorHandlerObj, setSuccessHandlerObj, setPageContent, pageType, navigate) => (dispatch) => {
    setIsLoading(true)
    axios.patch(`${url}/update-delivery-details`, data)
        .then((res) => {

            // console.log(res.data)
            // Set Page Content to awaiting dashboard data / setup done
            if (pageType === "settings") {
                setSuccessHandlerObj({
                    isSuccess: true,
                    message: "Delivery prices successfully updated!",
                });
            } else {
                axios.get(`${api}/api/v1/merchant/one`)
                    .then((response) => {
                        setIsLoading(false)
                        const userInfo = response.data
                        console.log(response.data, "authh")
                        if (userInfo?.profilePicture && userInfo?.address && userInfo?.cacDocument && userInfo?.accountNumber) {
                            // Set Page Content
                            // setPageContent("setup-done");
                            navigate("/merchant/awaiting-approval")

                        } else {
                            // Set Page Content
                            setPageContent("main");

                        }
                    })
                // setPageContent("setup-done");
            }
        })
        .catch((err) => {
            if (!isEmpty(err) && !isEmpty((err.response))) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                })
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: "Something Went Wrong. Please Check your Connection and try again"
                })
            }

            setIsLoading(false)
            // Set the error handler state
            setErrorHandlerObj({
                hasError: true,
                message:
                    err?.response?.data ||
                    "Something Went Wrong. Please Check your Connection and try again",
            });

        })

}