import React, { useState } from "react";
import { useEffect } from "react";
import TextInput from "../../input-components/text-input/TextInput";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import "./Modals.css";

const UseFlatFeeModal = function ({
  isUseFlatFeeModalOpened,
  setIsUseFlatFeeModalOpened,
  setErrorHandlerObj,
  setSuccessHandlerObj,
  flatFeePrices,
  setFlatFeePrices,
  setIsUseFlatFeeSubmitted,
}) {
  // Functions and states
  // States
  const [smallItemPriceValue, setSmallItemPriceValue] = useState("");
  const [mediumItemPriceValue, setMediumItemPriceValue] = useState("");
  const [largeItemPriceValue, setLargeItemPriceValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //   Functions
  const handleSubmitFlatFee = function (e) {
    if (
      smallItemPriceValue !== "" &&
      mediumItemPriceValue !== "" &&
      largeItemPriceValue !== ""
    ) {
      e.preventDefault();
      setIsUseFlatFeeSubmitted(true);
      setIsUseFlatFeeModalOpened(false);
    }
  };

  return (
    <div
      className={`use-flat-fee-modal-container ${
        isUseFlatFeeModalOpened ? "" : "none"
      }`}
    >
      <div className="use-flat-fee-modal-container--inner">
        <div className="use-flat-fee-modal-header">
          Add Flat Fees {isLoading && <LoadingSpinner />}
        </div>
        <div className="use-flat-fee-modal-sub-header">
          NB: Flat fees only apply for the Local governments within Lagos.
        </div>

        {/* Flat Fee form wrapper */}
        <div className="flat-fee-form-wrapper">
          <form>
            {/* Small Item Flat Fee form group */}
            <div className="form-group">
              <TextInput
                type={"text"}
                id={`small-item-flat-fee-text-input`}
                className={"small-item-flat-fee-input"}
                placeholder={"0"}
                value={smallItemPriceValue}
                onChange={(e) => {
                  setSmallItemPriceValue(e.target.value);

                  setFlatFeePrices((prevFlatFeePrices) => {
                    let updatedFlatFeePrices = {
                      ...prevFlatFeePrices,
                      smallItemPrice: e.target.value,
                    };

                    return updatedFlatFeePrices;
                  });
                }}
                required={true}
                label={"Small Items"}
                subLabel={"Flat fee"}
              />
            </div>

            {/* Medium Item Flat Fee form group */}
            <div className="form-group">
              <TextInput
                type={"text"}
                id={`medium-item-flat-fee-text-input`}
                className={"medium-item-flat-fee-input"}
                placeholder={"0"}
                value={mediumItemPriceValue}
                onChange={(e) => {
                  setMediumItemPriceValue(e.target.value);

                  setFlatFeePrices((prevFlatFeePrices) => {
                    let updatedFlatFeePrices = {
                      ...prevFlatFeePrices,
                      mediumItemPrice: e.target.value,
                    };

                    return updatedFlatFeePrices;
                  });
                }}
                required={true}
                label={"Medium Items"}
                subLabel={"Flat fee"}
              />
            </div>

            {/* Large Item Flat Fee form group */}
            <div className="form-group">
              <TextInput
                type={"text"}
                id={`large-item-flat-fee-text-input`}
                className={"large-item-flat-fee-input"}
                placeholder={"0"}
                value={largeItemPriceValue}
                onChange={(e) => {
                  setLargeItemPriceValue(e.target.value);

                  setFlatFeePrices((prevFlatFeePrices) => {
                    let updatedFlatFeePrices = {
                      ...prevFlatFeePrices,
                      largeItemPrice: e.target.value,
                    };

                    return updatedFlatFeePrices;
                  });
                }}
                required={true}
                label={"Large Items"}
                subLabel={"Flat fee"}
              />
            </div>

            {/* Submit Flat Fee Btn Wrapper */}
            <div className="submit-flat-fee-btn-wrapper">
              <button onClick={(e) => handleSubmitFlatFee(e)}>Done</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UseFlatFeeModal;
