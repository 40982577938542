import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const DataFormater = (number) => {
    if (number > 1000000000) {
        return (number / 1000000000).toFixed(1).toString() + 'B';
    } else if (number > 1000000) {
        return (number / 1000000).toFixed(1).toString() + 'M';
    } else if (number > 10000) {
        return (number / 1000).toFixed(1).toString() + 'K';
    } else {
        return number.toString();
    }
}

// export default class Chart extends PureComponent {
const Chart = ({ data, orderChartWidth }) => {
    return (
        <ResponsiveContainer width={orderChartWidth}>
            <AreaChart
                data={data}
                margin={{
                    top: 15,
                    right: 0,
                    left: -11,
                    bottom: 0,
                }}
                padding={{
                    right: 20
                }}
            >
                <defs>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="54.88%" stopColor={"rgba(181, 23, 158, 1)"} stopOpacity={1} />
                        <stop offset="112.95%" stopColor={"rgba(181, 23, 158, 0)"} stopOpacity={1} />
                    </linearGradient>

                </defs>
                <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
                <XAxis dataKey="name" scale="point" />
                <YAxis tickFormatter={DataFormater} />
                <Tooltip />
                <Area type="monotone" dataKey="amount" stroke={"#B5179E"} fill="url(#colorPv)" fillOpacity={0.3} />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default Chart;

