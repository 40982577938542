import './App.css';
import "./styles/PageLayout.css"
import "./styles/Table.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signin from './pages/signin/Signin';
import MerchantSignup from './pages/merchant-signup/MerchantSignup';
import MerchantDashboard from './pages/merchant-dashboard/MerchantDashboard';
import DashboardSetup from "./components/dashboard-comps/dashboard-setup/DashboardSetup"
import MerchantStock from './pages/merchant-stock/MerchantStock';
import { Provider } from "react-redux";
import store from './store';
import MerchantOrders from './pages/merchant-orders/MerchantOrders';
import AccountSettings from './pages/settings/AccountSettings';
import StockCreate from './components/stock-comps/stock-create/StockCreate';
import StockEdit from './components/stock-comps/stock-edit/StockEdit';
import FundWithdrawal from './components/fund-withdrawal/FundWithdrawal';
import AccountAwaitingApproval from './components/account-awaiting-approval/AccountAwaitingApproval';
import SearchContextProvider from './components/contexts/SearchContext';
import AccountVerification from './components/account-verification/AccountVerification';
import AwaitingVerification from './components/account-verification/awaiting-verification/AwaitingVerification';
import MerchantAgreement from './pages/merchant-agreement/MerchantAgreement';
import VoucherCreate from './components/voucher-update-comps/voucher-create/VoucherCreate';
import VoucherEdit from './components/voucher-update-comps/voucher-edit/VoucherEdit';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import ResetPassword from './pages/reset-password/ResetPassword';
import WalletHistory from './pages/merchant-wallet-history/WalletHistory';



function App() {
  return (
    <div className="app">
      <SearchContextProvider>
        <Provider store={store}>
          <Router>
            <Routes>
              <Route path='/' element={<Signin />} />
              <Route path='/merchant-agreement' element={<MerchantAgreement />} />
              <Route path='/signin' element={<Signin />} />
              <Route path='/merchant/signup' element={<MerchantSignup />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              {/* Account Awaiting Approval */}
              <Route path='/merchant/awaiting-approval' element={<AccountAwaitingApproval />} />
              {/* Account Awaiting Verification */}
              <Route path='/merchant/verification' element={<AwaitingVerification />} />
              {/* Verification Page */}
              <Route path='/merchant/verification/:token/:id' element={<AccountVerification />} />

              {/* Dashboard */}
              <Route path='/merchant/dashboard' element={<MerchantDashboard />} />
              <Route path='/merchant/dashboard/setup' element={<DashboardSetup />} />
              {/* Withdrawal */}
              <Route path='/merchant/dashboard/withdraw' element={<FundWithdrawal />} />
              {/* Stock */}
              <Route path='/merchant/stock' element={<MerchantStock />} />
              {/*  Store Stock */}
              <Route path='/merchant/stock/create' element={<StockCreate />} />
              <Route path='/merchant/stock/edit' element={<StockEdit />} />
              {/*  Voucher Stock */}
              <Route path='/merchant/voucher/create' element={<VoucherCreate />} />
              <Route path='/merchant/voucher/edit' element={<VoucherEdit />} />
              {/* Order */}
              <Route path='/merchant/orders' element={<MerchantOrders />} />
              {/* Wallet history */}
              <Route path='/merchant/wallet-history' element={<WalletHistory />} />

              {/* Settings */}
              <Route path='/settings' element={<AccountSettings />} />
            </Routes>
          </Router>
        </Provider>
      </SearchContextProvider>

    </div>
  );
}

export default App;
