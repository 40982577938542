import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import angleLeftIcon from "../../../image/angle-left.svg";
import formFilledIcon from "../../../image/form-filled-icon.svg";
import formUnfilledIcon from "../../../image/form-unfilled-icon.svg";
import DropdownInput from "../../input-components/dropdown-input/DropdownInput";
import TextInput from "../../input-components/text-input/TextInput";
import ErrorHandler from "../../error/ErrorHandler";
import SuccessHandler from "../../success/SuccessHandler";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { allStates } from "../../../utils/allStatesInNigeria";
import { updateMerchantAddressWithCACDocument } from "../../../redux/actions/shopDetailsActions";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import { allBanks } from "../../../utils/allBanksinNigeria";
import { deliveryLocations } from "../../../utils/deliveryLocations";
import { api } from "../../../api/config";
import { updateMerchantBankDetails } from "../../../redux/actions/bankDetailsActions";
import { updateDeliveryPricePerLocation } from "../../../redux/actions/deliveryLocationActions";
import isEmpty from "../../../validation/isEmpty";
import PhoneInput from "../../input-components/phone-input/PhoneInput";
import uploadIcon from "../../../image/upload-icon.svg";
import { logoutUser } from "../../../redux/actions/authActions";

function SetupLayout({
  pageContent,
  setPageContent,
  pageType,
  pageStateObj,
  setIsUseFlatFeeModalOpened,
  isUseFlatFeeSubmitted,
  flatFeePrices,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Functions, States and Variables

  // States
  const [userData, setUserData] = useState(null);
  //   Account details form state
  const [shopName, setShopName] = useState("");
  const [shopEmail, setShopEmail] = useState("");
  const [phoneNumberOne, setPhoneNumberOne] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  //   Shop details form states
  const [shopAddress, setShopAddress] = useState("");
  const [cacDocumentFileName, setCacDocumentFileName] = useState("");
  const [cacDocument, setCacDocument] = useState("");
  const [shopLocation, setShopLocation] = useState("");

  //   Bank account setup form states
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bvnNumber, setBvnNumber] = useState("");

  //   Delivery location setup  form states
  const [allDeliveryLocationsAndPrice, setAllDeliveryLocationsAndPrice] =
    useState(null);
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [smallItemPrice, setSmallItemPrice] = useState("");
  const [mediumItemPrice, setMediumItemPrice] = useState("");
  const [largeItemPrice, setLargeItemPrice] = useState("");

  //
  const [isAccountDetailsFormFilled, setIsAccountDetailsFormFilled] =
    useState(true);
  const [isShopDetailsFormFilled, setIsShopDetailsFormFilled] = useState(false);
  const [isBankAccountFormFilled, setIsBankAccountFormFilled] = useState(false);
  const [isDeliveryOptionsFormFilled, setIsDeliveryOptionsFormFilled] =
    useState(false);

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [profilePhoto, setProfilePhoto] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);
  // Handle Update account details form
  const handleSubmitAccountDetailsForm = function (e) {
    if (
      shopName !== "" &&
      shopEmail !== "" &&
      phoneNumberOne !== ""
      // password !== "" &&
      // confirmPassword !== ""
    ) {
      e.preventDefault();

      const resultData = {
        shopName,
        shopEmail,
        phoneNumberOne,
        password,
      };

      //   Set the form page to filled
      setIsAccountDetailsFormFilled(true);

      //   Got to next page
      setPageContent("shop-details-setup");
    }
  };

  // handle Upload CAC Document
  const handleUploadCACDocument = async function (e) {
    e.preventDefault();
    const token = localStorage.getItem("jwtToken");
    axios.defaults.headers.common["authorization"] = token;

    try {
      const selectedFile = e.target.files[0];
      const formData = new FormData();
      formData.append("file", selectedFile);

      setIsFileUploadLoading(true);
      const { data } = await axios.post(`${api}/api/v1/upload`, formData);

      if (data) {
        setCacDocumentFileName(selectedFile.name);
      }
      setCacDocument(data);
      setIsFileUploadLoading(false);
    } catch (ex) {
      setIsFileUploadLoading(false);

      setErrorHandlerObj({
        hasError: true,
        message: "CAC Document Upload failed!",
      });
    }
  };

  // Handle Submit / Update Shop details form
  const handleSubmitShopDetailsForm = function (e) {
    if (shopName !== "" && shopAddress !== "" && shopLocation !== "") {
      e.preventDefault();

      const data = {
        address: shopAddress,
        location: shopLocation,
        cacDocument,
      };

      // Call the dispatch fxn to update shop details and Go to next page on success
      dispatch(
        updateMerchantAddressWithCACDocument(
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setPageContent
        )
      );

      //   Set the form page to filled
      setIsShopDetailsFormFilled(true);
    }
  };

  // Handle Submit / Update Account Details form
  const handleSubmitBankAccountForm = function (e) {
    if (
      bankName !== "" &&
      accountName !== "" &&
      accountNumber !== "" &&
      bvnNumber !== ""
    ) {
      e.preventDefault();

      const bankIndex = allBanks.findIndex((banks) => banks.key === bankName);
      const data = {
        bank: allBanks[bankIndex].value,
        accountName,
        accountNumber,
        bvn: bvnNumber,
      };

      // Call the dispatch fxn to update shop details and Go to next page on success
      dispatch(
        updateMerchantBankDetails(
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setPageContent
        )
      );

      //   Set the form page to filled
      setIsBankAccountFormFilled(true);
    }
  };

  // Handle Submit / Update Delivery Options Form
  const handleSubmitDeliveryOptionsForm = function (e) {
    const isFormEmpty = allDeliveryLocationsAndPrice?.some(
      (location) =>
        location.smallItemPrice === "" ||
        location.mediumItemPrice === "" ||
        location.largeItemPrice === ""
    );
    if (!isFormEmpty) {
      e.preventDefault();
      let deliveryDetailsObj = {};
      // TODO
      allDeliveryLocationsAndPrice?.forEach((deliveryData) => {
        const newDeliveryCost = {
          [deliveryData.key]: {
            largePrice: parseInt(deliveryData.largeItemPrice),
            mediumPrice: parseInt(deliveryData.mediumItemPrice),
            smallPrice: parseInt(deliveryData.smallItemPrice),
          },
        };

        deliveryDetailsObj = { ...deliveryDetailsObj, ...newDeliveryCost };
      });

      const data = {
        deliverycost: { ...deliveryDetailsObj },
      };

      dispatch(
        updateDeliveryPricePerLocation(
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setPageContent,
          pageType,
          navigate
        )
      );
      console.log(allDeliveryLocationsAndPrice, data);
    }
  };

  // Handle Update profile photo
  const handleUpdateProfilePhoto = async function (e) {
    // Create or update the  profile photo

    setErrorHandlerObj({ hasError: false, message: "" });
    if (e.target.files[0].size <= 512000) {
      // handle Upload Product Img
      const token = localStorage.getItem("jwtToken");
      axios.defaults.headers.common["authorization"] = token;

      try {
        const selectedFile = e.target.files[0];
        const formData = new FormData();
        formData.append("file", selectedFile);

        setIsFileUploadLoading(true);
        const { data } = await axios.post(`${api}/api/v1/upload`, formData);

        const profilePictureUrl = {
          profilePicture: data,
        };

        const { data: userDetails } = await axios.patch(
          `${api}/api/v1/merchant/upload-profile-pic`,
          profilePictureUrl
        );

        setProfilePhoto(userDetails.profilePicture);

        setIsFileUploadLoading(false);
        setSuccessHandlerObj({
          isSuccess: true,
          message: "Profile picture updated!",
        });
      } catch (ex) {
        setIsFileUploadLoading(false);

        setErrorHandlerObj({
          hasError: true,
          message: "Upload failed!",
        });
      }

      // setProductImg("");
    } else {
      setErrorHandlerObj({
        hasError: true,
        message: "File size exceeds 500kb!",
      });
    }
  };

  // handle Log Out User
  const handleLogOutUser = function (e) {
    e.preventDefault();

    dispatch(logoutUser(navigate));
  };

  useEffect(() => {
    const bankIndex = allBanks.findIndex(
      (banks) => banks.value === pageStateObj.bank
    );

    setShopEmail(pageStateObj?.email || "");
    setShopName(pageStateObj?.shopName || "");
    setPhoneNumberOne(pageStateObj?.phoneNumber || "");
    setShopAddress(pageStateObj.address);
    setShopLocation(pageStateObj.location);
    setCacDocument(pageStateObj.cacDocument);
    setBankName(allBanks[bankIndex] ? allBanks[bankIndex].key : "");
    setAccountNumber(pageStateObj.accountNumber);
    setAccountName(pageStateObj.accountName);
    setBvnNumber(pageStateObj.bvn);
    setProfilePhoto(pageStateObj.profilePicture);
    setAllDeliveryLocationsAndPrice((prevDeliveryLocationsAndPrice) => {
      const defaultDeliveryLocAndPriceArr = deliveryLocations?.map(
        (location, index) => {
          return {
            _id: Math.floor(Math.random() * 10000),
            ...location,
            smallItemPrice: "",
            mediumItemPrice: "",
            largeItemPrice: "",
            isFormActive: false,
          };
        }
      );
      if (!prevDeliveryLocationsAndPrice || isEmpty(pageStateObj.deliveryCost))
        return defaultDeliveryLocAndPriceArr;

      const newDeliveryLocationsAndPrice = deliveryLocations?.map(
        (location, index) => {
          return {
            _id: Math.floor(Math.random() * 10000),
            ...location,
            smallItemPrice:
              pageStateObj.deliveryCost[location.key].smallPrice?.toString() ||
              "",
            mediumItemPrice:
              pageStateObj.deliveryCost[location.key].mediumPrice?.toString() ||
              "",
            largeItemPrice:
              pageStateObj.deliveryCost[location.key].largePrice?.toString() ||
              "",
            isFormActive: false,
          };
        }
      );
      setSmallItemPrice(newDeliveryLocationsAndPrice[0]?.smallItemPrice || "");
      setMediumItemPrice(
        newDeliveryLocationsAndPrice[0]?.mediumItemPrice || ""
      );
      setLargeItemPrice(newDeliveryLocationsAndPrice[0]?.largeItemPrice || "");
      return newDeliveryLocationsAndPrice;
    });

    if (shopAddress !== "" && cacDocument !== "" && shopLocation !== "") {
      setIsShopDetailsFormFilled(true);
    }

    if (
      bankName !== "" &&
      accountName !== "" &&
      accountNumber !== "" &&
      bvnNumber !== ""
    ) {
      setIsBankAccountFormFilled(true);
    }
    const isDeliveryFieldEmpty = allDeliveryLocationsAndPrice?.some(
      (location) =>
        location.smallItemPrice === "" ||
        location.mediumItemPrice === "" ||
        location.largeItemPrice === ""
    );

    if (isDeliveryFieldEmpty) {
      setIsDeliveryOptionsFormFilled(false);
    } else {
      setIsDeliveryOptionsFormFilled(true);
    }
  }, [pageStateObj]);

  useEffect(() => {
    // Get and set the userddata
    const token = localStorage.getItem("jwtToken");
    // Decode token to get user data
    const decoded = jwt_decode(token);
    setUserData(decoded);
  }, []);

  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pageContent]);

  useEffect(() => {
    if (isUseFlatFeeSubmitted) {
      // If the user submits the flat free prices
      setAllDeliveryLocationsAndPrice((prevDeliveryLocationsAndPrice) => {
        const defaultDeliveryLocAndPriceArr = deliveryLocations?.map(
          (location, index) => {
            if (
              location.key !== "northCentral" &&
              location.key !== "northEast" &&
              location.key !== "northWest" &&
              location.key !== "southEast" &&
              location.key !== "southSouth" &&
              location.key !== "southWest"
            ) {
              return {
                _id: Math.floor(Math.random() * 10000),
                ...location,
                smallItemPrice: flatFeePrices.smallItemPrice,
                mediumItemPrice: flatFeePrices.mediumItemPrice,
                largeItemPrice: flatFeePrices.largeItemPrice,
                isFormActive: false,
              };
            } else {
              return {
                _id: Math.floor(Math.random() * 10000),
                ...location,
                smallItemPrice:
                  prevDeliveryLocationsAndPrice?.filter(
                    (prevLocation) => prevLocation.key === location.key
                  )[0]?.smallItemPrice || "",
                mediumItemPrice:
                  prevDeliveryLocationsAndPrice?.filter(
                    (prevLocation) => prevLocation.key === location.key
                  )[0]?.mediumItemPrice || "",
                largeItemPrice:
                  prevDeliveryLocationsAndPrice?.filter(
                    (prevLocation) => prevLocation.key === location.key
                  )[0]?.largeItemPrice || "",
                isFormActive: false,
              };
            }
          }
        );

        console.log(defaultDeliveryLocAndPriceArr);
        console.log(smallItemPrice, mediumItemPrice, largeItemPrice);
        return defaultDeliveryLocAndPriceArr;
      });
    }
  }, [isUseFlatFeeSubmitted]);

  return (
    <div className="dashboard-setup-layout-container">
      {/* Dashboard Setup Top */}
      <div
        className={`dashboard-setup-top-container ${
          errorHandlerObj.hasError || successHandlerObj.isSuccess
            ? "push-container-down"
            : ""
        }`}
      >
        {/* Left Wrapper */}
        <div className="dashboard-setup-top-container--left-wrapper">
          {/* Back button Wrapper */}
          <div className="back-button-wrapper">
            <button
              onClick={() => {
                if (pageType === "settings") {
                  navigate(-1);
                } else {
                  setPageContent("main");
                }
              }}
            >
              <img src={angleLeftIcon} alt="" />
            </button>
          </div>

          {/* Setup Top Title */}
          <div className="dashboard-setup-top-title">
            {pageType === "settings" ? "Settings" : "Set up"}
          </div>
        </div>

        {/* Right Wrapper */}
        {/* Log out btn wrapper */}
        {pageType === "settings" && (
          <div className="log-out-btn-wrapper">
            <button onClick={(e) => handleLogOutUser(e)}>Log out</button>
          </div>
        )}
      </div>

      {/* Error handler component */}
      <ErrorHandler
        errorHandlerObj={errorHandlerObj}
        className="setup-layout-error-success-message-wrapper"
      />

      {/* Success handler component */}
      <SuccessHandler
        successHandlerObj={successHandlerObj}
        className="setup-layout-error-success-message-wrapper"
      />

      {/* Setup Layout main Container */}
      <div
        className={`dashboard-setup-layout-main-container  ${
          pageContent === "delivery-options-setup" ? "colum-grid" : ""
        }`}
      >
        {/* Dasboard setup Left Section / Dashboard setup navigation container */}
        <div className="dashboard-setup-layout--left-section dashboard-setup-layout-navigation-container">
          {/* Account Details Setup Nav Link */}
          <div className="form-navigation-link account-details-setup-form-link">
            <button
              className={`${
                pageContent === "account-details-setup" &&
                "active-form-nav-link"
              }`}
              onClick={() => setPageContent("account-details-setup")}
            >
              <img
                src={
                  isAccountDetailsFormFilled ? formFilledIcon : formUnfilledIcon
                }
                alt=""
              />
              Account Details
            </button>
          </div>

          {/* Account Details Setup Nav Link */}
          <div className="form-navigation-link shop-details-setup-form-link">
            <button
              className={`${
                pageContent === "shop-details-setup" && "active-form-nav-link"
              }`}
              onClick={() => setPageContent("shop-details-setup")}
            >
              <img
                src={
                  isShopDetailsFormFilled ? formFilledIcon : formUnfilledIcon
                }
                alt=""
              />
              Set up your Shop Details
            </button>
          </div>

          {/* Bank Account Setup Nav Link */}
          <div className="form-navigation-link bank-account-setup-form-link">
            <button
              className={`${
                pageContent === "bank-account-setup" && "active-form-nav-link"
              }`}
              onClick={() => setPageContent("bank-account-setup")}
            >
              <img
                src={
                  isBankAccountFormFilled ? formFilledIcon : formUnfilledIcon
                }
                alt=""
              />
              Set up your Bank Account
            </button>
          </div>

          {/* Delivery Options Setup Nav Link */}
          <div className="form-navigation-link delivery-options-setup-form-link">
            <button
              className={`${
                pageContent === "delivery-options-setup" &&
                "active-form-nav-link"
              }`}
              onClick={() => setPageContent("delivery-options-setup")}
            >
              <img
                src={
                  isDeliveryOptionsFormFilled
                    ? formFilledIcon
                    : formUnfilledIcon
                }
                alt=""
              />
              Set up Delivery Options
            </button>
          </div>
        </div>

        {/* Dasboard Setup Right Section / Dashboard setup form container */}
        <div className="dashboard-setup-layout--right-section dashboard-setup-layout-form-container">
          {/* Use A flat fee option */}
          {pageContent === "delivery-options-setup" && (
            <div className="use-a-flat-fee-option-button-wrapper">
              <button onClick={() => setIsUseFlatFeeModalOpened(true)}>
                Use a flat fee
              </button>
            </div>
          )}
          {/* Account Details Setup Inner Container */}
          <div
            className={`account-details-setup--inner ${
              pageContent !== "account-details-setup" ? "none" : ""
            }`}
          >
            <form>
              {/* Profile photo update form group*/}
              <div className="account-details-setup-profile-photo-form-group">
                <div className="profile-photo-display-wrapper">
                  <img src={profilePhoto} alt="" />
                </div>

                {/* Upload photo input */}
                <div className="upload-profile-photo-input-wrapper">
                  <input
                    type="file"
                    name="upload-profile-photo-input"
                    id="upload-profile-photo-input"
                    onChange={handleUpdateProfilePhoto}
                  />
                  <span>
                    {" "}
                    {isFileUploadLoading
                      ? "Uploading..."
                      : profilePhoto
                      ? "Change photo"
                      : "Upload photo"}
                  </span>
                </div>
                {isFileUploadLoading && (
                  <div className="upload-loading-spinner-wrapper"></div>
                )}
              </div>

              {/* Shop Name form group */}
              <div className="account-details-setup-shop-name-form-group form-group">
                <TextInput
                  type={"text"}
                  id={"merchant-shop-name-text-input-1"}
                  className={"merchant-shop-name-input"}
                  placeholder={""}
                  value={shopName}
                  onChange={(e) => setShopName(e.target.value)}
                  required={true}
                  label={"Store Name"}
                  disabled={true}
                />
              </div>
              {/* Shop Email form group */}
              <div className="account-details-setup-shop-email-form-group form-group">
                <TextInput
                  type={"email"}
                  id={"merchant-shop-email-text-input"}
                  className={"merchant-shop-email-input"}
                  placeholder={""}
                  value={shopEmail}
                  onChange={(e) => setShopEmail(e.target.value)}
                  required={true}
                  label={"Email"}
                  subLabel={"Preferably Shop mail"}
                  disabled={true}
                />
              </div>
              {/* Phone Number One form group */}
              <div className="account-details-setup-phone-number-form-group form-group">
                <PhoneInput
                  type={"tel"}
                  id={"merchant-phone-number-one-text-input"}
                  className={"merchant-phone-number-input"}
                  placeholder={""}
                  value={phoneNumberOne}
                  onChange={(e) => {
                    setPhoneNumberOne(e.target.value);
                    // }
                  }}
                  required={true}
                  label={"Phone Number"}
                  disabled={true}
                />
              </div>

              {/* Password form group */}
              {/* <div className="account-details-setup-password-form-group form-group">
                <img
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  src={isPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                  alt=""
                  className="eye-close-icon password-icon-wrapper"
                />
                <label htmlFor={"merchant-signup-password-input"}>
                  Password
                </label>
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  id={"merchant-signup-password-input"}
                  className={
                    "merchant-signup-password-text-input password-input"
                  }
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required={true}
                />
              </div> */}

              {/* Password form group */}
              {/* <div className="account-details-setup-confirm-password-form-group form-group">
                <img
                  onClick={() =>
                    setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                  }
                  src={isConfirmPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                  alt=""
                  className="eye-close-icon password-icon-wrapper"
                />

                <label htmlFor={"merchant-signup-confirm-password-input"}>
                  Retype Password
                </label>
                <input
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  id={"merchant-signup-confirm-password-input"}
                  className={
                    "merchant-signup-confirm-password-text-input password-input"
                  }
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required={true}
                />
              </div> */}
              {/* Submit form button */}
              {/* <div className="submit-setup-form-button-wrapper">
                <button onClick={handleSubmitAccountDetailsForm}>
                  Save & Next
                </button>
              </div> */}
            </form>
          </div>

          {/* Shop Details Setup Inner Container */}
          <div
            className={`shop-details-setup--inner ${
              pageContent !== "shop-details-setup" ? "none" : ""
            }`}
          >
            <form>
              {/* Shop Name form group */}
              <div className="shop-details-setup-shop-name-form-group form-group">
                <TextInput
                  type={"text"}
                  id={"merchant-shop-name-text-input-2"}
                  className={"merchant-shop-name-input"}
                  placeholder={""}
                  value={shopName}
                  onChange={(e) => setShopName(e.target.value)}
                  required={true}
                  label={"Store Name"}
                  disabled={true}
                />
              </div>

              {/* Shop Address form group */}
              <div className="shop-details-setup-shop-address-form-group form-group">
                <label htmlFor="merchant-shop-address-textarea-input">
                  Address
                </label>
                <textarea
                  id={"merchant-shop-address-textarea-input"}
                  className={"merchant-shop-address-input"}
                  placeholder={""}
                  value={shopAddress}
                  onChange={(e) => setShopAddress(e.target.value)}
                  required={true}
                />
              </div>

              {/* CAC document form group */}
              <div className="shop-details-setup-cac-doc-form-group file-form-group">
                <label htmlFor="merchant-cac-doc-file-input">
                  Document Upload <span>Optional *</span>
                </label>
                <div className="file-form-group--inner">
                  <div className="file-input-wrapper">
                    <div className="file-input-title">
                      {pageStateObj.cacDocument
                        ? "Change CAC Document"
                        : cacDocumentFileName || "Upload CAC Document"}
                    </div>
                    {isFileUploadLoading && (
                      <div className="upload-loading-spinner-wrapper"></div>
                    )}
                    <input
                      type="file"
                      id={"merchant-cac-doc-file-input"}
                      className={"merchant-cac-doc-input"}
                      placeholder={""}
                      onChange={(e) => {
                        handleUploadCACDocument(e);
                      }}
                    />
                  </div>
                  {pageStateObj.cacDocument && (
                    <div className="preview-cac-document-icon">
                      <a
                        href={`${pageStateObj.cacDocument}`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <img src={uploadIcon} alt="" />
                      </a>
                    </div>
                  )}
                </div>
              </div>

              {/* Shop Location dropdown */}
              <div className="shop-details-setup-shop-location-form-group form-group">
                <DropdownInput
                  className={"merchant-shop-location-dropdown-input"}
                  id={"merchant-shop-location-input"}
                  label={"Add Location"}
                  required={true}
                  optionArray={[
                    {
                      key: "",
                      value: "Choose location",
                      default: "default",
                    },
                    ...allStates,
                  ]}
                  value={shopLocation}
                  onChange={(e) => setShopLocation(e.target.value)}
                />
              </div>

              {/* Submit form button */}
              <div className="submit-setup-form-button-wrapper">
                {isLoading && <LoadingSpinner />}
                <button onClick={handleSubmitShopDetailsForm}>
                  Save & Next
                </button>
              </div>
            </form>
          </div>

          {/* Bank Account Setup Inner Container */}
          <div
            className={`bank-account-setup--inner ${
              pageContent !== "bank-account-setup" ? "none" : ""
            }`}
          >
            <form>
              {/* Merchant's Bank dropdown */}
              <div className="bank-account-setup-bank-name-form-group form-group">
                <DropdownInput
                  className={"merchant-bank-name-dropdown-input"}
                  id={"merchant-bank-name-input"}
                  label={"Choose Bank"}
                  required={true}
                  optionArray={[
                    {
                      key: "",
                      value: "Choose bank",
                      default: "Default",
                    },
                    ...allBanks,
                  ]}
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </div>

              {/* Merchant Account Number */}
              <div className="bank-account-setup-account-number-form-group form-group">
                <TextInput
                  type={"text"}
                  id={"merchant-account-number-text-input"}
                  className={"merchant-account-number-input"}
                  placeholder={""}
                  value={accountNumber}
                  onChange={(e) => {
                    setAccountNumber(e.target.value);
                    // }
                  }}
                  required={true}
                  label={"Account Number"}
                  maxLength={"10"}
                />
              </div>

              {/* Merchant Account Name */}
              <div className="bank-account-setup-account-name-form-group form-group">
                <TextInput
                  type={"text"}
                  id={"merchant-account-name-text-input"}
                  className={"merchant-account-name-input"}
                  placeholder={""}
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                  required={true}
                  label={"Account Name"}
                />
              </div>

              {/* Merchant BVN Number */}
              <div className="bank-account-setup-bvn-number-form-group form-group">
                <TextInput
                  type={"text"}
                  id={"merchant-bvn-number-text-input"}
                  className={"merchant-bvn-number-input"}
                  placeholder={""}
                  value={bvnNumber}
                  onChange={(e) => {
                    // const re = /^[0-9\b]+$/;
                    // if value is not blank, then test the regex
                    // if (e.target.value === "" || re.test(e.target.value)) {
                    setBvnNumber(e.target.value);
                    // }
                  }}
                  required={true}
                  label={"BVN Number"}
                  maxLength={"11"}
                />
              </div>

              {/* Submit form button */}
              <div className="submit-setup-form-button-wrapper">
                {isLoading && <LoadingSpinner />}
                <button onClick={handleSubmitBankAccountForm}>Save</button>
              </div>
            </form>
          </div>

          {/*Delivery Options Setup Inner Container */}
          <div
            className={`delivery-options-setup--inner ${
              pageContent !== "delivery-options-setup" ? "none" : ""
            }`}
          >
            <form>
              {/* Form Row One / Delivery Location One */}

              {allDeliveryLocationsAndPrice?.map((location, index) => (
                <div
                  key={index + 1}
                  className={`delivery-details-form-row-${
                    index + 1
                  } delivery-details-form-wrapper`}
                >
                  <div className="form-header-wrapper">
                    Delivery Details {index + 1} - ({location.address})
                  </div>
                  {/* Delivery Location */}
                  <div className="form-group-wrapper">
                    <div className="form-body-wrapper form-group">
                      {/* <input type="text" /> */}
                      <TextInput
                        type={"text"}
                        id={`merchant-delivery-location-${
                          index + 1
                        }-text-input1`}
                        className={`merchant-delivery-location-${
                          index + 1
                        }-input`}
                        placeholder={""}
                        value={allDeliveryLocationsAndPrice[index]?.value || ""}
                        onChange={() => {}}
                        required={true}
                        readOnly={true}
                        label={"Location"}
                      />
                    </div>

                    {/* Flex form wrapper */}
                    {/* Large Items */}
                    <div className="form-group">
                      <TextInput
                        type={"text"}
                        id={`large-item-price-on-location-${
                          index + 1
                        }-text-input`}
                        className={`large-item-price-on-location-${
                          index + 1
                        }-input`}
                        placeholder={"Input Price (N)"}
                        value={
                          !location.isFormActive
                            ? allDeliveryLocationsAndPrice[index]
                                ?.largeItemPrice
                            : largeItemPrice
                        }
                        onChange={(e) => {
                          setLargeItemPrice(e.target.value);
                          setAllDeliveryLocationsAndPrice(
                            (prevDeliveryLocationsAndPrice) => {
                              const newDeliveryLocationsAndPrice = {
                                ...location,
                                largeItemPrice: e.target.value,
                              };

                              prevDeliveryLocationsAndPrice.splice(
                                index,
                                1,
                                newDeliveryLocationsAndPrice
                              );

                              return prevDeliveryLocationsAndPrice;
                            }
                          );
                          // }
                        }}
                        onFocus={() => {
                          setLargeItemPrice(location.largeItemPrice || "");
                          // Set the active status of location to true on input focus
                          setAllDeliveryLocationsAndPrice(
                            (prevDeliveryLocationsAndPrice) => {
                              const newDeliveryLocationsAndPrice = {
                                ...location,
                                isFormActive: true,
                              };

                              prevDeliveryLocationsAndPrice
                                .map((location) => {
                                  return { ...location, isFormActive: false };
                                })
                                .splice(index, 1, newDeliveryLocationsAndPrice);

                              return prevDeliveryLocationsAndPrice;
                            }
                          );
                        }}
                        required={true}
                        label={"Large items"}
                      />
                    </div>

                    {/* Medium Items */}
                    <div className="form-group">
                      <TextInput
                        type={"text"}
                        id={`medium-item-price-on-location-${
                          index + 1
                        }-text-input`}
                        className={`medium-item-price-on-location-${
                          index + 1
                        }-input`}
                        placeholder={"Input Price (N)"}
                        value={
                          !location.isFormActive
                            ? allDeliveryLocationsAndPrice[index]
                                .mediumItemPrice
                            : mediumItemPrice
                        }
                        onChange={(e) => {
                          setMediumItemPrice(e.target.value);
                          setAllDeliveryLocationsAndPrice(
                            (prevDeliveryLocationsAndPrice) => {
                              const newDeliveryLocationsAndPrice = {
                                ...location,
                                mediumItemPrice: e.target.value,
                              };

                              prevDeliveryLocationsAndPrice.splice(
                                index,
                                1,
                                newDeliveryLocationsAndPrice
                              );

                              return prevDeliveryLocationsAndPrice;
                            }
                          );
                          // }
                        }}
                        onFocus={() => {
                          setMediumItemPrice(location.mediumItemPrice || "");
                          // Set the active status of location to true on input focus
                          setAllDeliveryLocationsAndPrice(
                            (prevDeliveryLocationsAndPrice) => {
                              const newDeliveryLocationsAndPrice = {
                                ...location,
                                isFormActive: true,
                              };

                              prevDeliveryLocationsAndPrice
                                .map((location) => {
                                  return { ...location, isFormActive: false };
                                })
                                .splice(index, 1, newDeliveryLocationsAndPrice);

                              return prevDeliveryLocationsAndPrice;
                            }
                          );
                        }}
                        required={true}
                        label={"Medium items"}
                      />
                    </div>

                    {/* Small Items */}
                    <div className="form-group">
                      <TextInput
                        type={"text"}
                        id={`small-item-price-on-location-${
                          index + 1
                        }-text-input`}
                        className={`small-item-price-on-location-${
                          index + 1
                        }-input`}
                        placeholder={"Input Price (N)"}
                        value={
                          !location.isFormActive
                            ? allDeliveryLocationsAndPrice[index].smallItemPrice
                            : smallItemPrice
                        }
                        onChange={(e) => {
                          setSmallItemPrice(e.target.value);
                          setAllDeliveryLocationsAndPrice(
                            (prevDeliveryLocationsAndPrice) => {
                              const newDeliveryLocationsAndPrice = {
                                ...location,
                                smallItemPrice: e.target.value,
                              };

                              prevDeliveryLocationsAndPrice.splice(
                                index,
                                1,
                                newDeliveryLocationsAndPrice
                              );

                              return prevDeliveryLocationsAndPrice;
                            }
                          );
                          // }
                        }}
                        onFocus={() => {
                          setSmallItemPrice(location.smallItemPrice || "");
                          // Set the active status of location to true on input focus
                          setAllDeliveryLocationsAndPrice(
                            (prevDeliveryLocationsAndPrice) => {
                              const newDeliveryLocationsAndPrice = {
                                ...location,
                                isFormActive: true,
                              };

                              prevDeliveryLocationsAndPrice
                                .map((location) => {
                                  return { ...location, isFormActive: false };
                                })
                                .splice(index, 1, newDeliveryLocationsAndPrice);

                              return prevDeliveryLocationsAndPrice;
                            }
                          );
                        }}
                        required={true}
                        label={"Small items"}
                      />
                    </div>
                  </div>
                </div>
              ))}

              {/* Submit form button */}
              <div className="submit-setup-form-button-wrapper">
                {isLoading && <LoadingSpinner />}
                <button onClick={handleSubmitDeliveryOptionsForm}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetupLayout;
