import React, { useEffect, useState } from "react";
import "../../merchant-vouchers/modal/SearchVoucherModal.css";
import { useDispatch } from "react-redux";
import TextInput from "../../../../components/input-components/text-input/TextInput";
import PrimaryButton from "../../../../components/buttons/primary-button/PrimaryButton";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import { redeemMerchantVoucher } from "../../../../redux/actions/vouchersActions";

function RedeemVoucherModal({
  isModalOpened,
  selectedVoucherCodeForRedemption,
  setIsModalOpened,
  setErrorHandlerObj,
  setSuccessHandlerObj,
}) {
  // Functions, States and Variables
  const dispatch = useDispatch();

  // States
  const [voucherOTPCode, setVoucherOTPCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  const handleRedeemVoucherThroughOTP = function (e) {
    if (voucherOTPCode !== "") {
      e.preventDefault();
      const data = {
        otp: voucherOTPCode,
        voucherCode: selectedVoucherCodeForRedemption,
      };
      console.log(data);
      // Call the API to redeem voucher through the OTP inputed
      dispatch(
        redeemMerchantVoucher(
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsModalOpened
        )
      );
    }
  };

  // UseEffects
  useEffect(() => {
    // Cleanup that clears the input state when the modal is closed
    return () => {
      setVoucherOTPCode("");
    };
  }, [isModalOpened]);

  return (
    <div
      className={`search-voucher-modal-container ${
        isModalOpened ? "" : "none"
      }`}
    >
      <div className="search-voucher-modal-container--inner">
        <div className="search-voucher-header-text">
          Fill in the OTP code sent to the user below
        </div>

        <form>
          {/* Voucher Code form group */}
          <div className="form-group">
            <TextInput
              type={"number"}
              className={"voucher-otp-code-input"}
              placeholder={""}
              value={voucherOTPCode}
              onChange={(e) => setVoucherOTPCode(e.target.value)}
              required={true}
              label={"OTP Code"}
            />
          </div>

          <div className="primary-button-container search-for-voucher-button-container">
            {isLoading && <LoadingSpinner />}
            <PrimaryButton
              className="search-for-voucher-button-wrapper"
              placeholder="Redeem Voucher"
              onClick={(e) => handleRedeemVoucherThroughOTP(e)}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default RedeemVoucherModal;
