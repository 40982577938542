import React, { useState } from "react";

function ProductSetupNav({ pageType, pageContent, setPageContent }) {
  // Functions, States and Variables
  // Functions
  const [tabOptions, setTabOptions] = useState([
    {
      key: "voucher-info",
      value: "Voucher Info",
    },
  ]);

  return (
    <div className="dashboard-setup-layout--left-section dashboard-setup-layout-navigation-container">
      {/* Voucher Setup Nav Link */}
      {tabOptions.map((tabOption, index) => (
        <div key={index + 1} className="form-navigation-link">
          <button
            className={`${
              pageContent === tabOption.key && "active-form-nav-link"
            }`}
            onClick={() => setPageContent(tabOption.key)}
          >
            {tabOption.value}
          </button>
        </div>
      ))}

      {/* delete stock btn wrapper */}
      {/* {pageType === "edit" && (
        <div className="delete-stock-btn-wrapper">
          <button onClick={() => handleOpenDeleteStockModal()}>
            Delete Product
          </button>
        </div>
      )} */}
    </div>
  );
}

export default ProductSetupNav;
