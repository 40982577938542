import React, { useState } from "react";
import "./ResetPassword.css";
import { useNavigate } from "react-router-dom";
import AuthNavbar from "../../components/auth-navbar/AuthNavbar";
import authWaveImage from "../../image/signin-wave-image.svg";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import ErrorHandler from "../../components/error/ErrorHandler";
import eyeOpenIcon from "../../image/eye-open-icon.svg";
import eyeCloseIcon from "../../image/eye-close-icon.svg";
import useDisplayMessage from "../../utils/useDisplayMessage";
import SuccessHandler from "../../components/success/SuccessHandler";
import { useDispatch } from "react-redux";
import TextInput from "../../components/input-components/text-input/TextInput";
import { resetPassword } from "../../redux/actions/authActions";

function ResetPassword() {
  // Functions, States and Variables
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Hooks
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();
  // States
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");

  //
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  // const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
  useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Functions
  const handleSubmitFormDetails = function (e) {
    setErrorHandlerObj({ hasError: false, message: "" });
    if (token !== "" && password !== "") {
      e.preventDefault();

      const data = {
        token,
        password,
      };

      dispatch(
        resetPassword(
          data,
          setErrorHandlerObj,
          setSuccessHandlerObj,
          setIsLoading,
          navigate
        )
      );
    }
  };

  return (
    <div className="signin-container">
      <AuthNavbar />

      {/* ResetPassword container--inner  */}
      <div className="signin-container--inner">
        {/* ResetPassword Left section  / Auth Left Section*/}
        <section className="auth-left-section signin-left-section">
          {/* ResetPassword left section text section */}
          <div className="signin-left-section-text-wrapper">
            Make sales and your money is ensured
          </div>

          {/* Auth Left Image Wrapper */}
          <div className="left-auth-section-image-wrapper">
            <img src={authWaveImage} alt="" />
          </div>
        </section>

        {/* ResetPassword Right section  / Auth Right Section*/}
        <section className="auth-right-section signin-right-section">
          <div className="signin-right-section--inner">
            {/* Error handler component */}
            <ErrorHandler
              errorHandlerObj={errorHandlerObj}
              className="auth-message-wrapper"
            />
            <SuccessHandler
              successHandlerObj={successHandlerObj}
              className="auth-message-wrapper"
            />

            {/* ResetPassword right section header */}
            <div className="signin-right-section-header">
              {/* ResetPassword right section header title */}
              <div className="signin-right-section-header-title">
                Reset password
              </div>

              <div className="forgot-password-header-sub-title">
                Fill in a new password different from your old password!
              </div>
            </div>

            {/* ResetPassword main form section */}
            <div className="signin-main-form-section">
              <form>
                {/* Email form group */}
                <div className="signin-email-form-group form-group">
                  <TextInput
                    type={"text"}
                    id={"signin-email-text-input"}
                    className={"signin-email-input"}
                    placeholder={""}
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    required={true}
                    label={"Token"}
                  />
                </div>

                {/* Password form group */}
                <div className="signin-password-form-group form-group">
                  <img
                    src={isPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
                    alt=""
                    className="password-icon-wrapper"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  />

                  <label htmlFor={"signin-password-input"}>Password</label>
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    id={"signin-password-input"}
                    className={"signin-password-text-input password-input"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required={true}
                  />
                </div>

                {/* Submit form button wrapper */}
                <div className="log-in-button-wrapper">
                  {isLoading && <LoadingSpinner />}
                  <button onClick={handleSubmitFormDetails}>
                    Reset password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ResetPassword;
