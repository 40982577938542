import React, { useEffect, useState } from "react";
import "./MerchantStock.css";
import Top from "../../components/app-top/Top";
import ErrorHandler from "../../components/error/ErrorHandler";
import SuccessHandler from "../../components/success/SuccessHandler";
import Main from "../../components/main-container/Main";
import Sidebar from "../../components/app-sidebar/Sidebar";
import MerchantStore from "./merchant-store/MerchantStore";
import useDisplayMessage from "../../utils/useDisplayMessage";
import ProductsNav from "./products-nav/ProductsNav";
import MerchantVouchers from "./merchant-vouchers/MerchantVouchers";
import { useLocation } from "react-router-dom";
import VouchersToBeRedeemed from "./vouchers-to-be-redeemed/VouchersToBeRedeemed";
import SearchVoucherModal from "./merchant-vouchers/modal/SearchVoucherModal";
import RedeemVoucherModal from "./vouchers-to-be-redeemed/modal/RedeemVoucherModal";

function MerchantStock() {
  // Functions, States and Variables
  const location = useLocation();

  // States
  // const [pageContent, setPageContent] = useState("store-vouchers");
  const [pageContent, setPageContent] = useState("store-stock");
  const [isSearchVoucherModalOpened, setIsSearchVoucherModalOpened] =
    useState(false);
  const [isVoucherSearched, setIsVoucherSearched] = useState(false);
  // Voucher OTP redemption modal
  const [isRedeemVoucherModalOpened, setIsRedeemVoucherModalOpened] =
    useState(false);
  const [
    selectedVoucherCodeForRedemption,
    setSelectedVoucherCodeForRedemption,
  ] = useState("");

  // Hooks
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  useEffect(() => {
    if (location.state) {
      const { message, currentPageContent } = location.state;
      if (message) setSuccessHandlerObj({ isSuccess: true, message });
      if (currentPageContent) setPageContent(currentPageContent);

      return () => {
        // Cleanup function that clears the location state when the component unmounts to prevent the success state
        if (message) window.history.replaceState({}, document.title);
      };
    }
  }, [location]);

  return (
    <div
      className={`app-page-container  ${
        isSearchVoucherModalOpened || isRedeemVoucherModalOpened
          ? "overflow-hidden"
          : ""
      }`}
    >
      <Top />
      <Sidebar activeName={"Products"} />

      {/* Modals */}
      <div
        onClick={() => {
          setIsSearchVoucherModalOpened(false);
          setIsRedeemVoucherModalOpened(false);
        }}
        className={`gray-overlay ${
          isSearchVoucherModalOpened || isRedeemVoucherModalOpened ? "" : "none"
        }`}
      ></div>

      {/* Search Voucher By Voucher Code Modal */}
      <SearchVoucherModal
        isModalOpened={isSearchVoucherModalOpened}
        setIsModalOpened={setIsSearchVoucherModalOpened}
        setErrorHandlerObj={setErrorHandlerObj}
        setIsVoucherSearched={setIsVoucherSearched}
      />

      {/* Redeem Voucher By Filling in OTP Send to User Modal */}
      <RedeemVoucherModal
        isModalOpened={isRedeemVoucherModalOpened}
        selectedVoucherCodeForRedemption={selectedVoucherCodeForRedemption}
        setIsModalOpened={setIsRedeemVoucherModalOpened}
        setErrorHandlerObj={setErrorHandlerObj}
        setSuccessHandlerObj={setSuccessHandlerObj}
      />

      <Main>
        <div className="app-page-container--inner">
          <div className="app--actions-handler-wrapper">
            {/* Error handler component */}
            <ErrorHandler errorHandlerObj={errorHandlerObj} />

            {/* Success handler component */}
            <SuccessHandler successHandlerObj={successHandlerObj} />
          </div>

          {/* app-container-main-section */}
          <section className="app-container-main-section">
            {/* Products Navigation Section */}
            <ProductsNav
              pageContent={pageContent}
              setPageContent={setPageContent}
            />

            {/* Page Contents */}
            {/* Store Stock */}
            {pageContent === "store-stock" && (
              <MerchantStore
                setErrorHandlerObj={setErrorHandlerObj}
                setSuccessHandlerObj={setSuccessHandlerObj}
              />
            )}

            {/* Voucher Stock */}
            {pageContent === "store-vouchers" && (
              <MerchantVouchers
                setErrorHandlerObj={setErrorHandlerObj}
                setSuccessHandlerObj={setSuccessHandlerObj}
              />
            )}

            {/* Voucher to be reedemed */}
            {pageContent === "vouchers-to-be-redeemed" && (
              <VouchersToBeRedeemed
                isVoucherSearched={isVoucherSearched}
                setIsVoucherSearched={setIsVoucherSearched}
                setErrorHandlerObj={setErrorHandlerObj}
                setSuccessHandlerObj={setSuccessHandlerObj}
                setIsSearchVoucherModalOpened={setIsSearchVoucherModalOpened}
                setIsRedeemVoucherModalOpened={setIsRedeemVoucherModalOpened}
                setSelectedVoucherCodeForRedemption={
                  setSelectedVoucherCodeForRedemption
                }
              />
            )}
          </section>
        </div>
      </Main>
    </div>
  );
}

export default MerchantStock;
