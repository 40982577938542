import React from "react";
import "./StockAwaiting.css";
import digitalNomadWorkingIcon from "../../../image/digital-nomad-working.jpg";
import { useNavigate } from "react-router-dom";

function StockAwaiting({ setPageContent }) {
  // Functions, States and variables
  const navigate = useNavigate();
  return (
    <section className="dashboard-awaiting-data-section">
      {/* Container Header  */}
      <div className="dashboard-awaiting-data-section-header">
        <div className="dashboard-awaiting-data-section-header-hero-img-wrapper">
          <img src={digitalNomadWorkingIcon} alt="" />
        </div>

        <div className="dashboard-awaiting-data-section-header-title">
          Your product is awaiting approval
        </div>

        <div className="dashboard-awaiting-data-section-header-sub-title">
          Sit back and relax 🤩
        </div>

        <div className="add-another-stock-link-button">
          <button onClick={() => navigate(0)}>Add another stock</button>
        </div>
      </div>
    </section>
  );
}

export default StockAwaiting;
