import axios from "axios";
import { GET_ERRORS } from "../constants";
import isEmpty from "../../validation/isEmpty";
import { api } from "../../api/config";


const url = `${api}/api/v1/merchant/update-address-and-cac-document`

const token = localStorage.getItem("jwtToken");
axios.defaults.headers.common["authorization"] = token;
// update Merchant Address With CAC Document
export const updateMerchantAddressWithCACDocument = (data, setIsLoading, setErrorHandlerObj, setSuccessHandlerObj, setPageContent) => (dispatch) => {
    setIsLoading(true)
    axios.patch(`${url}`, data)
        .then((res) => {

            // console.log(res.data)
            // Set Page Content to the next page
            setPageContent("bank-account-setup");

            setSuccessHandlerObj({ isSuccess: true, message: "Shop details updated successfully!" })
            setIsLoading(false)
        })
        .catch((err) => {
            if (!isEmpty(err) && !isEmpty((err.response))) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                })
            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: "Something Went Wrong. Please Check your Connection and try again"
                })
            }

            setIsLoading(false)
            // Set the error handler state
            setErrorHandlerObj({
                hasError: true,
                message:
                    err?.response?.data ||
                    "Something Went Wrong. Please Check your Connection and try again",
            });

        })

}