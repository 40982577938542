import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import stockReducer from "./stockReducer";
import categoryReducer from "./categoryReducer";
import orderReducer from "./orderReducer"
import withdrawalReducer from "./withdrawalReducer";
import revenueReducer from "./revenueReducer";
import walletReducer from "./walletReducer";
import deliveryLocationReducer from "./deliveryLocationReducer";
import verificationReducer from "./verificationReducer";
import brandsReducer from "./brandsReducer";
import vouchersReducer from "./vouchersReducer";


export default combineReducers({
    errors: errorReducer,
    users: authReducer,
    stocks: stockReducer,
    vouchers: vouchersReducer,
    categories: categoryReducer,
    orders: orderReducer,
    withdrawals: withdrawalReducer,
    revenues: revenueReducer,
    wallet: walletReducer,
    locations: deliveryLocationReducer,
    status: verificationReducer,
    brands: brandsReducer,
});
